import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, Form, Table } from 'react-bootstrap';
import { CheckLg, Pencil, TrashFill, XLg } from 'react-bootstrap-icons';
import Editable from '../../ui/Editable/Editable';
import Icon from '../../ui/Icon/Icon';
import TooltipIcon from '../../ui/TooltipIcon/TooltipIcon';
import toast from 'react-hot-toast';
import { InstitutionContextType } from './index';
import { IInstitution, IInstitutionContact } from '../../../types';

const tableSchema = {
  administrative_district: 'Správní obvod',
  cadastral_area: 'Katastrální území',
  position: 'Pozice',
  email: 'E-mail',
  action: 'Akce',
};

const emptyContactData = {
  administrative_district: '',
  cadastral_area: '',
  position: '',
  email: '',
};

export default function InstitutionDetail() {
  const { setError, setIsLoading, whoAmI } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const navigate = useNavigate();
  const { institutionId } = useParams();

  const { getInstitutions } = useOutletContext<InstitutionContextType>();
  const [institution, setInstitution] = useState<IInstitution | null>(null);

  const [editInstitutionDescription, setEditInstitutionDescription] = useState<string>('');
  const [editInstitutionName, setEditInstitutionName] = useState<string>('');
  const [addContactData, setAddContactData] =
    useState<Omit<IInstitutionContact, 'id'>>(emptyContactData);
  const [editContactData, setEditContactData] =
    useState<Omit<IInstitutionContact, 'id'>>(emptyContactData);
  const [editContactId, setEditContactId] = useState<number | null>(null);

  const getInstitution = useCallback(async (institutionId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/institutions/${institutionId}`);
      setInstitution(response.data);
      setAddContactData(emptyContactData);
      setEditContactData(emptyContactData);
      setEditInstitutionName(response.data?.name || '');
      setEditInstitutionDescription(response.data?.special_description || '');
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInstitution(institutionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionId]);

  const onEditContactChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const fieldName = (event.target as HTMLInputElement).getAttribute('name');
    const fieldValue = (event.target as HTMLInputElement).value;

    const newFormData = { ...editContactData, ...(fieldName && { [fieldName]: fieldValue }) };

    setEditContactData(newFormData);
  };

  const onEditContactSubmit = async (event: any) => {
    event.preventDefault();

    const editedContact = {
      administrative_district: editContactData.administrative_district,
      cadastral_area: editContactData.cadastral_area,
      position: editContactData.position,
      email: editContactData.email,
    };

    try {
      await httpClient.put(
        `/institutions/${institutionId}/contact/${editContactId}`,
        editedContact
      );
      const newContact = institution?.contacts.map((contact) =>
        contact.id === editContactId ? { id: editContactId, ...editedContact } : contact
      );
      setInstitution({ ...institution, contacts: newContact } as IInstitution);
      setEditContactId(null);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  const onEnableContactEdit = (
    event: MouseEvent<HTMLButtonElement>,
    contact: IInstitutionContact
  ) => {
    event.preventDefault();
    setEditContactId(contact.id);

    const formValues = {
      administrative_district: contact.administrative_district,
      cadastral_area: contact.cadastral_area,
      position: contact.position,
      email: contact.email,
    };

    setEditContactData(formValues);
  };

  const onCancelEdit = () => {
    setEditContactId(null);
  };

  const onAddContact = async (event: FormEvent, institutionId: number) => {
    event.preventDefault();
    setEditContactId(null);
    try {
      await httpClient.post(`/institutions/${institutionId}/contact`, addContactData);
      await getInstitution(institutionId);
      setAddContactData(emptyContactData);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  const onDeleteContact = async (institutionId: number, contactId: number) => {
    try {
      await httpClient.delete(`/institutions/${institutionId}/contact/${contactId}`);
      await getInstitution(institutionId);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  const onDeleteInstitution = async (institutionId: number) => {
    try {
      await httpClient.delete(`/institutions/${institutionId}`);
      await getInstitutions();
      navigate('/institutions');
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  const onContactChange = (event: ChangeEvent) => {
    event.preventDefault();

    const fieldName = (event.target as HTMLInputElement).getAttribute('name');
    const fieldValue = (event.target as HTMLInputElement).value;

    const newContactData = {
      ...(addContactData || emptyContactData),
      ...(fieldName && { [fieldName]: fieldValue }),
    };

    setAddContactData(newContactData);
  };

  const onUpdateInstitutionName = async (institutionId: number) => {
    try {
      await httpClient.put(`/institutions/${institutionId}`, { name: editInstitutionName });
      setInstitution({ ...institution, name: editInstitutionName } as IInstitution);
      await getInstitutions();
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  const onInstitutionDescriptionChange = (event: ChangeEvent) => {
    event.preventDefault();
    const fieldValue = (event.target as HTMLInputElement).value;
    setEditInstitutionDescription(fieldValue);
  };

  const onUpdateInstitutionDescription = async (institutionId: number) => {
    try {
      await httpClient.put(`/institutions/${institutionId}`, {
        special_description: editInstitutionDescription,
      });
      setInstitution({
        ...institution,
        special_description: editInstitutionDescription,
      } as IInstitution);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  const isEditButtonDisabled = (): boolean => {
    return institution?.special_description === editInstitutionDescription;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="mb-5">
      {institution && (
        <Fragment>
          <h3>
            <Editable
              text={institution.name}
              childRef={inputRef}
              disabled={whoAmI && whoAmI.role === 'operator'}
              saveAction={() => onUpdateInstitutionName(institution.id)}
              setEditValue={setEditInstitutionName}
            >
              <Form.Control
                ref={inputRef}
                type="text"
                name="name"
                value={editInstitutionName}
                onChange={(e) => setEditInstitutionName(e.target.value)}
              />
            </Editable>
          </h3>
          <h4 className="pt-4 pb-3">
            Kontakty
            <TooltipIcon title="Zde nastavíte k instituci kontakty, požadavky, případně předdefinovaný email." />
          </h4>
          <Form onSubmit={onEditContactSubmit}>
            <Table bordered>
              <thead style={{ textAlign: 'center' }}>
                <tr>
                  <th>{tableSchema.administrative_district}</th>
                  <th style={{ width: '30%' }}>{tableSchema.cadastral_area}</th>
                  <th style={{ width: '25%' }}>{tableSchema.position}</th>
                  <th style={{ width: '20%' }}>{tableSchema.email}</th>
                  <th style={{ width: '90px' }}>{tableSchema.action}</th>
                </tr>
              </thead>
              <tbody>
                {institution.contacts?.map((contact) => (
                  <Fragment key={contact.id}>
                    {editContactId === contact.id ? (
                      <tr>
                        <td>
                          <Form.Control
                            type="text"
                            name="administrative_district"
                            placeholder={tableSchema.administrative_district}
                            value={editContactData.administrative_district}
                            onChange={onEditContactChange}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            name="cadastral_area"
                            placeholder={tableSchema.cadastral_area}
                            value={editContactData.cadastral_area}
                            onChange={onEditContactChange}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            name="position"
                            placeholder={tableSchema.position}
                            value={editContactData.position}
                            onChange={onEditContactChange}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="email"
                            name="email"
                            required
                            placeholder={tableSchema.email}
                            value={editContactData.email}
                            onChange={onEditContactChange}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Button title="Uložit" size="sm" variant="success" type="submit">
                            <Icon>
                              <CheckLg color="white" />
                            </Icon>
                          </Button>
                          <Button
                            title="Zrušit"
                            size="sm"
                            variant="secondary"
                            onClick={onCancelEdit}
                          >
                            <Icon>
                              <XLg color="white" />
                            </Icon>
                          </Button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={contact.id}>
                        <td>{contact.administrative_district}</td>
                        <td>{contact.cadastral_area}</td>
                        <td>{contact.position}</td>
                        <td>{contact.email}</td>
                        {whoAmI && whoAmI.role !== 'operator' && (
                          <td style={{ textAlign: 'center' }}>
                            <Button
                              title="Editovat"
                              size="sm"
                              variant="info"
                              onClick={(event) => onEnableContactEdit(event, contact)}
                            >
                              <Icon>
                                <Pencil color="white" />
                              </Icon>
                            </Button>
                            <Button
                              title="Smazat"
                              size="sm"
                              variant="danger"
                              onClick={() => {
                                window.confirm('Opravdu chcete zmazat kontakt?') &&
                                  onDeleteContact(institution.id, contact.id);
                              }}
                            >
                              <Icon>
                                <TrashFill color="white" />
                              </Icon>
                            </Button>
                          </td>
                        )}
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </Form>

          <h5 className="pt-4 pb-3">Přidat nový kontakt</h5>
          <Form onSubmit={(event) => onAddContact(event, institution.id)}>
            <Table bordered>
              <thead style={{ textAlign: 'center' }}>
                <tr>
                  <th>{tableSchema.administrative_district}</th>
                  <th style={{ width: '30%' }}>{tableSchema.cadastral_area}</th>
                  <th style={{ width: '25%' }}>{tableSchema.position}</th>
                  <th style={{ width: '20%' }}>{tableSchema.email}</th>
                  <th style={{ width: '90px' }}>{tableSchema.action}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      name="administrative_district"
                      value={addContactData.administrative_district || ''}
                      placeholder={tableSchema.administrative_district}
                      onChange={onContactChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name="cadastral_area"
                      value={addContactData.cadastral_area || ''}
                      placeholder={tableSchema.cadastral_area}
                      onChange={onContactChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name="position"
                      value={addContactData.position || ''}
                      placeholder={tableSchema.position}
                      onChange={onContactChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="email"
                      name="email"
                      required
                      placeholder={tableSchema.email}
                      value={addContactData.email || ''}
                      onChange={onContactChange}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Button
                      size="sm"
                      variant="success"
                      type="submit"
                      disabled={whoAmI && whoAmI.role === 'operator'}
                    >
                      Uložit
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form>

          <h5 className="pt-4 pb-3">
            Speciální požadavky pro instituci
            <TooltipIcon title="Speciální požadavky se objeví jako informační okno při odesílání na tuto instituci." />
          </h5>
          <Form className="pb-3">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              name="special_description"
              placeholder="Zde napište speciální instrukce pro tuto instituci. Zobrazí se před odesláním podnětu na instituci."
              value={editInstitutionDescription}
              onChange={onInstitutionDescriptionChange}
            />
            <div className="clearfix">
              <Button
                size="sm"
                variant="success"
                type="button"
                className="mt-2 float-end"
                disabled={isEditButtonDisabled() || (whoAmI && whoAmI.role === 'operator')}
                onClick={() => onUpdateInstitutionDescription(institution.id)}
              >
                Uložit
              </Button>
            </div>
          </Form>

          <Button
            size="sm"
            variant="danger"
            disabled={whoAmI && whoAmI.role === 'operator'}
            style={{ minWidth: '80%', display: 'block', margin: '2rem auto' }}
            onClick={() => {
              window.confirm('Opravdu chcete smazat instituci a všechny kontakty?') &&
                onDeleteInstitution(institution.id);
            }}
          >
            Smazat instituci a kontakty
          </Button>
        </Fragment>
      )}
    </div>
  );
}

import { FC, useContext } from 'react';
import AppContext from '../../../store/appContext';
import { Formik, Form } from 'formik';
import InputFieldLabelFront from '../InputFieldLabelFront/InputFieldLabelFront';
import { string, object } from 'yup';

const PairEmailToSurvey: FC<{ id: number }> = ({ id }) => {
  const { pairEmailToSurvey } = useContext(AppContext);

  const validationSchema = object().shape({
    surveyId: string()
      .required('Zadejte ID podnětu, prosím')
      .matches(/^[0-9]+$/, 'ID je pouze číslo ')
      .test('len', 'ID má 9 nebo 11 znaků', (val) => val?.length === 9 || val?.length === 11),
  });
  const initialValues = {
    surveyId: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        pairEmailToSurvey(id, values.surveyId);
        setTimeout(() => {
          window.location.reload();
        }, 100);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form className="text-dark mb-2 d-flex align-items-start">
          <InputFieldLabelFront
            name="surveyId"
            id="surveyId"
            placeholder="Zadejte ID podnětu"
            type="text"
          />
          <button
            className="ms-2 btn btn-outline-dark"
            style={{ height: '38px', padding: '2px 12px' }}
            type="submit"
            disabled={isSubmitting}
          >
            Přiřadit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default PairEmailToSurvey;

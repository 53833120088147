import { FC } from 'react';
import { Field, useField } from 'formik';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { InputProps } from '../../../interfaces/interfaces';

const InputFieldLabelBack: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <div className="form-check">
      <Field className="form-check-input" {...field} {...props} />
      <label className="form-check-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
    </div>
  );
};

export default InputFieldLabelBack;

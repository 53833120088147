import { Field, Form, Formik } from 'formik';
import { DateTime, Interval } from 'luxon';
import { Button, InputGroup } from 'react-bootstrap';
import React, { useContext } from 'react';
import { Download as DownloadIcon } from 'react-bootstrap-icons';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';

function* months(interval: Interval) {
  let cursor = interval.start.startOf('month');
  while (cursor <= interval.end) {
    yield cursor.toFormat('MM-yyyy');
    cursor = cursor.plus({ month: 1 });
  }
}

const ExportForm = () => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  // assign to operator
  const getExport = async (date: string) => {
    setIsLoading(true);
    setError(null);

    const [month, year] = date.split('-');
    const query = month && year ? `?month=${month}&year=${year}` : '';

    try {
      const response = await httpClient.get(`/exports/download${query}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        response.headers['content-disposition']?.split('filename=')[1] || 'zmenteto-export.xlsx'
      ); //this is the name with which the file will be downloaded
      link.click();

      setTimeout(() => window.URL.revokeObjectURL(url), 0);
    } catch (error: any) {
      setError(() => error.message);
    }
    setIsLoading(false);
  };

  const getDateList = () => {
    const start = DateTime.local(2017, 1, 1).setZone('Europe/Prague');
    const end = DateTime.now().setZone('Europe/Prague');
    return Array.from(months(Interval.fromDateTimes(start, end)));
  };

  const dateList = getDateList();

  const initialValues = {
    selectDate: dateList[dateList.length - 1],
  };

  return (
    <div className="ms-3">
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          await getExport(values.selectDate.trim());
          actions.setSubmitting(false);
        }}
      >
        {({ values, errors, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Field
                as="select"
                className="form-control"
                value={values.selectDate}
                name="selectDate"
              >
                {dateList.map((date, index) => (
                  <option key={index} value={date.toString()}>
                    {date.toString()}
                  </option>
                ))}
                <option key={'all'} value={''}>
                  Vše
                </option>
              </Field>
              <Button
                variant="light"
                type="submit"
                disabled={isSubmitting}
                className="border-start-0 border rounded ms-n3"
              >
                <DownloadIcon />
              </Button>
            </InputGroup>
            {errors.selectDate && (
              <StyledErrorMessage className="align-self-end">
                {errors.selectDate}
              </StyledErrorMessage>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ExportForm;

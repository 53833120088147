import { FC } from 'react';
import StyledText from '../StyledText/StyledText';
import StyledTextCatName from '../StyledTextCatName/StyledTextCatName';
import { Image } from 'react-bootstrap';

const FileFieldSimple: FC<{ label: string; files?: string[]; imageWidth?: number }> = ({
  label,
  files,
  imageWidth,
}) => {
  return (
    <StyledText>
      <div className="d-flex justify-content-between mb-1">
        <StyledTextCatName className="align-top">{label}</StyledTextCatName>
      </div>
      <div className="d-flex flex-wrap pb-2">
        {files?.map((file, i) => {
          return (
            <div key={`photo_nr_${i}`} className="position-relative">
              <Image
                thumbnail
                src={file}
                alt={label}
                className="mx-1"
                style={{ maxWidth: `${imageWidth ? imageWidth : 6}rem` }}
              />
            </div>
          );
        })}
      </div>
    </StyledText>
  );
};

export default FileFieldSimple;

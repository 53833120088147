import { useContext } from 'react';
import { string, object } from 'yup';
import { Field, Formik } from 'formik';
import AppContext from '../../../store/appContext';
import { Button, InputGroup } from 'react-bootstrap';
import { Search as SearchIcon } from 'react-bootstrap-icons';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { useNavigate } from "react-router-dom";

const SearchSchema = object().shape({
  regNum: string(),
});

const SearchForm = () => {
  const { limit, getSurveys } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div>
      <Formik
        initialValues={{ regNum: '' }}
        validationSchema={SearchSchema}
        onSubmit={(values, actions) => {
          navigate('./surveys');
          actions.setSubmitting(true);
          setTimeout(() => {
            getSurveys(limit, 0, '', values.regNum.trim());
            actions.setSubmitting(false);
          }, 300);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputGroup>
              <Field
                type="search"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.regNum}
                name="regNum"
                placeholder="Vyhledávání"
              />
              <Button
                variant="light"
                type="submit"
                disabled={isSubmitting}
                className="border-start-0 border rounded ms-n3"
              >
                <SearchIcon />
              </Button>
            </InputGroup>
            {errors.regNum && (
              <StyledErrorMessage id="feedback">{errors.regNum}</StyledErrorMessage>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SearchForm;

import { Dispatch, FC, SetStateAction } from 'react';
import { Card, Container } from 'react-bootstrap';
import { IChildren } from '../../../interfaces/interfaces';

interface IBWrapper {
  header: string;
  children: IChildren;
  onClick: Dispatch<SetStateAction<boolean>>;
  showStep?: null | number;
}

const ButtonWrapper: FC<IBWrapper> = ({ header, children, onClick, showStep }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title
          onClick={() => onClick(!showStep)}
          style={{ cursor: 'pointer', marginBottom: showStep ? '.5rem' : '0' }}
        >
          {header}
        </Card.Title>
        {showStep && <Container className="mt-4">{children}</Container>}
      </Card.Body>
    </Card>
  );
};

export default ButtonWrapper;

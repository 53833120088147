import { CodeDTO } from '../../../api';
import ApiContext from '../../../store/apiContext';
import React, { ChangeEventHandler, useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Card, Form, InputGroup, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';

export type TSKApiFormRequest = {
  category: number;
  description: string;
};

type TSKApiFormProps = {
  initialDescription: string;
  onCancel: () => void;
  onRequest: (req: TSKApiFormRequest) => void;
  submitting: boolean;
};

const TSKApiForm: React.FC<TSKApiFormProps> = (props) => {
  const { initialDescription, onCancel, onRequest, submitting } = props;

  const { CodeListsApi } = useContext(ApiContext);

  const [loading, setLoading] = useState(false);
  const [categoriesReload, setCategoriesReload] = useState(Date.now());
  const [errorLoadingTSKCodeList, setErrorLoadingTSKCodeList] = useState(false);
  const [categories, setCategories] = useState<CodeDTO[]>([]);
  const [category, setCategory] = useState<number | null>(null);
  const [errors, setErrors] = useState<{ category: boolean; description: boolean }>({
    category: false,
    description: false,
  });
  const [description, setDescription] = useState(initialDescription);

  useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription]);

  useEffect(() => {
    // Load TSK categories
    const ac = new AbortController();

    const loader = async () => {
      setErrorLoadingTSKCodeList(false);
      setLoading(true);

      try {
        const d = await CodeListsApi.codeListsControllerGetTSKCategories();
        if (d.data.length === 0) {
          throw new Error();
        }
        setCategories(d.data);
      } catch (e) {
        setErrorLoadingTSKCodeList(true);
      } finally {
        setLoading(false);
      }
    };

    void loader();

    return () => ac.abort();
  }, [categoriesReload, CodeListsApi]);

  const handleCategoryChange = useCallback<ChangeEventHandler<HTMLSelectElement>>((e) => {
    setErrors((v) => ({ ...v, category: false }));
    const n = parseInt(e.target.value, 10);
    if (isNaN(n)) {
      setCategory(null);
      return;
    }
    setCategory(n);
  }, []);

  useEffect(() => {}, []);

  const submitHandler = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      if (!category) {
        toast.error(`Vyberte prosím kategorii TSK`);
        setErrors((v) => ({ ...v, category: true }));
        return;
      }

      onRequest({
        category,
        description,
      });
    },
    [category, description, onRequest]
  );

  const disableCategories = loading || errorLoadingTSKCodeList;
  const disableSubmit = submitting || disableCategories;

  return (
    <Card>
      <Card.Body>
        <Form noValidate onSubmit={submitHandler}>
          {loading && (
            <Alert variant="info" className="d-flex align-items-center mb-3">
              <Spinner animation="border" className="me-3" size="sm" />
              <span>Probíhá načítání kategorií TSK...</span>
            </Alert>
          )}
          {errorLoadingTSKCodeList && (
            <Alert variant="warning">
              Nepodařilo se načíst kategorie,{' '}
              <Alert.Link onClick={() => setCategoriesReload(Date.now())}>
                zkuste to prosím znovu.
              </Alert.Link>
            </Alert>
          )}
          <InputGroup className="d-flex mb-2">
            <InputGroup.Text>
              <label htmlFor="tsk-category">Kategorie TSK</label>
            </InputGroup.Text>
            <Form.Select
              isInvalid={errors.category}
              disabled={disableCategories}
              name="tsk-category"
              value={`${category === null ? '' : category}`}
              onChange={handleCategoryChange}
              required
            >
              <option selected={category === null}></option>
              {categories.map((c) => (
                <option value={c.code} selected={c.code === category}>
                  {c.label}
                </option>
              ))}
            </Form.Select>
          </InputGroup>

          <InputGroup className="d-flex mb-2">
            <InputGroup.Text>
              <label htmlFor="tsk-description">Popis</label>
            </InputGroup.Text>
            <Form.Control
              isInvalid={errors.description}
              as="textarea"
              rows={4}
              type="text"
              name="tsk-description"
              placeholder="Zde napište speciální instrukce pro TSK."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </InputGroup>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-danger me-3"
              type="button"
              onClick={onCancel}
              style={{ maxWidth: '8rem' }}
            >
              Zpět
            </button>
            <button
              className={`btn${disableSubmit ? '' : ' btn-outline-success'}`}
              type="submit"
              style={{ maxWidth: '8rem' }}
              disabled={disableSubmit}
            >
              Odeslat
            </button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default TSKApiForm;

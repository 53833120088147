import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  text-decoration: none;
  padding-left: 0.2rem;
  color: #3400a6;
  &:hover {
    color: red;
  }
`;

export default StyledLink;

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperatorResponseDTO
 */
export interface OperatorResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof OperatorResponseDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorResponseDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorResponseDTO
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorResponseDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorResponseDTO
     */
    role: string;
}

export function OperatorResponseDTOFromJSON(json: any): OperatorResponseDTO {
    return OperatorResponseDTOFromJSONTyped(json, false);
}

export function OperatorResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'surname': json['surname'],
        'username': json['username'],
        'role': json['role'],
    };
}

export function OperatorResponseDTOToJSON(value?: OperatorResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'surname': value.surname,
        'username': value.username,
        'role': value.role,
    };
}


import { useState } from 'react';

export const STORAGE_KEY = 'auth:signInState';

export default function useToken() {
  const getToken = () => {
    return localStorage.getItem(STORAGE_KEY);
  };

  const [token, setToken] = useState<string | null>(getToken());

  const saveToken = (userToken: string | null) => {
    if (!userToken) {
      localStorage.removeItem(STORAGE_KEY);
    } else {
      localStorage.setItem(STORAGE_KEY, userToken as string);
    }
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}

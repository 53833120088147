import React, { FC } from 'react';
// import AppContext from '../../../store/appContext';
import { Col, Container, Image, Row } from 'react-bootstrap';
import LogoPraha from '../../../assets/img/logos/Praha_logo_bar.png';
import LogoOperatorICT from '../../../assets/img/logos/logo_oict_bila.png';

const Footer: FC = () => {
  // const ctx = React.useContext(AppContext);

  return (
    <div>
      <footer className="py-3 bg-primary-light">
        <Container>
          {/* <hr /> */}
          <Row className="my-5">
            <Col
              xs={12}
              sm={{ span: 2, offset: 4 }}
              className="d-flex align-items-center justify-content-center justify-content-md-end mb-3"
            >
              <a href="https://praha.eu" target="_blank" rel="noreferrer">
                <Image fluid src={LogoPraha} alt="Magistrát hl. m. Prahy" />
              </a>
            </Col>
            <Col
              xs={12}
              sm={{ span: 2 }}
              className="d-flex align-items-center justify-content-center mb-3"
            >
              <a href="https://operatorict.cz/" target="_blank" rel="noreferrer">
                <Image
                  fluid
                  style={{ maxHeight: '330px' }}
                  src={LogoOperatorICT}
                  alt="Operátor ICT"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;

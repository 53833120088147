import { useContext } from 'react';
import AppContext from '../../../store/appContext';

const Tag = () => {
  const { oper } = useContext(AppContext);

  return <div>{<h4 className="px-3 mb-3 text-center">Operator: {oper}</h4>}</div>;
};

export default Tag;

import { FC, useContext } from 'react';
import AppContext from '../../../store/appContext';
import { Modal } from 'react-bootstrap';
import AggregationMap from '../AggregationMap/AggregationMap';
import ChangeGPS from '../ChangeGPS/ChangeGPS';
import Gallery from '../Gallery/Gallery';

const ModalHQ: FC = () => {
  const { detail, showModalHQ, setShowModalHQ, filesToShow } = useContext(AppContext);

  const handleClose = () => setShowModalHQ({ show: false, type: '', regnum: '' });

  let header = 'Načítám';
  if (showModalHQ.type === 'aggregation') {
    header = 'Mapa podnětů';
  }
  if (showModalHQ.type === 'gps') {
    header = 'Změna polohy podnětu';
  }
  if (showModalHQ.type === 'photos') {
    header = 'Galerie';
  }
  
  return (
    <Modal
      show={showModalHQ.show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{showModalHQ.regnum!}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{header}</h4>
        {showModalHQ.type === 'aggregation' && <AggregationMap />}
        {showModalHQ.type === 'gps' && <ChangeGPS />}
        {showModalHQ.type === 'photos' && (
          <Gallery files={filesToShow} survey_id={showModalHQ.survey_id ?? detail?.id!} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-dark shadow"
          style={{ maxWidth: '97%' }}
          onClick={handleClose}
        >
          Zavřít
        </button>
      </Modal.Footer>
    </Modal>
    );
};

export default ModalHQ;


import { createContext, useCallback, useContext, useState } from 'react';
import AppContext from './appContext';
import {
  IChildren,
  IDetail,
  IFiles,
  IMail,
  IMailPrep,
  ISolutionAction,
  ISolutionCtx,
  ISolutionStep,
  ISolutionTreatSurvey,
} from '../interfaces/interfaces';
import AxiosContext from './axiosContext';
import toast from 'react-hot-toast';

const SolutionContext = createContext<ISolutionCtx>({
  getSurveyByStepId: (
    stepId: number,
    detail: IDetail,
    options?: string,
    toInstitution?: boolean
  ) => {},
  emailPrep: undefined,
  setEmailPrep: () => {},
  sendEmail: (addToURL: string, formObject: IMail) => {},
  sendAll: (survey_id: string, addToURL: string, formObject: any, files?: File[]) => {},
  treatSurvey: () => Promise.reject('not implemented'),
  getSolutionSteps: () => {},
  stepIds: undefined,
  assignTo: (survey_id: string, op_id: number) => {},
  deletePhoto: (survey_id: string, photo_id: number) => {},
  addPhoto: (survey_id: string, files: File[]) => {},
  addNote: (survey_id: string, note: string) => {},
  changeGPS: (survey_id: string, gps: string) => {},
  changeStatus: (survey_id: string, status: string) => {},
  addTagSolved: (survey_id: string) => {},
  solutionAction: null,
  setSolutionAction: () => {},
  solutionStepName: '',
  setSolutionStepName: (name: string) => {},
});

export const SolutionContextProvider = ({ children }: IChildren) => {
  const { setIsLoading, setError, whoAmI } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);
  const [stepIds, setStepIds] = useState<ISolutionStep[] | undefined>();

  const [emailPrep, setEmailPrep] = useState<IMailPrep | undefined>(undefined);
  const [solutionAction, setSolutionAction] = useState<ISolutionAction | null>(null);
  const [solutionStepName, setSolutionStepName] = useState<string>('');

  const getSolutionSteps = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/surveys/solution-steps`);
      setStepIds(response.data);
    } catch (error: any) {
      if (error.response.data.statusCode === 403) {
        toast.error(`Chyba: Nemáte potřebné oprávnění`);
      } else {
        toast.error(`Chyba: ${error.response.data.message}`);
      }
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoAmI]);

  const getSurveyByStepId = async (
    stepId: number,
    detail: IDetail,
    options?: string,
    toInstitution?: boolean
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(
        `/surveys/${detail!.id}/solution-steps/${stepId}?${options}`
      );
      const data = response.data;
      setEmailPrep({
        bcc: data.bcc,
        cc: data.cc,
        header: data.header,
        message: data.message,
        step_id: stepId,
        status_id: data.status_id!,
        survey_id: detail?.id,
        to: toInstitution ? '' : detail.email ?? '',
        subject: data.subject,
        files: toInstitution ? detail.files! : [],
      });
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  //sendEmail
  const sendEmail = async (addToURL: string, formObject: IMail) => {
    setIsLoading(true);
    setError(null);
    const jsonOutput = JSON.stringify(formObject, null, 2);
    try {
      const response = await httpClient.post(addToURL, jsonOutput, {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Odeslalo jsem `);
      return response.data;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // send all data
  const sendAll = async (
    survey_id: string,
    addToURL: string,
    formObject: IMail,
    files?: File[],
    alreadyFiles?: IFiles[]
  ) => {
    setIsLoading(true);
    setError(null);
    const formData = new FormData();
    const arr = [...(files as unknown as FileList)];
    for (let i = 0; i < arr.length; i++) {
      formData.append('files', arr[i]);
    }
    try {
      const responseFromFiles = await httpClient.post(`/surveys/${survey_id}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      formObject.attachments = alreadyFiles
        ? alreadyFiles.concat(responseFromFiles.data)
        : responseFromFiles.data;
      const jsonOutput = JSON.stringify(formObject, null, 2);
      const responseFromSurvey = await httpClient.post(addToURL, jsonOutput, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Odeslalo jsem `);
      return responseFromSurvey;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // assign to operator
  const assignTo = async (survey_id: string, op_id: number) => {
    setIsLoading(true);
    setError(null);
    const userObj = { userId: op_id };
    try {
      const response = await httpClient.post(`/surveys/${survey_id}/user`, userObj, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Přiděleno operátorovi ${op_id}`);
      return response;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // mono fnc
  const treatSurvey: ISolutionTreatSurvey = async (urlSuffix, options) => {
    setIsLoading(true);
    setError(null);
    const { body, signal } = options || {};

    try {
      const response = await httpClient.post(urlSuffix, body, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      });
      toast.success(`Podnět zpracován`);
      return response.data;
    } catch (error: any) {
      switch (error.response.data.message) {
        case 'already-sent':
          toast.error(`Chyba: Podnět byl již odeslán`);
          setError(() => error.message);
          break;
        case 'not-found':
          toast.error(`Chyba: Podnět nebyl nalezen`);
          setError(() => error.message);
          break;
        case 'file-not-found':
          toast.error(`Chyba: Nebyl nalezený příložený soubor`);
          setError(() => error.message);
          break;
        default:
          toast.error(`Chyba: ${error.response.data.message}`);
          setError(() => error.message);
      }
      return new Error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //add photo
  const addPhoto = async (survey_id: string, files: File[]) => {
    setIsLoading(true);
    setError(null);
    const formData = new FormData();
    const arr = [...(files as unknown as FileList)];
    for (let i = 0; i < arr.length; i++) {
      formData.append('files', arr[i]);
    }
    try {
      const responseFromFiles = await httpClient.post(`/surveys/${survey_id}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(`Fotografie k podnětu ${survey_id} byla/y přidány`);
      return responseFromFiles.data;
    } catch (error: any) {
      if (error.response.data.statusCode === 403) {
        toast.error(`Chyba: Nemáte potřebné oprávnění`);
      } else {
        toast.error(`Chyba: ${error.response.data.message}`);
      }
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // delete photo
  const deletePhoto = async (survey_id: string, photo_id: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.delete(`/surveys/${survey_id}/files/${photo_id}`);
      toast.success(`Fotografie podnětu ${survey_id} byla odebrána`);
      return response.data;
    } catch (error: any) {
      if (error.response.data.statusCode === 403) {
        toast.error(`Chyba: Nemáte potřebné oprávnění`);
      } else {
        toast.error(`Chyba: ${error.response.data.message}`);
      }
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // add note
  const addNote = async (survey_id: string, note: string) => {
    setIsLoading(true);
    setError(null);
    const jsonOutput = JSON.stringify({ note: note }, null, 2);
    try {
      const response = await httpClient.post(`/surveys/${survey_id}/note`, jsonOutput, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Poznámka k podnětu ${survey_id} byla přidána`);
      return response.data;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // change GPS
  const changeGPS = async (survey_id: string, gps: string) => {
    setIsLoading(true);
    setError(null);
    const jsonOutput = JSON.stringify({ gps: gps }, null, 2);
    try {
      const response = await httpClient.post(`/surveys/${survey_id}/gps`, jsonOutput, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Poloha GPS podnětu ${survey_id} byla změněna`);
      return response.data;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // change status
  const changeStatus = async (survey_id: string, status: string) => {
    setIsLoading(true);
    setError(null);
    const jsonOutput = JSON.stringify({ status: status }, null, 2);
    try {
      const response = await httpClient.post(`/surveys/${survey_id}/status`, jsonOutput, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Status podnětu ${survey_id} byl změněn`);
      return response.data;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // add tag solved (uz jste zmenili)
  const addTagSolved = async (survey_id: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.post(`/surveys/${survey_id}/solved`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Podnět ${survey_id} je totálně vyřešený`);
      return response.data;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };

  return (
    <SolutionContext.Provider
      value={{
        getSurveyByStepId,
        emailPrep,
        setEmailPrep,
        sendEmail,
        sendAll,
        treatSurvey,
        getSolutionSteps,
        stepIds,
        assignTo,
        deletePhoto,
        addPhoto,
        addNote,
        changeGPS,
        changeStatus,
        addTagSolved,
        solutionAction,
        setSolutionAction,
        solutionStepName,
        setSolutionStepName,
      }}
    >
      {children}
    </SolutionContext.Provider>
  );
};

export default SolutionContext;

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CodeListResponseDTO,
    CodeListResponseDTOFromJSON,
    CodeListResponseDTOToJSON,
    ForbiddenResponseDTO,
    ForbiddenResponseDTOFromJSON,
    ForbiddenResponseDTOToJSON,
    UnauthorizedResponseDTO,
    UnauthorizedResponseDTOFromJSON,
    UnauthorizedResponseDTOToJSON,
} from '../models';

/**
 * 
 */
export class CodeListsApi extends runtime.BaseAPI {

    /**
     */
    async codeListsControllerGetTSKCategoriesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CodeListResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/code-lists/tsk/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodeListResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async codeListsControllerGetTSKCategories(initOverrides?: RequestInit): Promise<CodeListResponseDTO> {
        const response = await this.codeListsControllerGetTSKCategoriesRaw(initOverrides);
        return await response.value();
    }

}

import { FC, useContext, useEffect } from 'react';
import AppContext from '../../../store/appContext';
import { NavLink, Outlet } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { StyledScrollableDiv } from '../../ui/StyledScrollableDiv/StyledScrollableDiv';
import { Props } from '../../../interfaces/interfaces';

const Admin: FC<Props> = (props): JSX.Element => {
  const { getWhoAmI, whoAmI } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getWhoAmI(), []);

  return (
    <Container fluid style={{ minHeight: '70vh' }}>
      <h1 className="px-3 mb-5 text-center">{props.header}</h1>
      <Row>
        <Col md={4}>
          <StyledScrollableDiv>
            <div className="clearfix mb-2">
              <h4 className="d-inline-block">Administrace</h4>
            </div>
            <ul className="list-unstyled">
              {whoAmI && whoAmI.role === 'admin' && (
                <li
                  key="users"
                  className="list-group list-group-flush"
                  style={{
                    border: '1px solid rgba(0,0,0,.125)',
                    borderRadius: '0.4rem',
                    marginBottom: '0.2rem',
                  }}
                >
                  <NavLink to="users" className={`list-group-item list-group-item-action py-3`}>
                    Správa účtů
                  </NavLink>
                </li>
              )}
              <li
                key="templates"
                className="list-group list-group-flush"
                style={{
                  border: '1px solid rgba(0,0,0,.125)',
                  borderRadius: '0.4rem',
                  marginBottom: '0.2rem',
                }}
              >
                <NavLink to="templates" className={`list-group-item list-group-item-action py-3`}>
                  Nastavení odpovědi
                </NavLink>
              </li>
              <li
                key="apologies"
                className="list-group list-group-flush"
                style={{
                  border: '1px solid rgba(0,0,0,.125)',
                  borderRadius: '0.4rem',
                  marginBottom: '0.2rem',
                }}
              >
                <NavLink to="apologies" className={`list-group-item list-group-item-action py-3`}>
                  Nastavení odesílání omluvenek
                </NavLink>
              </li>
            </ul>
          </StyledScrollableDiv>
        </Col>
        <Col md={8}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;

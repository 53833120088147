import React from 'react';
import { Router } from './components/router';
import { BrowserRouter } from 'react-router-dom';
import NavTop from './components/layout/NavTop/NavTop';
import Footer from './components/layout/Footer/Footer';

// for use in need of bootstrap customization
import './styles/custom.scss';
import { AuthContextProvider } from './store/authContext';
import { AxiosContextProvider } from './store/axiosContext';
import { AppContextProvider } from './store/appContext';
import { SolutionContextProvider } from './store/solutionContext';
import ModalHQ from './components/ui/ModalHQ/ModalHQ';
import { Toaster } from 'react-hot-toast';
import { ApiContextProvider } from './store/apiContext';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <ApiContextProvider>
          <AxiosContextProvider>
            <AppContextProvider>
              <SolutionContextProvider>
                <Toaster
                  toastOptions={{
                    style: {
                      background: '#f8f8f8',
                    },
                    error: {
                      duration: 5000,
                      style: {
                        color: 'red',
                        border: 'solid red 2px',
                      },
                    },
                    success: {
                      duration: 2500,
                      style: {
                        color: 'green',
                        border: 'solid green 2px',
                      },
                    },
                  }}
                />
                <ModalHQ />
                <NavTop />
                <main>
                  <Router />
                </main>
                <Footer />
              </SolutionContextProvider>
            </AppContextProvider>
          </AxiosContextProvider>
        </ApiContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export default App;

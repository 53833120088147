import { DetailedHTMLProps, FC, InputHTMLAttributes, MutableRefObject } from 'react';
import styled, { css } from 'styled-components';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  ref?: MutableRefObject<HTMLInputElement>;
  name: string;
  mobVertical?: boolean;
};

const InputContainer = styled.div<{ mobVertical?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;

  ${({ mobVertical }) =>
    mobVertical &&
    css`
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }
    `}
`;

const StyledInput = styled.input`
  border: 3px solid #2dcfa7;
  width: 100%;
  text-overflow: ellipses;
`;

export const InputStyledBasic: FC<InputProps> = ({ label, mobVertical, ...restProps }) => {
  return (
    <InputContainer mobVertical={mobVertical}>
      {label && (
        <label className="form-label" htmlFor={restProps.id || restProps.name}>
          {label}
        </label>
      )}
      <StyledInput className="form-control" {...restProps} />
    </InputContainer>
  );
};

import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../store/authContext';

interface ILocationState {
  from: {
    pathname: string;
  };
}

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const { REDIRECT_AFTER_LOGIN, token } = useContext(AuthContext);

  const location = useLocation();
  const state = location.state as ILocationState;
  const redirect = state?.from?.pathname || REDIRECT_AFTER_LOGIN;

  return token ? <Navigate to={redirect} replace /> : children;
};

export default PublicRoute;

import { FC, useContext, useState } from 'react';
import SolutionContext from '../../../store/solutionContext';
import { Formik, Form } from 'formik';
import MailEditor from '../MailEditor/MailEditor';

interface Values {
  note?: string;
}

const AddNoteForm: FC<{ id: string; onSave: () => void; prevNotes: string }> = ({
  id,
  onSave,
  prevNotes,
}) => {
  const { addNote } = useContext(SolutionContext);
  const [note, setNote] = useState<string>();

  const readTextHandler = (text: string) => {
    setNote(text);
  };

  const initialValues: Values = {
    note: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        addNote(id, note!);
        onSave();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form className="mb-3">
          <div className="mb-5">
            <MailEditor initialText={prevNotes} readText={readTextHandler} />
          </div>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-dark"
              type="submit"
              style={{ width: '100%' }}
              disabled={isSubmitting}
            >
              Uložit poznámku
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNoteForm;

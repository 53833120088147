import React, { FC, useCallback, useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { configuration } from '../../../configuration';
import { Navigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { object, ref, string } from 'yup';
import FormikPasswordInput from '../../ui/FormikPasswordInput/FormikPasswordInput';
import toast from 'react-hot-toast';
import { Props } from '../../../interfaces/interfaces';
import { passwordYup } from '../../ui/_Utils/yup_validations';

interface IPasswordReset {
  newPassword: string;
  showNewPassword: boolean;
  newPasswordConfirm: string;
  showNewPasswordConfirm: boolean;
}

const ResetPass: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);
  const [resetSuccess, setResetSuccess] = useState<string | null>(null);

  const { token } = useParams();

  const resetPassword = useCallback(async (password: string, confirmPassword: string) => {
    setIsLoading(true);
    setError(null);
    try {
      let response = await httpClient.post(
        `${configuration.API_URL}/auth/reset-password`,
        { password, token },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setResetSuccess(response.toString());
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
      setResetSuccess(null);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = object().shape({
    newPassword: passwordYup,
    newPasswordConfirm: passwordYup.when('newPassword', {
      is: (val: string) => !!(val && val.length > 0),
      then: string().oneOf([ref('newPassword')], 'Hesla se musí shodovat'),
    }),
  });

  const initialValues: IPasswordReset = {
    newPassword: '',
    showNewPassword: false,
    newPasswordConfirm: '',
    showNewPasswordConfirm: false,
  };

  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <h1 className="px-3 mb-5 display-5 text-center">{props.header}</h1>
        {resetSuccess ? (
          <div>
            {toast.success(`Heslo bylo nastaveno`)}
            <Navigate to="/" />
          </div>
        ) : (
          <Row>
            <Col xs={12} sm={{ span: '6', offset: '3' }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={true}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  await resetPassword(values.newPassword, values.newPasswordConfirm);
                  actions.setSubmitting(false);
                }}
              >
                {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                  <Form className="text-dark text-center mt-4">
                    <Card border="info" className="shadow-sm">
                      <Card.Body>
                        <Card.Title className="mb-3">Změna hesla</Card.Title>
                        <FormikPasswordInput
                          id="newPassword"
                          name="newPassword"
                          label="Nové heslo (min. 6 znaků)"
                          placeholder="Nové heslo"
                        />
                        <FormikPasswordInput
                          id="newPasswordConfirm"
                          name="newPasswordConfirm"
                          label="Potvrzení hesla"
                          placeholder="Potvrzení hesla"
                        />
                        <button
                          className="btn btn-outline-dark mb-3"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Změnit heslo
                        </button>
                      </Card.Body>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default ResetPass;

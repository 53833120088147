import { FC, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { configuration } from '../../../configuration';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import FormikPasswordInput from '../../ui/FormikPasswordInput/FormikPasswordInput';
import { PersonCircle } from 'react-bootstrap-icons';
import { Props } from '../../../interfaces/interfaces';
import { IUser } from '../../../types';
import { object, ref, string } from 'yup';
import { passwordYup } from '../../ui/_Utils/yup_validations';
import toast from 'react-hot-toast';

interface IPasswordChange {
  currentPassword: string;
  showCurrentPassword: boolean;
  newPassword: string;
  showNewPassword: boolean;
  newPasswordConfirm: string;
  showNewPasswordConfirm: boolean;
}

const Profile: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [user, setUser] = useState<IUser | null>(null);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/users/me`);
      setUser(response.data);
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePassword = useCallback(async (currentPassword: string, newPassword: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await httpClient.post(
        `${configuration.API_URL}/auth/change-password`,
        { currentPassword, newPassword },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success(`Heslo bylo změněno`);
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: IPasswordChange = {
    currentPassword: '',
    showCurrentPassword: false,
    newPassword: '',
    showNewPassword: false,
    newPasswordConfirm: '',
    showNewPasswordConfirm: false,
  };

  const validationSchema = object().shape({
    currentPassword: string().required('Vyplňte, prosím'),
    newPassword: passwordYup,
    newPasswordConfirm: passwordYup.when('newPassword', {
      is: (val: string) => !!(val && val.length > 0),
      then: string().oneOf([ref('newPassword')], 'Hesla se musí shodovat'),
    }),
  });

  return (
    <section className="top">
      <h1 className="px-3 mb-5 text-center">{props.header}</h1>
      <Container style={{ minHeight: '70vh' }}>
        <Row>
          <Col md={6}>
            <Card border="info" className="mt-4">
              <Card.Header className="text-center py-3 bg-white">
                <PersonCircle size="55px" />
              </Card.Header>
              <Card.Body>
                <Card.Title className="mb-3">Základní informace</Card.Title>
                <Card.Text>
                  <span className="mb-2 d-block">
                    <strong>Jméno:</strong> {user?.name ?? '---'}
                    <br />
                  </span>
                  <span className="mb-2 d-block">
                    <strong>Příjmení:</strong> {user?.surname ?? '---'}
                    <br />
                  </span>
                  <span className="mb-2 d-block">
                    <strong>Přihlašovací e-mail:</strong> {user?.username}
                    <br />
                  </span>
                  <span className="mb-2 d-block">
                    <strong>Role:</strong> {user?.role}
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={true}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                await changePassword(values.currentPassword, values.newPassword);
                actions.setSubmitting(false);
              }}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form className="text-dark text-center mt-4">
                  <Card border="info" className="shadow-sm">
                    <Card.Body>
                      <Card.Title className="mb-3">Změna hesla</Card.Title>
                      <FormikPasswordInput
                        id="currentPassword"
                        name="currentPassword"
                        label="Staré heslo"
                        placeholder="Staré heslo"
                      />
                      <FormikPasswordInput
                        id="newPassword"
                        name="newPassword"
                        label="Nové heslo (min. 6 znaků)"
                        placeholder="Nové heslo"
                      />
                      <FormikPasswordInput
                        id="newPasswordConfirm"
                        name="newPasswordConfirm"
                        label="Potvrzení hesla"
                        placeholder="Potvrzení hesla"
                      />
                      <button
                        className="btn btn-outline-dark mb-3"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Změnit heslo
                      </button>
                    </Card.Body>
                  </Card>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Profile;

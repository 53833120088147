import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import toast from 'react-hot-toast';
import { ITemplate } from '../../../types';

type TEditTemplate = Pick<ITemplate, 'to' | 'cc' | 'bcc' | 'subject' | 'message' | 'add_message'>;

export default function AdminTemplates() {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [templates, setTemplates] = useState<Record<string, ITemplate>>({});
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(null);
  const [selectedTemplateEdit, setSelectedTemplateEdit] = useState<TEditTemplate>({
    to: null,
    cc: null,
    bcc: null,
    subject: '',
    message: '',
    add_message: '',
  });

  const getTemplates = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get<ITemplate[]>(`/email-templates`);
      setTemplates(
        response.data.reduce((res: Record<number, ITemplate>, el) => {
          res[el.id] = el;
          return res;
        }, {})
      );
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedTemplateEdit({
      to: selectedTemplate?.to ?? null,
      cc: selectedTemplate?.cc ?? null,
      bcc: selectedTemplate?.bcc ?? null,
      subject: selectedTemplate?.subject ?? '',
      message: selectedTemplate?.message ?? '',
      add_message: selectedTemplate?.add_message ?? '',
    });
  }, [selectedTemplate]);

  const onTemplateSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTemplate(templates[event.target.value] || null);
  };

  const handleSubmit = async (event: FormEvent, templateId: number) => {
    event.preventDefault();
    try {
      await httpClient.put(`/email-templates/${templateId}`, selectedTemplateEdit);
      await getTemplates();
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    setSelectedTemplateEdit({
      ...selectedTemplateEdit!,
      [name]: value,
    });
  };

  const handleEditorChange = (content: string, editor: TinyMCEEditor) => {
    setSelectedTemplateEdit({
      ...selectedTemplateEdit!,
      [editor.id]: content,
    });
  };

  return (
    <div className="mb-5">
      <h3 className="pb-3">Nastavení automatických odpovědí</h3>
      <Form onSubmit={(event) => handleSubmit(event, selectedTemplate!.id)}>
        <Form.Group className="mb-6">
          <Form.Select value={selectedTemplate?.id} onChange={(event) => onTemplateSelect(event)}>
            <option key={'template_default'}>-- Vyberte automatickou odpověď --</option>
            {Object.values(templates).map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {!!selectedTemplate && (
          <>
            <InputGroup className="mt-5 mb-3">
              <InputGroup.Text id="basic-addon1">Komu:</InputGroup.Text>
              <Form.Control
                type="email"
                name="to"
                placeholder="uzivatel@email.cz"
                value={selectedTemplateEdit?.to as string}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Kopie:</InputGroup.Text>
              <Form.Control
                type="email"
                name="cc"
                placeholder="uzivatel@email.cz"
                value={selectedTemplateEdit?.cc as string}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Skrytá kopie:</InputGroup.Text>
              <Form.Control
                type="email"
                name="bcc"
                placeholder="uzivatel@email.cz"
                value={selectedTemplateEdit?.bcc as string}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Předmět:</InputGroup.Text>
              <Form.Control
                type="text"
                name="subject"
                placeholder="Text"
                value={selectedTemplateEdit?.subject}
                onChange={handleChange}
              />
            </InputGroup>
            <Form.Label column sm="2">
              <b>Zpráva:</b>
            </Form.Label>
            <Editor
              apiKey='rpt21d4nv6z4lcyfs1y1mxwv9segqhfhtvx3hqf5pp5maeca' // account: vyvoj@operatorict.cz
              id="message"
              value={selectedTemplateEdit.message}
              onEditorChange={handleEditorChange}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar: `undo redo | insert | blockquote | styleselect | bold italic |
                  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code help`,
                menu: {
                  insert: { title: 'Insert', items: 'insertfile' },
                },
              }}
            />
            <Form.Label column sm="2" className="mt-3">
              <b>Dodatečná zpráva:</b>
            </Form.Label>
            <Editor
              apiKey='rpt21d4nv6z4lcyfs1y1mxwv9segqhfhtvx3hqf5pp5maeca' // account: vyvoj@operatorict.cz
              id="add_message"
              value={selectedTemplateEdit.add_message}
              onEditorChange={handleEditorChange}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar: `undo redo | insert | blockquote | styleselect | bold italic |
                  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code help`,
                menu: {
                  insert: { title: 'Insert', items: 'insertfile' },
                },
              }}
            />
            <div className="clearfix">
              <Button type="submit" size="sm" variant="success" className="mt-3 float-end">
                Uložit
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

import { FC } from 'react';
import LoginForm from '../../ui/LoginForm/LoginForm';
import { Col, Container, Row } from 'react-bootstrap';
import { Props } from '../../../interfaces/interfaces';

const ForgotPass: FC<Props> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <h1 className="px-3 mb-5 display-5 text-center">{props.header}</h1>
        <Row>
          <Col xs={12} sm={{ span: '6', offset: '3' }}>
            <LoginForm username fn="forgot" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForgotPass;

import { FC, Fragment, useContext, useEffect } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import StyledSolutionCard from '../../ui/StyledSolutionCard/StyledSolutionCard';
import SolutionSelections from '../SolutionSelectionElements';
import SolutionStepButton from '../SolutionButton';
import SolutionAction from '../SolutionAction';

const Solution: FC = () => {
  const { detail, whoAmI } = useContext(AppContext);
  const { getSolutionSteps, stepIds, solutionAction, solutionStepName } =
    useContext(SolutionContext);

  useEffect(() => {
    getSolutionSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let solution = (
    <Col className="d-flex justify-content-center">
      <Spinner animation="border" />
    </Col>
  );
  if (whoAmI && whoAmI.role === 'guest') {
    solution = (
      <Col className="d-flex justify-content-center">
        <h4>{`Uživatel ${whoAmI.role} nemá potřebné oprávnění`}</h4>
      </Col>
    );
  }
  if (stepIds && whoAmI && whoAmI.role !== 'guest') {
    solution = (
      <Fragment>
        {solutionAction !== null && <SolutionAction action={solutionAction} />}
        <SolutionSelections />
        <SolutionStepButton />
      </Fragment>
    );
  }

  return (
    <StyledSolutionCard>
      <Card.Body>
        <Card.Title className="display-6">
          <Row className="mx-1">Řešení {detail?.reg_num}</Row>
          <h5 className="mx-1">{solutionStepName.length > 0 ? ` - ${solutionStepName}` : ''}</h5>
        </Card.Title>
        {solution}
      </Card.Body>
    </StyledSolutionCard>
  );
};

export default Solution;

import { FC, Fragment, memo, useContext } from 'react';
import AppContext from '../../../store/appContext';
import { AuthContext } from '../../../store/authContext';
import SolutionContext from '../../../store/solutionContext';
import { Link } from 'react-router-dom';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import ExportForm from '../../ui/ExportForm/ExportForm';
import SearchForm from '../../ui/SearchForm/SearchForm';
import LogoZmenteTo from '../../../assets/img/logos/zmente_to_logo_color.svg';

const NavTop: FC = () => {
  const { whoAmI } = useContext(AppContext);
  const { logOut, LOGIN_ROUTE } = useContext(AuthContext);
  const { setSolutionAction } = useContext(SolutionContext);

  const clickHandler = () => {
    setSolutionAction(null);
  };

  return (
    <header>
      <Navbar expand="md" fixed="top" className="mb-5 justify-content-center bg-light shadow">
        <Container fluid="md">
          <Navbar.Brand>
            <Link to={LOGIN_ROUTE} onClick={clickHandler}>
              <Image src={LogoZmenteTo} width="80" className="bg-light rounded" alt="Změňte.to" />
            </Link>
          </Navbar.Brand>
          <Fragment>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-3" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="pe-5">
                {whoAmI && (
                  <Nav.Item>
                    <SearchForm />
                  </Nav.Item>
                )}
                {whoAmI && whoAmI.role !== 'guest' && (
                  <Nav.Item className="mt-2">
                    <Link
                      className="ms-3 link-dark"
                      to="/institutions"
                      style={{ textDecoration: 'none' }}
                    >
                      instituce
                    </Link>
                  </Nav.Item>
                )}
                {whoAmI && (
                  <NavDropdown title="hodnocení" className="ms-3 link-dark">
                    <Link
                      to="/ratings"
                      className="mx-3 link-dark text-nowrap d-block"
                      style={{ textDecoration: 'none' }}
                    >
                      Výsledky hodnocení
                    </Link>
                    <Link
                      to="/ratings-offices"
                      className="mx-3 link-dark text-nowrap d-block"
                      style={{ textDecoration: 'none' }}
                    >
                      Hodnocení dle útvarů
                      <br />
                    </Link>
                    <Link
                      to="/ratings-questions"
                      className="mx-3 link-dark text-nowrap d-block"
                      style={{ textDecoration: 'none' }}
                    >
                      Hodnocení dle otázek
                      <br />
                    </Link>
                    <NavDropdown.Divider />
                    <Link
                      to="/organizations"
                      className="mx-3 link-dark text-nowrap d-block"
                      style={{ textDecoration: 'none' }}
                    >
                      Přehled organizací
                      <br />
                    </Link>
                    <Link
                      to="/questions"
                      className="mx-3 link-dark text-nowrap d-block"
                      style={{ textDecoration: 'none' }}
                    >
                      Nastavení otázek
                      <br />
                    </Link>
                  </NavDropdown>
                )}
                {whoAmI && (whoAmI.role === 'chief_operator' || whoAmI.role === 'admin') && (
                  <Nav.Item className="mt-2">
                    <Link
                      className="ms-3 link-dark"
                      to="/operators"
                      style={{ textDecoration: 'none' }}
                    >
                      operátoři
                    </Link>
                  </Nav.Item>
                )}
                {whoAmI && (whoAmI.role === 'chief_operator' || whoAmI.role === 'admin') && (
                  <Nav.Item className="mt-2">
                    <Link
                      className="ms-3 link-dark"
                      to="/administration"
                      style={{ textDecoration: 'none' }}
                    >
                      admin
                    </Link>
                  </Nav.Item>
                )}
                {whoAmI && (whoAmI.role === 'chief_operator' || whoAmI.role === 'admin') && (
                  <Nav.Item className="mt-2">
                    <Link
                      className="ms-3 link-dark"
                      to="/unpaired"
                      style={{ textDecoration: 'none' }}
                    >
                      nespárované
                    </Link>
                  </Nav.Item>
                )}
                {whoAmI && (
                  <Nav.Item>
                    <ExportForm />
                  </Nav.Item>
                )}
                <NavDropdown title={<Person size={22} />} className="ms-3 link-dark">
                  <Link className="ms-3 link-dark" to="/profile" style={{ textDecoration: 'none' }}>
                    profil
                  </Link>
                  <NavDropdown.Divider />
                  <Link
                    className="ms-3 link-dark"
                    onClick={logOut}
                    to="/"
                    style={{ textDecoration: 'none' }}
                  >
                    logout
                  </Link>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Fragment>
        </Container>
      </Navbar>
    </header>
  );
};

export default memo(NavTop);

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyNearestSurveysDTO
 */
export interface SurveyNearestSurveysDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveyNearestSurveysDTO
     */
    surveyId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyNearestSurveysDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyNearestSurveysDTO
     */
    regNum: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyNearestSurveysDTO
     */
    lat: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyNearestSurveysDTO
     */
    lng: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyNearestSurveysDTO
     */
    distance: number;
}

export function SurveyNearestSurveysDTOFromJSON(json: any): SurveyNearestSurveysDTO {
    return SurveyNearestSurveysDTOFromJSONTyped(json, false);
}

export function SurveyNearestSurveysDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyNearestSurveysDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyId': json['survey_id'],
        'description': json['description'],
        'regNum': json['reg_num'],
        'lat': json['lat'],
        'lng': json['lng'],
        'distance': json['distance'],
    };
}

export function SurveyNearestSurveysDTOToJSON(value?: SurveyNearestSurveysDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'survey_id': value.surveyId,
        'description': value.description,
        'reg_num': value.regNum,
        'lat': value.lat,
        'lng': value.lng,
        'distance': value.distance,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyMailHistoryDTO
 */
export interface SurveyMailHistoryDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyMailHistoryDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    cc: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    bcc: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    mailBox?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyMailHistoryDTO
     */
    createdAt: string;
}

export function SurveyMailHistoryDTOFromJSON(json: any): SurveyMailHistoryDTO {
    return SurveyMailHistoryDTOFromJSONTyped(json, false);
}

export function SurveyMailHistoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyMailHistoryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'to': json['to'],
        'from': json['from'],
        'cc': json['cc'],
        'bcc': json['bcc'],
        'subject': json['subject'],
        'message': json['message'],
        'attachments': json['attachments'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'mailBox': !exists(json, 'mail_box') ? undefined : json['mail_box'],
        'createdAt': json['created_at'],
    };
}

export function SurveyMailHistoryDTOToJSON(value?: SurveyMailHistoryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'to': value.to,
        'from': value.from,
        'cc': value.cc,
        'bcc': value.bcc,
        'subject': value.subject,
        'message': value.message,
        'attachments': value.attachments,
        'date': value.date,
        'mail_box': value.mailBox,
        'created_at': value.createdAt,
    };
}


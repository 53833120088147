import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Display, Phone } from 'react-bootstrap-icons';

import { Props } from '../../../interfaces/interfaces';
import AppContext from '../../../store/appContext';
import Icon from '../../ui/Icon/Icon';
import { StyledBadge } from '../../ui/StyledBadge/StyledBadge';
import AxiosContext from '../../../store/axiosContext';
import { IRatingsPaginated } from '../../../types';
import Paginator from '../../ui/Paginator/Paginator';
import toast from 'react-hot-toast';

const ITEMS_PER_PAGE = 50;

const Ratings: FC<Props> = (): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [ratings, setRatings] = useState<IRatingsPaginated>({ data: [], total: 0 });
  const [detailVisibility, setDetailVisibility] = useState<Record<number, boolean>>({});
  const [currentPage, setCurrentPage] = useState<number>(1);

  const toggleVisibility = (ratingId: number) => {
    setDetailVisibility({ ...detailVisibility, [ratingId]: !detailVisibility[ratingId] ?? true });
  };

  const getRatings = useCallback(async (currentPage: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(
        `/office-ratings/ratings?limit=${ITEMS_PER_PAGE}&offset=${
          (currentPage - 1) * ITEMS_PER_PAGE
        }`
      );
      setRatings(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRatings(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <Row>
          <Col>
            <h2 className="mb-4">Zobrazení výsledků hodnocení</h2>
            <Paginator
              totalCount={ratings.total}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <Table striped>
              <thead>
                <tr>
                  <th>id</th>
                  <th>hodnocený útvar</th>
                  <th colSpan={2}>slovní hodnocení</th>
                  <th>zdroj</th>
                  <th>podrobnosti</th>
                  <th>celková známka</th>
                </tr>
              </thead>
              <tbody>
                {ratings.data.map((rating) => (
                  <Fragment key={rating.id + '_wrapper'}>
                    <tr key={rating.id}>
                      <td>{rating.id}</td>
                      <td>
                        <span>
                          <b>{rating.department.department_name}</b>
                        </span>
                        <br />
                        <span>({rating.department.division_name})</span>
                      </td>
                      <td colSpan={2}>{rating.description}</td>
                      <td style={{ textAlign: 'center' }}>
                        {rating.rater === 'APP' ? (
                          <Icon>
                            <Phone size={24} />
                          </Icon>
                        ) : (
                          <Icon>
                            <Display size={24} />
                          </Icon>
                        )}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <button
                          onClick={() => toggleVisibility(rating.id)}
                          className="btn btn-primary btn-sm"
                        >
                          info
                        </button>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <StyledBadge scale={1.2} rating={rating.rating}>
                          {rating.rating}
                        </StyledBadge>
                      </td>
                    </tr>
                    <tr
                      key={rating.id + '_info'}
                      style={{ visibility: detailVisibility[rating.id] ? 'visible' : 'collapse' }}
                    >
                      <td></td>
                      <td>
                        <i>
                          {rating.counter ? (
                            <>
                              přepážka: <b>{rating.counter}</b>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          zadané datum: <b>{rating.date}</b>
                          <br />
                          datum vložení: <b>{rating.created_at}</b>
                          <br />
                          zdroj: <b>{rating.rater}</b>
                        </i>
                      </td>
                      <td colSpan={5}>
                        <Table striped size="sm">
                          <tbody>
                            {rating.question_answers.map((question, i) => (
                              <tr key={rating.id + '_' + i}>
                                <td>{question.question}</td>
                                <td>
                                  <StyledBadge rating={question.rating}>
                                    {question.rating}
                                  </StyledBadge>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </Table>
            <Paginator
              totalCount={ratings.total}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Ratings;

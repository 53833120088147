import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const SurveyMessageHistory = styled(Card)`
  padding: 0.2rem;
  background-color: #e6e6fa33;
  margin-bottom: 1rem;
`;

export default SurveyMessageHistory;

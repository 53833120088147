import { createContext, useContext, useMemo } from 'react';
import { AuthContext } from './authContext';
import { configuration } from '../configuration';
import { CodeListsApi, Configuration, SurveysApi } from '../api';

type Context = {
  CodeListsApi: CodeListsApi;
  SurveysApi: SurveysApi;
};

export const ApiContext = createContext<Context>({
  CodeListsApi: new CodeListsApi(),
  SurveysApi: new SurveysApi(),
});

export const ApiContextProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { token, setToken } = useContext(AuthContext);

  const apiConf = useMemo(
    () =>
      new Configuration({
        basePath: configuration.API_BASE_URL,
        middleware: [
          {
            // Guard for unauthorized responses to unset the token
            post: async (context) => {
              if (context.response.status === 401) {
                setToken(null);
              }
            },
            // Provide authorization through the obtained token
            pre: async (context) => {
              if (!token) {
                return;
              }

              let { headers } = context.init;
              if (!headers) {
                headers = {};
              }

              if (headers instanceof Array) {
                headers.push(['Authorization', `Bearer ${token}`]);
              } else if (headers instanceof Headers) {
                headers.set('Authorization', `Bearer ${token}`);
              } else {
                headers['Authorization'] = `Bearer ${token}`;
              }

              context.init.headers = headers;
            },
          },
        ],
      }),
    [setToken, token]
  );

  const value = useMemo(
    () => ({
      CodeListsApi: new CodeListsApi(apiConf),
      SurveysApi: new SurveysApi(apiConf),
    }),
    [apiConf]
  );

  return <ApiContext.Provider value={value}>{props.children}</ApiContext.Provider>;
};

export default ApiContext;

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserRequestDTO
 */
export interface CreateUserRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDTO
     */
    role?: CreateUserRequestDTORoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateUserRequestDTORoleEnum {
    Guest = 'guest',
    Operator = 'operator',
    ChiefOperator = 'chief_operator',
    Admin = 'admin',
    Deactivated = 'deactivated'
}

export function CreateUserRequestDTOFromJSON(json: any): CreateUserRequestDTO {
    return CreateUserRequestDTOFromJSONTyped(json, false);
}

export function CreateUserRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'username': json['username'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function CreateUserRequestDTOToJSON(value?: CreateUserRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
        'username': value.username,
        'role': value.role,
    };
}


import { Dispatch, FC, memo, SetStateAction, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import styled from 'styled-components';

type PaginatorProps = {
  totalCount: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  nItemsWithin?: number;
};

const StyledPagination = styled(Pagination)`
  justify-content: center;
  margin-bottom: 2rem;
`;

const Paginator: FC<PaginatorProps> = ({
  totalCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  nItemsWithin = 2,
}) => {
  const pageCount = Math.ceil(totalCount / itemsPerPage) || 1;
  const isPaginationShown = pageCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pageCount;

  const changePage = (pageNumber: number) => {
    if (currentPage === pageNumber) return;
    setCurrentPage(pageNumber);
  };

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > pageCount) {
      setCurrentPage(pageCount);
    }
  };

  let isPageNumberOutOfRange: boolean;

  const pageNumbers = [...new Array(pageCount)].map((_, index) => {
    const pageNumber = index + 1;

    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pageCount;
    const isCurrentPageWithinNPageNumbers = Math.abs(pageNumber - currentPage) <= nItemsWithin;

    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinNPageNumbers) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} disabled={true} />;
    }

    return null;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setLastPageAsCurrent, [pageCount]);

  return (
    <>
      {isPaginationShown && (
        <StyledPagination>
          <Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst} />
          {pageNumbers}
          <Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast} />
        </StyledPagination>
      )}
    </>
  );
};

export default memo(Paginator);

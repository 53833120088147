import { FC, useContext } from 'react';
import SolutionContext from '../../../store/solutionContext';
import { object } from 'yup';
import { Formik, Form } from 'formik';
import InputFile from '../InputFile/InputFile';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { filesInputScheme } from '../_Utils/yup_validations';
interface Values {
  files: File[] | undefined;
}
const ValidationSchema = object().shape({
  files: filesInputScheme,
});

const FileForm: FC<{ survey_id: string }> = ({ survey_id }) => {
  const { addPhoto } = useContext(SolutionContext);

  const initialValues: Values = {
    files: undefined,
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        addPhoto(survey_id, values.files!);
        setTimeout(() => {
          window.location.reload();
        }, 200);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form className="mb-3">
          <div className="mb-3">
            <InputFile
              style={{ color: '#777' }}
              name="files"
              id="files"
              type="file"
              accept="image/jpg, image/jpeg, image/gif, image/png"
              multiple
              onChange={(event) => {
                setFieldValue('files', event.currentTarget.files);
              }}
            />
            {errors.files && touched.files ? (
              <StyledErrorMessage>{errors.files}</StyledErrorMessage>
            ) : null}
          </div>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-dark"
              type="submit"
              style={{ maxWidth: '8rem' }}
              disabled={isSubmitting}
            >
              Odeslat
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FileForm;

import { FC, useContext } from 'react';
import AppContext from '../../../store/appContext';
import { Card } from 'react-bootstrap';
import StyledCard from '../../ui/StyledCard/StyledCard';
import StyledLine from '../../ui/StyledLine/StyledLine';
import StyledLink from '../../ui/StyledLink/StyledLink';
import StyledText from '../../ui/StyledText/StyledText';
import StyledTextCatName from '../../ui/StyledTextCatName/StyledTextCatName';
import { ISurveys } from '../../../interfaces/interfaces';
import '../../../styles/styles.scss';
import FileField from '../../ui/FileField/FileField';
import { toCzDateTime } from '../../../utils/toCzDateTime';
import StyledButtonMap from '../../ui/StyledButtonMap';

interface ISurvey {
  fields: ISurveys;
}

const SurveyBasic: FC<ISurvey> = ({ fields }): JSX.Element => {
  const { setShowModalHQ, setFilesToShow } = useContext(AppContext);

  const {
    id,
    reg_num,
    category_name,
    description,
    email,
    gps,
    datetime,
    status_id,
    status_name,
    priority,
    dpp_sent,
    survey_lock_user_username,
    ext_apology,
    ext_urgency,
    ext_praise,
    ext_anonym,
    ext_solved,
    files,
    child_surveys,
  } = fields;

  let shortOp: string;
  if (survey_lock_user_username!) {
    shortOp = survey_lock_user_username!.split('@')[0] + '@';
  }
  return (
    <StyledCard className="mb-3" priority={priority}>
      <Card.Body>
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <StyledLink to={`/detail/${id}`} state={{ id: id }} className="h5">
            {reg_num}
          </StyledLink>
          <Card.Text className="h5 text-dark" style={{ color: 'red' }}>
            {toCzDateTime(datetime)}
          </Card.Text>
          <Card.Text className={`mb-2 filt filt-${status_id}`}>{status_name}</Card.Text>
        </div>
        <div className='d-flex flex-column flex-sm-row justify-content-end'>
          {shortOp! &&
            <Card.Text className={`mb-2 text-dark bg-info filt`}>{shortOp!}</Card.Text>
          }
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-between">
          <StyledLine header={`Kategorie:`} data={category_name} />
          <StyledLine header={`Operátor:`} data={shortOp!} />
          <div className="align-item-end">
            {dpp_sent !== null && <span className={`me-1 tag tag-1`}>DPP</span>}
            {ext_solved && <span className={`me-1 tag tag-5`}>Změněno</span>}
            {ext_apology && <span className={`me-1 tag tag-2`}>Omluvenka</span>}
            {ext_urgency && <span className={`me-1 tag tag-3`}>Urgence</span>}
            {ext_anonym && <span className={`me-1 tag tag-4`}>Anonym</span>}
            {ext_praise && <span className={`me-1 tag tag-7`}>Pochvala</span>}
            {child_surveys && <span className={`me-1 tag tag-2`}>Hlavní podnět</span>}
            {reg_num && reg_num.indexOf('/') >= 0 && (
              <span className={`me-1 tag tag-2`}>Podpodnět</span>
            )}
          </div>
        </div>
        <StyledText>
          <StyledTextCatName>Zobrazit: </StyledTextCatName>
          <StyledButtonMap
            onClick={() => window.open(`https://www.google.com/maps/place/${gps}`, '_blank')}
          >
            {gps}
          </StyledButtonMap>
        </StyledText>
        <FileField
          label={`Fotografie:`}
          files={files}
          survey_id={id}
          onClick={() => {
            setShowModalHQ({ show: true, type: 'photos', regnum: reg_num, survey_id: id });
            setFilesToShow(files);
          }}
        />
        <div className='d-flex justify-content-between mt-3'>
          <StyledLine header={`Popis:`} data={description} />
          <StyledText>
            <StyledTextCatName>Odesílatel:</StyledTextCatName>
            <StyledLink to={`/email`} state={{ email: email }}>
              {email}
            </StyledLink>
          </StyledText>
        </div>
      </Card.Body>
    </StyledCard>
  );
};

export default SurveyBasic;

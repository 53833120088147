/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequestResponseDTO,
    BadRequestResponseDTOFromJSON,
    BadRequestResponseDTOToJSON,
    ConflictResponseDTO,
    ConflictResponseDTOFromJSON,
    ConflictResponseDTOToJSON,
    CreateUserRequestDTO,
    CreateUserRequestDTOFromJSON,
    CreateUserRequestDTOToJSON,
    CreateUserResponseDTO,
    CreateUserResponseDTOFromJSON,
    CreateUserResponseDTOToJSON,
    ForbiddenResponseDTO,
    ForbiddenResponseDTOFromJSON,
    ForbiddenResponseDTOToJSON,
    NotFountResponseDTO,
    NotFountResponseDTOFromJSON,
    NotFountResponseDTOToJSON,
    UnauthorizedResponseDTO,
    UnauthorizedResponseDTOFromJSON,
    UnauthorizedResponseDTOToJSON,
    UpdateUserRequestDTO,
    UpdateUserRequestDTOFromJSON,
    UpdateUserRequestDTOToJSON,
    UserResponseDTO,
    UserResponseDTOFromJSON,
    UserResponseDTOToJSON,
} from '../models';

export interface UsersControllerCreateUserRequest {
    createUserRequestDTO: CreateUserRequestDTO;
}

export interface UsersControllerDeleteUserRequest {
    userId: number;
    hardDelete?: boolean;
}

export interface UsersControllerGetUserRequest {
    userId: number;
    includeStats?: boolean;
}

export interface UsersControllerGetUsersRequest {
    role?: Array<UsersControllerGetUsersRoleEnum>;
    active?: boolean;
}

export interface UsersControllerUpdateUserRequest {
    userId: number;
    updateUserRequestDTO: UpdateUserRequestDTO;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async usersControllerCreateUserRaw(requestParameters: UsersControllerCreateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateUserResponseDTO>> {
        if (requestParameters.createUserRequestDTO === null || requestParameters.createUserRequestDTO === undefined) {
            throw new runtime.RequiredError('createUserRequestDTO','Required parameter requestParameters.createUserRequestDTO was null or undefined when calling usersControllerCreateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestDTOToJSON(requestParameters.createUserRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerCreateUser(requestParameters: UsersControllerCreateUserRequest, initOverrides?: RequestInit): Promise<CreateUserResponseDTO> {
        const response = await this.usersControllerCreateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerDeleteUserRaw(requestParameters: UsersControllerDeleteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerDeleteUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.hardDelete !== undefined) {
            queryParameters['hardDelete'] = requestParameters.hardDelete;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerDeleteUser(requestParameters: UsersControllerDeleteUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerDeleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async usersControllerGetCurrentUserRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserResponseDTOFromJSON));
    }

    /**
     */
    async usersControllerGetCurrentUser(initOverrides?: RequestInit): Promise<Array<UserResponseDTO>> {
        const response = await this.usersControllerGetCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetUserRaw(requestParameters: UsersControllerGetUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponseDTO>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerGetUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeStats !== undefined) {
            queryParameters['includeStats'] = requestParameters.includeStats;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerGetUser(requestParameters: UsersControllerGetUserRequest, initOverrides?: RequestInit): Promise<UserResponseDTO> {
        const response = await this.usersControllerGetUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetUsersRaw(requestParameters: UsersControllerGetUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserResponseDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.role) {
            queryParameters['role'] = requestParameters.role;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserResponseDTOFromJSON));
    }

    /**
     */
    async usersControllerGetUsers(requestParameters: UsersControllerGetUsersRequest = {}, initOverrides?: RequestInit): Promise<Array<UserResponseDTO>> {
        const response = await this.usersControllerGetUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerUpdateUserRaw(requestParameters: UsersControllerUpdateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerUpdateUser.');
        }

        if (requestParameters.updateUserRequestDTO === null || requestParameters.updateUserRequestDTO === undefined) {
            throw new runtime.RequiredError('updateUserRequestDTO','Required parameter requestParameters.updateUserRequestDTO was null or undefined when calling usersControllerUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestDTOToJSON(requestParameters.updateUserRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerUpdateUser(requestParameters: UsersControllerUpdateUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersControllerUpdateUserRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum UsersControllerGetUsersRoleEnum {
    Guest = 'guest',
    Operator = 'operator',
    ChiefOperator = 'chief_operator',
    Admin = 'admin',
    Deactivated = 'deactivated'
}

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyChildResponseDTO
 */
export interface SurveyChildResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveyChildResponseDTO
     */
    surveyId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyChildResponseDTO
     */
    regNum: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyChildResponseDTO
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyChildResponseDTO
     */
    statusId: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyChildResponseDTO
     */
    statusName: string;
}

export function SurveyChildResponseDTOFromJSON(json: any): SurveyChildResponseDTO {
    return SurveyChildResponseDTOFromJSONTyped(json, false);
}

export function SurveyChildResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyChildResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyId': json['survey_id'],
        'regNum': json['reg_num'],
        'createdAt': json['created_at'],
        'statusId': json['status_id'],
        'statusName': json['status_name'],
    };
}

export function SurveyChildResponseDTOToJSON(value?: SurveyChildResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'survey_id': value.surveyId,
        'reg_num': value.regNum,
        'created_at': value.createdAt,
        'status_id': value.statusId,
        'status_name': value.statusName,
    };
}


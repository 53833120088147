/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyEmailAttachmentDTO,
    SurveyEmailAttachmentDTOFromJSON,
    SurveyEmailAttachmentDTOFromJSONTyped,
    SurveyEmailAttachmentDTOToJSON,
} from './SurveyEmailAttachmentDTO';

/**
 * 
 * @export
 * @interface SurveyPrefilledEmailRequestDTO
 */
export interface SurveyPrefilledEmailRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    regNum: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    statusId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    to: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    cc: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    bcc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    message: string;
    /**
     * 
     * @type {Array<SurveyEmailAttachmentDTO>}
     * @memberof SurveyPrefilledEmailRequestDTO
     */
    attachments: Array<SurveyEmailAttachmentDTO>;
}

export function SurveyPrefilledEmailRequestDTOFromJSON(json: any): SurveyPrefilledEmailRequestDTO {
    return SurveyPrefilledEmailRequestDTOFromJSONTyped(json, false);
}

export function SurveyPrefilledEmailRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyPrefilledEmailRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regNum': json['reg_num'],
        'statusId': json['status_id'],
        'to': json['to'],
        'cc': json['cc'],
        'bcc': json['bcc'],
        'subject': json['subject'],
        'message': json['message'],
        'attachments': ((json['attachments'] as Array<any>).map(SurveyEmailAttachmentDTOFromJSON)),
    };
}

export function SurveyPrefilledEmailRequestDTOToJSON(value?: SurveyPrefilledEmailRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reg_num': value.regNum,
        'status_id': value.statusId,
        'to': value.to,
        'cc': value.cc,
        'bcc': value.bcc,
        'subject': value.subject,
        'message': value.message,
        'attachments': ((value.attachments as Array<any>).map(SurveyEmailAttachmentDTOToJSON)),
    };
}


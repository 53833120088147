import { FC, useContext, useEffect } from 'react';
import AppContext from '../../../store/appContext';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import SurveyDetail from '../../layout/SurveyDetail/SurveyDetail';
import Solution from '../../layout/Solution/Solution';
import { Props } from '../../../interfaces/interfaces';

const Detail: FC<Props> = (props): JSX.Element => {
  const { detail, getFilters, getSurveyById, getWhoAmI } = useContext(AppContext);

  const params = useParams();
  useEffect(() => {
    getWhoAmI();
    getFilters();
    getSurveyById(params.id!);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let detail_item = (
    <Col className="d-flex justify-content-center">
      <Spinner animation="border" />
    </Col>
  );
  if (detail) {
    detail_item = <SurveyDetail details={detail} />;
  }

  return (
    <section className="top">
      <h1 className="px-3 mb-4 display-5 text-center">{props.header}</h1>
      <Container fluid>
        <Row className="mb-5">
          <Col lg={6}>{detail_item}</Col>
          <Col lg={6}>
            <Solution />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Detail;

import { FC, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PlusLg } from 'react-bootstrap-icons';
import { StyledScrollableDiv } from '../../ui/StyledScrollableDiv/StyledScrollableDiv';
import Icon from '../../ui/Icon/Icon';
import toast from 'react-hot-toast';
import { Props } from '../../../interfaces/interfaces';
import { IInstitution } from '../../../types';

export type InstitutionContextType = {
  getInstitutions: () => Promise<void>;
};

const Institutions: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError, getWhoAmI, whoAmI } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [institutions, setInstitutions] = useState<IInstitution[]>([]);

  const getInstitutions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get('/institutions');
      setInstitutions(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWhoAmI();
    getInstitutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid style={{ minHeight: '70vh' }}>
      <h1 className="px-3 mb-5 text-center">{props.header}</h1>
      <Row>
        <Col md={4}>
          <div className="clearfix mb-2">
            <h4 className="d-inline-block">Přehled institucí</h4>
            {whoAmI && whoAmI.role !== 'operator' && (
              <Link to="add" className="btn btn-sm btn-success float-end">
                <Icon>
                  <PlusLg size={24} color="white" />
                </Icon>
              </Link>
            )}
          </div>
          <StyledScrollableDiv>
            <ul className="list-unstyled">
              {institutions.map((institution) => (
                <li
                  key={institution.id}
                  className="list-group list-group-flush"
                  style={{
                    border: '1px solid rgba(0,0,0,.125)',
                    borderRadius: '0.4rem',
                    marginBottom: '0.2rem',
                  }}
                >
                  <NavLink
                    to={institution.id.toString()}
                    className={`list-group-item list-group-item-action py-3`}
                  >
                    {institution.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </StyledScrollableDiv>
        </Col>
        <Col md={8}>
          <Outlet context={{ getInstitutions }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Institutions;

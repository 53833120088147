import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const StyledCard = styled(Card)`
  border: ${(props) =>
    props.priority === 1
      ? '2px solid red'
      : props.priority === 2
      ? '2px solid orange'
      : '2px solid blue'};
`;

export default StyledCard;

import { Libraries } from "@react-google-maps/api";

const GOOGLE_LIBS: Libraries = ['places'];

export const configuration = {
  API_BASE_URL: window.ENV.API_BASE_URL,
  API_URL: window.ENV.API_URL,
  API_KEY: window.ENV.API_KEY,
  GOOGLE_API_KEY: window.ENV.GOOGLE_API_KEY,
  GOOGLE_ANALYTICS_ID: window.ENV.GOOGLE_ANALYTICS_ID,
  CURRENT_YEAR: window.ENV.CURRENT_YEAR || 2022,
  SHOW_REGISTRATION: window.ENV.SHOW_REGISTRATION || false,
  // PRESENTATIONS_URL: '',
  GOOGLE_LIBS,
};

import { CSSProperties, FC, ChangeEventHandler, MutableRefObject, useRef } from 'react';

interface IInputFile {
  style: CSSProperties;
  name: string;
  id: string;
  type: string;
  accept: string;
  multiple: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const InputFile: FC<IInputFile> = (props): JSX.Element => {
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  return (
    <div className="d-flex mb-3">
      <input ref={fileRef} className="form-control" {...props} />
      <button
        className="btn btn-outline-secondary ms-2"
        type="button"
        onClick={() => (fileRef.current.value = '')}
      >
        Odebrat
      </button>
    </div>
  );
};

export default InputFile;

import { FC, useContext } from 'react';
import { AuthContext } from '../../../store/authContext';
import { object } from 'yup';
import { Form, Formik } from 'formik';
import { FormProps, LoginValues } from '../../../interfaces/interfaces';
import { Card, Col, Row } from 'react-bootstrap';
import InputFieldLabelTop from '../InputFieldLabelTop/InputFieldLabelTop';
import { usernameYup, passwordYup } from '../_Utils/yup_validations';
import '../../../styles/styles.scss';

// Yup validation schema
const LoginSchema = object().shape({
  username: usernameYup,
  password: passwordYup,
});
const EmailSchema = object().shape({
  username: usernameYup,
});
const PassSchema = object().shape({
  password: passwordYup,
});

const LoginForm: FC<FormProps> = (props): JSX.Element => {
  const auth = useContext(AuthContext);
  const initialValues: LoginValues = {
    username: '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        props.username && props.password ? LoginSchema : props.username ? EmailSchema : PassSchema
      }
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        props.fn === 'login' && auth.logIn(values.username, values.password);
        props.fn === 'forgot' && auth.forgotPass(values.username);
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form className="text-dark text-center">
          <Row>
            <Col>
              <Card className="shadow-sm">
                <Card.Body>
                  <Card.Title className="mb-3">{props.formTitle}</Card.Title>
                  {props.username && (
                    <div className="mb-3">
                      <InputFieldLabelTop
                        id="username"
                        name="username"
                        label="Vaše uživatelské jméno (e-mail)"
                        placeholder="Váš e-mail"
                        type="email"
                      />
                    </div>
                  )}
                  {props.password && (
                    <div className="mb-3">
                      <InputFieldLabelTop
                        id="password"
                        name="password"
                        label="Vaše heslo (min. 6 znaků)"
                        placeholder="Vaše heslo"
                        type="password"
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-outline-dark mb-3"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Odeslat
                  </button>
                  <Row>
                    <Col className="text-start">
                      {props.fn === 'login' && (
                        <Card.Link
                          href="/password-reset"
                          className="text-dark text-decoration-none"
                        >
                          Zapomenuté heslo?
                        </Card.Link>
                      )}
                      {props.fn === 'forgot' && (
                        <Card.Link href="/" className="text-dark text-decoration-none">
                          Zpět na přihlášení
                        </Card.Link>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;

import { FC, useCallback, useContext, useState, useEffect } from 'react';
import AppContext from '../../../store/appContext';
import { Card, Form, Spinner } from 'react-bootstrap';
import StyledLine from '../../ui/StyledLine/StyledLine';
import StyledLink from '../../ui/StyledLink/StyledLink';
import StyledText from '../../ui/StyledText/StyledText';
import StyledTextCatName from '../../ui/StyledTextCatName/StyledTextCatName';
import FileField from '../../ui/FileField/FileField';
import AddNoteForm from '../../ui/AddNoteForm/AddNoteForm';
import AddNoteIcon from '../../ui/AddNoteIcon/AddNoteIcon';
import { MarkerIcon } from '../../ui/Map/MarkerIcon/MarkerIcon';
import { IFiles } from '../../../interfaces/interfaces';
import styled from 'styled-components';
import StyledMessage from '../../ui/StyledMessage/StyledMessage';
import StyledButtonMap from '../../ui/StyledButtonMap';

import AggregationMap from '../../ui/AggregationMap/AggregationMap';
import { Pencil, X } from 'react-bootstrap-icons';

interface ISurveyDetailBody {
  admin_notes: string;
  category_name: string;
  datetime: string;
  created_at: string;
  description: string;
  email: string;
  files: IFiles[];
  gps: string;
  gps_added?: number;
  id: string;
  priority: number;
  status_name: string;
  survey_lock_user_username: string;
  reg_num: string;
}

const SurveyBody = styled(Card)`
  padding: 0.2rem;
  background-color: ${({ priority }) =>
    priority === 1 ? '#FFF0F533' : priority === 2 ? '#FFFFE033' : '#ccdbff33'};
  margin-bottom: 1rem;
`;

const StyledEditIcon = styled(Pencil)`
  background-color: #24a1;
  font-size: 1.7rem;
  padding: 0.2rem;
  transition: all 0.1s ease-in;
  &:hover {
    color: red;
  }
  margin-left: 0.2rem;
  cursor: pointer;
`;

const StyledCancelIcon = styled(X)`
  background-color: #24a1;
  font-size: 1.7rem;
  padding: 0.2rem;
  transition: all 0.1s ease-in;
  &:hover {
    color: red;
  }
  margin-left: 0.2rem;
  cursor: pointer;
`;

const SurveyDetailBody: FC<ISurveyDetailBody> = ({
  admin_notes,
  category_name,
  datetime,
  created_at,
  description,
  email,
  files,
  gps,
  id,
  priority,
  status_name,
  survey_lock_user_username,
  reg_num,
}): JSX.Element => {
  const { patchSurvey, setShowModalHQ, surChildren, whoAmI, setFilesToShow } =
    useContext(AppContext);
  const [showEditor, setShowEditor] = useState(false);
  const [isIconHovered, setIconIsHovered] = useState(false);
  const [editedDescription, setEditedDescription] = useState<string | null>(null);
  const [updatingDescription, setUpdatingDescription] = useState(false);
  const updateDescriptionHandler = useCallback<React.FormEventHandler>(
    async (e) => {
      e.preventDefault();
      if (editedDescription === null) {
        return;
      }

      setUpdatingDescription(true);

      try {
        await patchSurvey(id, {
          description: editedDescription,
        });
        setEditedDescription(null);
      } catch (e) {
        console.error(e);
      } finally {
        setUpdatingDescription(false);
      }
    },
    [editedDescription, id, patchSurvey]
  );

  let role: string = 'guest';
  if (whoAmI) {
    role = whoAmI.role;
  }

  let shortOp: string;
  if (survey_lock_user_username) {
    shortOp = survey_lock_user_username!.split('@')[0] + '@';
  }
  const linkId = reg_num.slice(5, reg_num.lastIndexOf('/'));
  const [showMap, setShowMap] = useState(false);
  const toggleMap = () => {
    setShowMap(!showMap);
  };

  useEffect(() => {
    setShowMap(true);
  }, []);

  const openFullscreen = (imageSrc: string) => {
    const fullscreenWindow = window.open('', '_blank');
    
    if (fullscreenWindow) {
      fullscreenWindow.document.write(`
        <html>
          <head>
            <style>
              body { margin: 0; overflow: hidden; }
              img { width: 100%; height: 100%; object-fit: contain; }
            </style>
          </head>
          <body>
            <img src="${imageSrc}" alt="Fullscreen Image">
          </body>
        </html>
      `);
    }
  };
  
  return (
    <SurveyBody priority={priority}>
      {surChildren && surChildren.length > 0 ? (
        surChildren.map((child) => {
          return (
            <StyledText key={child.survey_id}>
              <StyledTextCatName>Podpodnět: </StyledTextCatName>
              <StyledLink to={`${child.survey_id}`} target="_blank">
                {child.reg_num}
              </StyledLink>
            </StyledText>
          );
        })
      ) : reg_num && reg_num.indexOf('/') >= 0 ? (
        <StyledText key={linkId}>
          <StyledTextCatName>Hlavní podnět: </StyledTextCatName>
          <StyledLink to={`${linkId}`} target="_blank">
            {reg_num.slice(0, reg_num.lastIndexOf('/'))}
          </StyledLink>
        </StyledText>
      ) : null}

      <div className="d-flex justify-content-between">
        <StyledLine header={`Kategorie:`} data={category_name} />
        {
        //<StyledLine header={`Status:`} data={status_name} />
        }
      </div>
      <div className="d-flex justify-content-between">
        <StyledLine header={`Založeno:`} data={created_at} />
        <StyledLine header={`Nahlášeno uživatelem:`} data={datetime} />
      </div>
      <div className="d-flex justify-content-between">
        <StyledText>
          <StyledTextCatName>Zobrazit </StyledTextCatName>
          <StyledButtonMap
            onClick={() => window.open(`https://www.google.com/maps/place/${gps}`, '_blank')}
          >
            {gps}
          </StyledButtonMap>
        </StyledText>
      </div>
      <div style={{backgroundColor:'#24a1', padding:'0.1rem 0.5rem'}}>
        <div className='d-flex'>
          <StyledTextCatName>Fotografie:</StyledTextCatName>
          <svg style={{ cursor: isIconHovered ? 'pointer' : 'default' }} className='mx-2 my-1' onClick={() => {
            setShowModalHQ({ show: true, type: 'photos', regnum: reg_num, survey_id: id });
            setFilesToShow(files);
          }} xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512" onMouseOver={() => setIconIsHovered(true)} onMouseOut={() => setIconIsHovered(false)}><path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z"/></svg>
        </div>
        <div className="d-flex justify-space-between">
          {files.length > 0 && 
            files.map((file, index) => {
              return (
                <a style={{width:'20%', border:'3px solid white', marginRight:'7px'}} key={index} href="#" onClick={() => openFullscreen(file.path)}>
                  <img className='w-100' src={file.path} alt=""/>
                </a>
              )
            })
          }
        </div>
      </div>
      {role !== 'guest' && showEditor && (
        <AddNoteForm id={id} onSave={() => setShowEditor(false)} prevNotes={admin_notes} />
      )}
      <div className="d-flex justify-content-between align-items-center">
        <StyledLine
          header={`Váš podnět:`}
          data={editedDescription === null ? description : ''}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        />
        {role !== 'guest' && (
          <>
            {editedDescription === null && (
              <StyledEditIcon onClick={() => setEditedDescription(description)} />
            )}
            {editedDescription !== null && !updatingDescription && (
              <StyledCancelIcon onClick={() => setEditedDescription(null)} />
            )}
            {editedDescription !== null && updatingDescription && (
              <Spinner animation="border" className="me-1" size="sm" />
            )}
          </>
        )}
      </div>
      {editedDescription !== null && (
        <Form noValidate onSubmit={updateDescriptionHandler}>
          <div className="mb-1">
            <textarea
              rows={Math.min(20, Math.max(description.split('\n').length, 2))}
              onChange={(e) => setEditedDescription(e.target.value)}
              style={{ width: '100%' }}
            >
              {editedDescription}
            </textarea>
          </div>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-dark"
              type="submit"
              style={{ width: '100%' }}
              disabled={updatingDescription}
            >
              Uložit popis
            </button>
          </div>
        </Form>
      )}
      <StyledText>
        <StyledTextCatName>Kontakt:</StyledTextCatName>
        <StyledLink to={`/email`} state={{ email: email }}>
          {email}
        </StyledLink>
      </StyledText>
      <div>
        <StyledLine header={`Okolní podněty: `}>
          <MarkerIcon onClick={toggleMap}/>
          {showMap &&
            <AggregationMap />
          }
        </StyledLine>
      </div>
      <div className="d-flex justify-content-between">
        <StyledLine header={`Poznámky:`} />
        {role !== 'guest' && (
          <AddNoteIcon onClick={() => setShowEditor(!showEditor)} showEditor={showEditor} />
        )}
      </div>
      {!showEditor && (
        <StyledMessage>
          <div dangerouslySetInnerHTML={{ __html: admin_notes! }} />
        </StyledMessage>
      )}
      {role !== 'guest' && showEditor && (
        <AddNoteForm id={id} onSave={() => setShowEditor(false)} prevNotes={admin_notes} />
      )}

    </SurveyBody>
  );
};

export default SurveyDetailBody;

import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { IApology } from '../../../types';

export type TAction = keyof typeof Action;

export enum Action {
  STOP = 'STOP',
  START = 'START',
  SAVE = 'SAVE',
}

export default function AdminApology() {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [apology, setApology] = useState<IApology | null>(null);
  const [days, setDays] = useState<number | null>(null);

  const getApology = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/apology`);
      setApology(response.data || null);
      setDays(response.data?.days || null);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getApology();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAction = async (event: FormEvent, action: TAction) => {
    event.preventDefault();
    try {
      await httpClient.post(`/apology`, { days: days!, action });
      await getApology();
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDays(+value);
  };

  return (
    <div className="mb-5">
      <h3 className="pb-3">Nastavení odesílání automatických omluvenek</h3>
      <Form onSubmit={(event) => onAction(event, Action.SAVE)}>
        <Form.Group as={Row} className="mb-3" controlId="days">
          <Form.Label column sm="2">
            <b>Počet dní</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="number"
              min="1"
              placeholder="Počet dní"
              value={days || ''}
              name="days"
              onChange={handleChange}
            />
          </Col>
          <div className="clearfix">
            <Button
              type="submit"
              size="sm"
              variant="success"
              className="mt-3 float-end"
              disabled={!days}
            >
              Uložit
            </Button>
          </div>
        </Form.Group>
        <div className="clearfix">
          <hr className="mt-4" />
          {apology?.start_date === null && (
            <Button
              size="sm"
              variant="danger"
              style={{ minWidth: '80%', display: 'block', margin: '3rem auto' }}
              onClick={(event) => onAction(event, Action.START)}
            >
              Start
            </Button>
          )}
          {apology?.end_date === null && (
            <Button
              size="sm"
              variant="danger"
              style={{ minWidth: '80%', display: 'block', margin: '3rem auto' }}
              onClick={(event) => onAction(event, Action.STOP)}
            >
              Stop
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

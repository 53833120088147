import { FC, useEffect } from 'react';

export const Title: FC<{ title: string | null }> = ({ title, children }) => {
  useEffect(() => {
    if (!title) {
      document.title = 'Změňte.to';
    } else {
      document.title = `${title} | ${'Změňte.to'}`;
    }
  }, [title]);

  return <>{children}</>;
};

import MailForm from '../../ui/MailForm/MailForm';
import TSKApiForm, { TSKApiFormRequest } from '../../ui/TSKApiForm';
import { ISolutionAction } from '../../../interfaces/interfaces';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';

type SoltionActionProps = {
  action: ISolutionAction;
};

const SolutionAction: React.FC<SoltionActionProps> = (props) => {
  const { action } = props;
  const { detail } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const { emailPrep, setSolutionAction, setSolutionStepName, treatSurvey } =
    useContext(SolutionContext);

  const closeAction = useCallback(() => {
    setSolutionAction(null);
    setSolutionStepName('');
  }, [setSolutionAction, setSolutionStepName]);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  useEffect(() => {
    // Future proof react 18 strict mode in dev environment
    const ac = new AbortController();
    setAbortController(ac);

    // Reset any outstanding requests on component unmount
    return () => {
        closeAction();
        ac.abort()
    };
  }, []);

  switch (action) {
    case 'viewMail':
      return (
        <MailForm
          bcc={emailPrep?.bcc!}
          cc={emailPrep?.cc!}
          files={emailPrep?.files!}
          header={emailPrep?.header!}
          message={emailPrep?.message!}
          regNum={detail?.reg_num!}
          status_id={emailPrep?.status_id!}
          step_id={emailPrep?.step_id!}
          subject={emailPrep?.subject!}
          surveyId={detail?.id!}
          to={emailPrep?.to!}
        />
      );
    case 'sendToTSK':
      return (
        <TSKApiForm
          initialDescription={detail?.description || ''}
          onCancel={closeAction}
          onRequest={async (req: TSKApiFormRequest) => {
            if (!detail?.id) {
              return;
            }

            try {
              setSubmitting(true);
              const res = await treatSurvey(`/surveys/${detail.id}/tsk`, {
                body: req,
                signal: abortController?.signal,
              });

              if (!(res instanceof Error)) {
                closeAction();
              }
            } finally {
              setSubmitting(false);
            }
          }}
          submitting={submitting}
        />
      );
  }
};

export default SolutionAction;

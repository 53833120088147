import React, { FC } from 'react';
import { InfoCircleFill } from 'react-bootstrap-icons';
import Icon from '../Icon/Icon';

interface ITooltipIcon {
  title: string;
}

const TooltipIcon: FC<ITooltipIcon> = ({ title }) => {
  return (
    <Icon className="d-inline float-end" title={title}>
      <InfoCircleFill color="gray" size="16px" />
    </Icon>
  );
};

export default TooltipIcon;

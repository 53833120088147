import { FC, useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import { object } from 'yup';
import { Formik, Form } from 'formik';
import { Card } from 'react-bootstrap';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { IMailFormProps } from '../../../interfaces/interfaces';
import InputFieldMail from '../InputFieldMail/InputFieldMail';
import MailEditor from '../MailEditor/MailEditor';
import ChooseInstitutions from '../ChooseInstitution/ChooseInstitutions';
import FileField from '../FileField/FileField';
import InputFile from '../InputFile/InputFile';
import { arrayOfEmailsScheme, filesInputScheme } from '../_Utils/yup_validations';
interface Values {
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  files: File[] | undefined;
}
// Yup validation schema
const ValidationSchema = object().shape({
  to: arrayOfEmailsScheme.required('Chybí e-mail'),
  cc: arrayOfEmailsScheme,
  bcc: arrayOfEmailsScheme,
  files: filesInputScheme,
});

const MailForm: FC<IMailFormProps> = (props): JSX.Element => {
  const { sendEmail, sendAll, setSolutionAction, setSolutionStepName } = useContext(SolutionContext);
  const { setShowModalHQ } = useContext(AppContext);
  const [editedText, setEditedText] = useState<string>();
  const [recipient, setRecipient] = useState<string>(props.to);
  const [alreadyFiles, setAlreadyFiles] = useState(props.files);

  const changeMessageOrder = (message: string) => {
    const parts = message.split('<br/>');
    const emailPart = parts.find(part => part.includes('Kontaktní e-mail'));
    const datePart = parts.find(part => part.includes('Datum a čas'));
    const zachovejtePartIndex = parts.findIndex(part => part.includes('Tento řádek prosím v budoucí komunikaci zachovejte'));
    parts.splice(parts.indexOf(emailPart!), 1);
    parts.splice(parts.indexOf(datePart!) + 1, 0, emailPart!);
    if (zachovejtePartIndex !== -1) {
      parts[zachovejtePartIndex] = parts[zachovejtePartIndex].replace(
        /Tento řádek prosím v budoucí komunikaci zachovejte/,
        'Tento řádek prosím v budoucí komunikaci zachovejte<br/>'
      );
    }
    const resultString = parts.join('<br/>');
    return resultString;
  };
  const message = changeMessageOrder(props.message!);

  const readTextHandler = (text: string) => {
    setEditedText(text);
  };

  const institutionHandler = (recipient: string) => {
    //setRecipient((prev) => (prev === '' ? recipient : prev + ',' + recipient));
    setRecipient(recipient);
  };

  const deletePhotoHandler = (id: number) => {
    setAlreadyFiles(alreadyFiles!.filter((file) => file.id !== id));
  };
  const resetPhotosHandler = () => {
    setAlreadyFiles(props.files);
  };

  const initialValues: Values = {
    to: recipient || '',
    cc: props.cc || '',
    bcc: props.bcc || '',
    subject: props.subject || '',
    files: undefined,
  };

  function deleteExtraTags(text: string): string {
    let result = text;
    
    const replacements: [RegExp, string][] = [
        [/<p>---------------------------------------<\/p>/g, '<hr style="border-color: #f6f6f6; padding-top: 5px; padding-bottom: 5px;" />'],
        [/<p><strong> Tento ř(&aacute;|á)dek pros(&iacute;|í)m v budouc(&iacute;|í) komunikaci zachovejte<\/strong><\/p>\s*<p><strong>([\w\/]+)<\/strong><\/p>/g, '<p style="margin-top: 5px;"><strong> Tento ř&aacute;dek pros&iacute;m v budouc&iacute; komunikaci zachovejte <span style="color: #d0d0d0;">$4</span> </strong></p>'],
    ];

    replacements.forEach(([pattern, replacement]) => {
      result = result.replace(pattern, replacement);
    });
    
    return result;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        const formObject = {
          bcc: values.bcc ? values.bcc!.split(',') : [],
          cc: values.cc ? values.cc!.split(',') : [],
          reg_num: props.regNum,
          step_id: props.step_id,
          status_id: props.status_id,
          to: values.to!.split(','),
          subject: values.subject,
          message: editedText ? deleteExtraTags(editedText) : deleteExtraTags(props.message),
        };
        const url = `/surveys/${props.surveyId}/solution-steps/${props.step_id}`;
        if (!values.files && !alreadyFiles) {
          sendEmail(url, formObject);
        }
        if (values.files && !alreadyFiles) {
          sendAll(props.surveyId, url, formObject, values.files);
        }
        if (!values.files && alreadyFiles) {
          sendAll(props.surveyId, url, formObject, [], alreadyFiles);
        }
        if (values.files && alreadyFiles) {
          sendAll(props.surveyId, url, formObject, values.files, alreadyFiles);
        }
        actions.setSubmitting(false);
        setSolutionStepName('');
        actions.resetForm();
        setSolutionAction(null);
        window.scrollTo(0, 0);
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Card className="mb-3">
          <Card.Body>
            <ChooseInstitutions onSend={institutionHandler} />
            <Form className="mb-3">
              <div className="d-flex">
                <InputFieldMail
                  id="to"
                  name="to"
                  label="Příjemce:"
                  placeholder="Příjemce"
                  type="email"
                  multiple
                />
                <button
                  className="btn btn-outline-secondary ms-2"
                  style={{ height: '38px', padding: '2px 12px' }}
                  type="button"
                  onClick={() => setRecipient('')}
                >
                  Vymazat
                </button>
              </div>
              <InputFieldMail
                id="cc"
                name="cc"
                label="Kopie:"
                placeholder="Kopie"
                type="email"
                multiple
              />
              <InputFieldMail
                id="bcc"
                name="bcc"
                label="Skrytá kopie:"
                placeholder="Skrytá kopie"
                type="email"
                multiple
              />
              <InputFieldMail
                id="subject"
                name="subject"
                label="Předmět:"
                placeholder="Předmět"
                type="text"
              />
              <div className="mb-1">
                {props.files?.length! > 0 && (
                  <FileField
                    label={`Fotografie:`}
                    files={alreadyFiles!}
                    survey_id={props.surveyId}
                    onDelete={deletePhotoHandler}
                    onReset={resetPhotosHandler}
                    onClick={() => setShowModalHQ({ show: true, type: 'photos' })}
                    controls
                    imageWidth={7}
                  />
                )}
              </div>
              <div className="mb-3">
                <InputFile
                  style={{ color: '#777' }}
                  name="files"
                  id="files"
                  type="file"
                  accept="image/jpg, image/jpeg, image/gif, image/png"
                  multiple
                  onChange={(event) => {
                    setFieldValue('files', event.currentTarget.files);
                  }}
                />
                {errors.files && touched.files ? (
                  <StyledErrorMessage>{errors.files}</StyledErrorMessage>
                ) : null}
              </div>
              <div className="mb-5">
                <MailEditor initialText={message} readText={readTextHandler} />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-outline-danger"
                  type="button"
                  onClick={() => {
                    setSolutionStepName('');
                    setSolutionAction(null);
                    window.scrollTo(0, 0);
                  }}
                  style={{ maxWidth: '8rem' }}
                >
                  Zpět
                </button>
                <button
                  className="btn btn-outline-success"
                  type="submit"
                  style={{ maxWidth: '8rem' }}
                  disabled={isSubmitting}
                >
                  Odeslat
                </button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

export default MailForm;

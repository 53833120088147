import { FC, useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import AppContext from '../../../store/appContext';
import { Props } from '../../../interfaces/interfaces';
import UnpairedEmail from '../../layout/UnpairedEmail/UnpairedEmail';
import Paginator from '../../ui/Paginator/Paginator';

export interface IUnpairedMail {
  id: number;
  subject: string;
  from_email: string;
  to_email: string;
  cc_email: string;
  bcc_email: string;
  body: string;
  date: Date;
  attachments: string;
  created_at: Date;
}

export interface IUnpaired {
  data: IUnpairedMail[];
  total: number;
}

const Unpaired: FC<Props> = ({ header }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { getUnpairedEmails, limit, totalSurCount, unpaired, getWhoAmI } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getWhoAmI(), []);

  useEffect(() => {
    getUnpairedEmails(limit, (currentPage - 1) * limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, currentPage]);

  let result = [
    <Col key={`99999999`} className="d-flex justify-content-center">
      <Spinner animation="border" />
    </Col>,
  ];
  let generatedSurveys;
  if (unpaired) {
    generatedSurveys = unpaired.data.map((survey) => {
      return (
        <Col key={survey?.id} xs={12}>
          <UnpairedEmail fields={survey} />
        </Col>
      );
    });
    if (generatedSurveys.length > 0) {
      result = generatedSurveys;
    } else {
      result = [
        <Col key={`99999999`}>
          <h3 className="mt-5 text-center">Nic jsem nenašlo, pardon</h3>
        </Col>,
      ];
    }
  }

  return (
    <section className="top">
      <h1 className="px-3 mb-4 display-5 text-center">{header}</h1>
      <Container>
        <Row className="mb-3">
          <Col>
            <Paginator
              totalCount={totalSurCount!}
              itemsPerPage={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
        <Row className="mb-3">{result}</Row>
        <Row className="mb-5">
          <Col>
            <Paginator
              totalCount={totalSurCount!}
              itemsPerPage={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Unpaired;

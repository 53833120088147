import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../store/authContext';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { LOGIN_ROUTE, token } = useContext(AuthContext);
  const location = useLocation();
  return token ? children : <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
};

export default ProtectedRoute;

import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import { CheckLg, Pencil, XLg } from 'react-bootstrap-icons';
import { Button, InputGroup } from 'react-bootstrap';
import { IChildren } from '../../../interfaces/interfaces';

interface EditableInputProps {
  text: string;
  saveAction: () => {};
  setEditValue: Dispatch<SetStateAction<string>>;
  childRef: any;
  children: IChildren;
  disabled?: boolean;
}

const EditableInput: FC<EditableInputProps> = ({
  text,
  saveAction,
  setEditValue,
  childRef,
  children,
  disabled,
  ...props
}) => {
  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    if (childRef && childRef.current && editable) {
      childRef.current.focus();
    }
  }, [editable, childRef]);

  const onSave = () => {
    saveAction();
    setEditable(false);
  };

  const onCancel = () => {
    setEditable(false);
    setEditValue(text);
  };

  return (
    <Fragment {...props}>
      {editable ? (
        <InputGroup className="mb-3">
          {children}
          <Button title="Uložit" size="sm" variant="success" onClick={onSave}>
            <Icon>
              <CheckLg color="white" />
            </Icon>
          </Button>
          <Button title="Zrušit" size="sm" variant="secondary" onClick={onCancel}>
            <Icon>
              <XLg color="white" />
            </Icon>
          </Button>
        </InputGroup>
      ) : (
        <span>
          <span>{text}</span>
          <Button
            title="Editovat"
            className="ms-3"
            size="sm"
            variant="info"
            disabled={disabled}
            onClick={() => setEditable(true)}
          >
            <Icon>
              <Pencil color="white" />
            </Icon>
          </Button>
        </span>
      )}
    </Fragment>
  );
};

export default EditableInput;

import { FC, useContext, useState } from 'react';
import SolutionContext from '../../../store/solutionContext';
import CarouselComponent from '../Carousel/Carousel';
import FileForm from '../FileForm/FileForm';
import { Card, Button } from 'react-bootstrap';
import { IFiles } from '../../../interfaces/interfaces';

interface IGallery {
  files: IFiles[] | undefined;
  survey_id: string;
}

const Gallery: FC<IGallery> = ({ files, survey_id }): JSX.Element => {
  const { deletePhoto } = useContext(SolutionContext);
  const [confirmation, setConfirmation] = useState<{ show: boolean; id: number | null }>({
    show: false,
    id: null,
  });
  const [addPhotoFormView, setAddPhotoFormView] = useState(false);

  const handleDelete = (id: number) => {
    setConfirmation({ show: true, id: id });
    setAddPhotoFormView(false);
  };
  const handleDeleteTrue = () => {
    deletePhoto(survey_id, confirmation.id!);
    setConfirmation({ show: false, id: null });
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };
  const handleDeleteFalse = () => setConfirmation({ show: false, id: null });

  return (
    <Card>
      <Card.Body>
        {files && files.length > 0 && (
          <CarouselComponent files={files} handleDelete={handleDelete} />
        )}
      </Card.Body>
      <Card.Body>
        {confirmation.show && (
          <Button variant="outline-danger" className="mb-3" onClick={handleDeleteTrue}>
            Opravdu smazat
          </Button>
        )}
        {confirmation.show && (
          <Button variant="outline-success" className="mb-3 ms-3" onClick={handleDeleteFalse}>
            Zrušit
          </Button>
        )}
        <div className="d-flex flex-column me-auto">
          <Button
            variant="outline-info"
            className="mb-3"
            onClick={() => {
              setAddPhotoFormView(!addPhotoFormView);
              setConfirmation({ show: false, id: null });
            }}
          >
            Přidat fotografie
          </Button>
          {addPhotoFormView && <FileForm survey_id={survey_id} />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Gallery;

import { createContext, useContext } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthContext } from './authContext';
import { configuration } from '../configuration';
import { IChildren } from '../interfaces/interfaces';

const AxiosContext = createContext<AxiosInstance>(null as unknown as AxiosInstance);

export const AxiosContextProvider = ({ children }: IChildren) => {
  const defaultOptions = {
    baseURL: configuration.API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instanceRef = axios.create(defaultOptions);
  const { token, setToken } = useContext(AuthContext);

  instanceRef.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers!['Authorization'] = token ? `Bearer ${token}` : '';
    return config;
  });

  instanceRef.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && token) {
        setToken(null);
      }
      return Promise.reject(error);
    }
  );

  return <AxiosContext.Provider value={instanceRef}>{children}</AxiosContext.Provider>;
};

export default AxiosContext;

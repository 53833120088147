import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import toast from 'react-hot-toast';
import { OperatorContextType, ROLES } from './index';
import { IUser } from '../../../types';

type IAddOperator = Pick<IUser, 'name' | 'surname' | 'username'>;

export default function OperatorAdd() {
  const { setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const { getOperators } = useOutletContext<OperatorContextType>();
  const [operator, setOperator] = useState<IAddOperator>({
    name: '',
    surname: '',
    username: '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    setOperator({
      ...operator,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await httpClient.post(`/users`, { ...operator, role: ROLES.OPERATOR });
      setOperator({
        name: '',
        surname: '',
        username: '',
      });
      await getOperators();
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  return (
    <div className="mb-5">
      <h3 className="pb-3">Přidat operátora</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Jméno</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="text"
              placeholder="Jméno"
              name="name"
              value={operator.name}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Příjmení</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="text"
              placeholder="Příjmení"
              name="surname"
              value={operator.surname}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Email</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="email"
              placeholder="user@oict.cz"
              name="username"
              value={operator.username}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <div className="clearfix">
          <Button size="sm" variant="success" type="submit" className="mt-2 float-end">
            Uložit
          </Button>
        </div>
      </Form>
    </div>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperatorResponseDTO,
    OperatorResponseDTOFromJSON,
    OperatorResponseDTOFromJSONTyped,
    OperatorResponseDTOToJSON,
} from './OperatorResponseDTO';
import {
    SurveyFiltersStatusResponseDTO,
    SurveyFiltersStatusResponseDTOFromJSON,
    SurveyFiltersStatusResponseDTOFromJSONTyped,
    SurveyFiltersStatusResponseDTOToJSON,
} from './SurveyFiltersStatusResponseDTO';
import {
    SurveyFiltersTagResponseDTO,
    SurveyFiltersTagResponseDTOFromJSON,
    SurveyFiltersTagResponseDTOFromJSONTyped,
    SurveyFiltersTagResponseDTOToJSON,
} from './SurveyFiltersTagResponseDTO';

/**
 * 
 * @export
 * @interface SurveyFiltersResponseDTO
 */
export interface SurveyFiltersResponseDTO {
    /**
     * 
     * @type {Array<OperatorResponseDTO>}
     * @memberof SurveyFiltersResponseDTO
     */
    operators: Array<OperatorResponseDTO>;
    /**
     * 
     * @type {Array<SurveyFiltersStatusResponseDTO>}
     * @memberof SurveyFiltersResponseDTO
     */
    statuses: Array<SurveyFiltersStatusResponseDTO>;
    /**
     * 
     * @type {Array<SurveyFiltersTagResponseDTO>}
     * @memberof SurveyFiltersResponseDTO
     */
    extTags: Array<SurveyFiltersTagResponseDTO>;
}

export function SurveyFiltersResponseDTOFromJSON(json: any): SurveyFiltersResponseDTO {
    return SurveyFiltersResponseDTOFromJSONTyped(json, false);
}

export function SurveyFiltersResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyFiltersResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operators': ((json['operators'] as Array<any>).map(OperatorResponseDTOFromJSON)),
        'statuses': ((json['statuses'] as Array<any>).map(SurveyFiltersStatusResponseDTOFromJSON)),
        'extTags': ((json['ext_tags'] as Array<any>).map(SurveyFiltersTagResponseDTOFromJSON)),
    };
}

export function SurveyFiltersResponseDTOToJSON(value?: SurveyFiltersResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operators': ((value.operators as Array<any>).map(OperatorResponseDTOToJSON)),
        'statuses': ((value.statuses as Array<any>).map(SurveyFiltersStatusResponseDTOToJSON)),
        'ext_tags': ((value.extTags as Array<any>).map(SurveyFiltersTagResponseDTOToJSON)),
    };
}


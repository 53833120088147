import { FC } from 'react';
import { Field, useField } from 'formik';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { InputProps } from '../../../interfaces/interfaces';

const InputFieldLabelTop: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <div className="d-flex flex-column">
      {label && (
        <label className="form-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <Field className="form-control align-self-start" {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="ms-2">{meta.error}</StyledErrorMessage>
      ) : null}
    </div>
  );
};

export default InputFieldLabelTop;

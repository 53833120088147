import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Accordion, Col, Container, Row, Table } from 'react-bootstrap';
import { Check, GeoAltFill, X } from 'react-bootstrap-icons';
import Icon from '../../ui/Icon/Icon';
import toast from 'react-hot-toast';
import { Props } from '../../../interfaces/interfaces';
import { IOffice } from '../../../types';

const RatingsOrganizations: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [organizations, setOrganizations] = useState<IOffice[]>([]);

  const getOrganizations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/office-ratings/offices`);
      setOrganizations(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <Row>
          <Col>
            <h2 className="mb-5">Přehled organizací</h2>
            <Accordion className="mb-5">
              {organizations.map((organization) => (
                <Accordion.Item key={organization.id} eventKey={organization.id.toString()}>
                  {/*<Accordion.Header style={{ color: organization.ratable ? "yellowgreen" : " red" }}>*/}
                  <Accordion.Header>
                    <Icon>
                      {organization.ratable ? (
                        <Check color="green" size={34} />
                      ) : (
                        <X color="red" size={34} />
                      )}
                    </Icon>
                    {organization.name}
                  </Accordion.Header>
                  <Accordion.Body>
                    <h4 className="pt-2">
                      {organization.name} {organization.ratable ? 'je možné' : 'nelze'} hodnotit
                    </h4>
                    {!!organization.divisions && !!organization.divisions.length && (
                      <Fragment>
                        <h5 className="pt-4">Útvary</h5>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>název</th>
                              <th>adresa</th>
                              <th>pozice</th>
                              <th>stav</th>
                              {/*<th>akce</th>*/}
                            </tr>
                          </thead>
                          <tbody>
                            {organization.divisions.map((division) => (
                              <Fragment key={division.id + '_info'}>
                                <tr key={division.id} style={{ backgroundColor: 'lightgray' }}>
                                  <td colSpan={4}>
                                    <b>{division.name}</b>
                                  </td>
                                </tr>
                                {division.departments.map((department) => (
                                  <tr key={department.id}>
                                    <td>{department.name}</td>
                                    <td>{department.address}</td>
                                    <td>
                                      <a
                                        target="_blank"
                                        title="Zobrazit na mapě"
                                        href={`https://mapy.cz/zakladni?x=${department.lng}&y=${department.lat}&z=17&source=coor&id=${department.lng}%2C${department.lat}`}
                                        className="btn btn-info"
                                        rel="noreferrer"
                                      >
                                        <GeoAltFill color="white" />
                                      </a>
                                    </td>
                                    <td>
                                      {department.ratable ? (
                                        <Check color="green" size={34} />
                                      ) : (
                                        <X color="red" size={34} />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </Fragment>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RatingsOrganizations;

import { FC, useContext } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import GoogleMap from '../Map/GoogleMap';

const ChangeGPS: FC = () => {
  const { detail, LatLng, setShowModalHQ } = useContext(AppContext);
  const { changeGPS } = useContext(SolutionContext);
  const initialValues = {
    newGPS: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        const gpsString = `${LatLng?.lat}, ${LatLng?.lng}`;
        changeGPS(detail?.id!, gpsString);
        actions.setSubmitting(false);
        actions.resetForm();
        setShowModalHQ({ show: false, type: '' });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form className="text-dark">
          <Row>
            <Col>
              <div className="mb-3">
                <span>Lokace</span>
                {detail && <GoogleMap isAutocomplete />}
              </div>
            </Col>
            <button
              className="btn btn-outline-dark shadow"
              style={{ maxWidth: '97%', margin: '0 auto' }}
              type="submit"
              disabled={isSubmitting}
            >
              Odeslat
            </button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeGPS;

import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { InstitutionContextType } from './index';
import { IInstitution } from '../../../types';

type IAddInstitution = Pick<IInstitution, 'name' | 'special_description'>;

export default function InstitutionAdd() {
  const { setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const { getInstitutions } = useOutletContext<InstitutionContextType>();
  const [institution, setInstitution] = useState<IAddInstitution>({
    name: '',
    special_description: '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    setInstitution({
      ...institution,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await httpClient.post(`/institutions`, { ...institution });
      setInstitution({
        name: '',
        special_description: '',
      });
      await getInstitutions();
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
  };

  return (
    <div className="mb-5">
      <h3 className="pb-3">Přidat instituci</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Název</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="text"
              placeholder="Název instituce"
              name="name"
              value={institution.name}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Popis</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              name="special_description"
              placeholder="Zde napište speciální instrukce pro tuto instituci. Zobrazí se před odesláním podnětu na instituci."
              value={institution.special_description}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <div className="clearfix">
          <Button size="sm" variant="success" type="submit" className="mt-2 float-end">
            Uložit
          </Button>
        </div>
      </Form>
    </div>
  );
}

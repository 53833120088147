import { GeoAlt } from 'react-bootstrap-icons';
import styled from 'styled-components';

export const MarkerIcon = styled(GeoAlt)`
  border-radius: 0.2rem;
  font-size: 1.7rem;
  padding: 0.1rem 0.1rem;
  transition: all 0.1s ease-in;
  &:hover {
    color: red;
  }
  margin-left: 0.2rem;
`;

import { CSSProperties, FC } from 'react';
import StyledText from '../StyledText/StyledText';
import StyledTextCatName from '../StyledTextCatName/StyledTextCatName';
import React from 'react';
import { IChildren } from '../../../interfaces/interfaces';

const StyledLine: FC<{
  header: string;
  data?: string | number | Date | boolean;
  className?: string;
  style?: CSSProperties;
  children?: IChildren;
}> = ({ header, data, children, style }) => {
  return (
    <StyledText style={style}>
      <StyledTextCatName>{header}</StyledTextCatName> {data}
      {children}
    </StyledText>
  );
};

export default StyledLine;

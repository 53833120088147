import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { useOutletContext, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { OperatorContextType } from './index';
import { IUser } from '../../../types';

type IEditOperator = Pick<
  IUser,
  'assign_amount' | 'max_surveys' | 'assign_balance' | 'set_holidays'
>;

export default function OperatorDetail() {
  const { setError, setIsLoading } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const { operatorId } = useParams();

  const { selectedOperatorId, setSelectedOperatorId, getOperators } =
    useOutletContext<OperatorContextType>();
  const [selectedOperator, setSelectedOperator] = useState<IUser | null>(null);

  const getOperatorDetail = useCallback(async (operatorId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/users/${operatorId}?includeStats=true`);
      setSelectedOperator(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedOperatorId(operatorId ? +operatorId : null);
    getOperatorDetail(operatorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  const [operator, setOperator] = useState<IEditOperator>({
    set_holidays: !!selectedOperator?.set_holidays,
    assign_balance: !!selectedOperator?.assign_balance,
    assign_amount: !!selectedOperator?.assign_amount,
    max_surveys: selectedOperator?.max_surveys ?? 0,
  });

  useEffect(() => {
    setOperator({
      set_holidays: !!selectedOperator?.set_holidays,
      assign_balance: !!selectedOperator?.assign_balance,
      assign_amount: !!selectedOperator?.assign_amount,
      max_surveys: selectedOperator?.max_surveys ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperatorId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    setOperator({
      ...operator,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await httpClient.put(`/users/${operatorId}`, operator);
      await getOperators();
      await getOperatorDetail(operatorId);
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
  };

  return (
    <div className="mb-5">
      <h3>
        Operátor:{' '}
        {selectedOperator?.name && selectedOperator?.surname ? (
          <span>
            {selectedOperator?.surname} {selectedOperator?.name}
          </span>
        ) : (
          <span>{selectedOperator?.username}</span>
        )}
      </h3>
      <h4 className="pt-4">Statistika</h4>
      <Row>
        <Col sm={3}>
          <Card className="p-4 text-center text-primary">
            <Card.Title className="display-2">
              {selectedOperator?.statistics?.active ?? 0}
            </Card.Title>
            <Card.Text className="small text-center">přidělených podnětů</Card.Text>
          </Card>
        </Col>
        <Col sm={3}>
          <Card className="p-4 text-center text-success">
            <Card.Title className="display-2">
              {selectedOperator?.statistics?.solved ?? 0}
            </Card.Title>
            <Card.Text className="small">vyřešených podnětů</Card.Text>
          </Card>
        </Col>
        <Col sm={3}>
          <Card className="p-4 text-center text-danger">
            <Card.Title className="display-2">
              {selectedOperator?.statistics?.unsolved ?? 0}
            </Card.Title>
            <Card.Text className="small">předaných podnětů</Card.Text>
          </Card>
        </Col>
        <Col sm={3}>
          <Card className="p-4 text-center">
            <Card.Title className="display-2">
              {selectedOperator?.statistics?.postponed ?? 0}
            </Card.Title>
            <Card.Text className="small">odložených podnětů</Card.Text>
          </Card>
        </Col>
      </Row>
      <div className="d-none">
        <h4 className="pt-4">Nastavení</h4>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name="assign_amount"
              checked={!!operator?.assign_amount}
              onChange={handleChange}
              label="Rozdělení nových podnětů do maximálního počtu operátorových aktuálních přidělených podnětů."
            />
            <div className="px-4 py-2">
              <Form.Label>Maximální počet:</Form.Label>
              <Form.Control
                type="number"
                name="max_surveys"
                value={operator?.max_surveys}
                onChange={handleChange}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 py-2">
            <Form.Check
              type="checkbox"
              name="assign_balance"
              checked={!!operator?.assign_balance}
              onChange={handleChange}
              label="Rozdělení nových podnětů postupně každému operátorovi bez ohledu na aktuální počet přidělených podnětů."
            />
          </Form.Group>
          <Form.Group className="mb-3 py-2">
            <Form.Check
              type="checkbox"
              name="set_holidays"
              checked={!!operator?.set_holidays}
              onChange={handleChange}
              label="Nastavit dovolenou. Nebudou se přidávat žádné podněty."
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Uložit
          </Button>
        </Form>
      </div>
    </div>
  );
}

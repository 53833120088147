import { array, mixed, string } from 'yup';
//form custom helper functions
import {
  checkIfFilesAreTooBig,
  checkIfFilesAreCorrectType,
  checkIfFilesAreOverLimit3,
} from './helperFunctions';

export const arrayOfEmailsScheme = array()
  .of(string().email())
  .transform(function (value: string, originalValue: string) {
    if (this.isType(value) && value !== null) {
      return value;
    }
    return originalValue ? originalValue.split(/[\s,]+/) : [];
  })
  .of(string().email(({ value }) => `${value} není platný e-mail`));

export const usernameYup = string().email('Neplatná e-mailová adresa').required('Vyplňte, prosím');

export const passwordYup = string()
  .min(6, 'Příliš krátké! Min 6 znaků')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
    'Heslo musí obsahovat min. 6 znaků, velké a malé písmeno a číslo.'
  )
  .required('Vyplňte, prosím');

export const filesInputScheme = mixed()
  .test('fileSize', 'Soubor je příliš veliký, max velikost je 5 MB/soubor', checkIfFilesAreTooBig)
  .test(
    'fileType',
    'Neplatný typ souboru (povolené jsou jpg, gif a png)',
    checkIfFilesAreCorrectType
  )
  .test('fileCount', 'Maximální počet souborů je 3', checkIfFilesAreOverLimit3);

export const checkAtLeatstOneSheme = mixed().test(
  'arraySize',
  'Vyberte minimálně jednu možnost',
  (value: []) => value.length > 0
);

export const chooseRadioOptionSheme = string().required('Zvolte možnost');

import Quill from 'quill';
import { BlockEmbed } from 'quill/blots/block';

class DividerBlot extends BlockEmbed {
  static blotName = 'divider';
  static tagName = 'hr';
  static className: 'underline-blot';
}

Quill.register(DividerBlot);

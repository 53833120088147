import styled from 'styled-components';
import { Badge } from 'react-bootstrap';

export const StyledBadge = styled(Badge)`
  background-color: ${(props) =>
    props.rating < 2
      ? 'green !important'
      : props.rating > 4
      ? 'red !important'
      : 'gray !important'};
  font-size: ${(props) => (props.scale ? props.scale + 'em' : 'inherit')};
`;

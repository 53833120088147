import { FC, useContext, useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import AppContext from '../../../store/appContext';
import SetOperatorForm from '../../ui/SetOperatorForm/SetOperatorForm';
import StyledCard from '../../ui/StyledCard/StyledCard';
import SurveyDetailBody from '../SurveyDetailBody/SurveyDetailBody';
import SurveyMessageHistory from '../../ui/SurveyMessageHistory/SurveyMessageHistory';
import SurveyMessageHistoryItem from '../SurveyMessageHistoryItem/SurveyMessageHistoryItem';
import SurveyStatusHistoryItem from '../SurveyStatusHistoryItem/SurveyStatusHistoryItem';
import SurveyStatusHistory from '../../ui/SurveyStatusHistory/SurveyStatusHistory';
import { IDetail } from '../../../interfaces/interfaces';
import { toCzDateTime } from '../../../utils/toCzDateTime';

interface IDetails {
  details: IDetail;
}

const SurveyDetail: FC<IDetails> = ({ details }): JSX.Element => {
  const { whoAmI, setLatLng } = useContext(AppContext);
  const [showSubHistory, setShowSubHistory] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);

  const {
    id,
    category_name,
    reg_num,
    description,
    email,
    gps,
    datetime,
    status_name,
    status_id,
    priority,
    survey_lock_user_username,
    admin_notes,
    files,
    status_history,
    mail_history,
    created_at,
    dpp_sent,
    ext_solved,
    ext_apology,
    ext_urgency,
    ext_anonym,
    ext_praise,
    child_surveys,
  } = details;

  let shortOp: string;
  if (survey_lock_user_username) {
    shortOp = survey_lock_user_username!.split('@')[0] + '@';
  }

  const gpsArr = gps!.split(',');

  const toggleSubHistory = () => {
    setShowSubHistory(!showSubHistory);
  };

  const toggleChatHistory = () => {
    setShowChatHistory(!showChatHistory);
  };

  useEffect(() => {
    setLatLng({ lat: Number(gpsArr[0]), lng: Number(gpsArr[1]) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gps]);

  return (
    <StyledCard className="mb-3" priority={details.priority}>
      <Card.Body>
        <Row>
          <Col>
            <Card.Title>
              <h4>Podnět: {reg_num}</h4>
            </Card.Title>
          </Col>
          <Col>{whoAmI && whoAmI.role !== 'guest' && <SetOperatorForm />}</Col>
        </Row>
        <div className="d-flex">
          {dpp_sent !== null && <span className={`me-1 tag tag-1 mb-2`}>DPP</span>}
          {ext_solved && <span className={`me-1 tag tag-5 mb-2`}>Změněno</span>}
          {ext_apology && <span className={`me-1 tag tag-2 mb-2`}>Omluvenka</span>}
          {ext_urgency && <span className={`me-1 tag tag-3 mb-2`}>Urgence</span>}
          {ext_anonym && <span className={`me-1 tag tag-4 mb-2`}>Anonym</span>}
          {ext_praise && <span className={`me-1 tag tag-7 mb-2`}>Pochvala</span>}
          {child_surveys && <span className={`me-1 tag tag-2 mb-2`}>Hlavní podnět</span>}
          {reg_num && reg_num.indexOf('/') >= 0 && (
            <span className={`me-1 tag tag-2 mb-2`}>Podpodnět</span>
          )}
          {shortOp! && <Card.Text className={`mb-2 me-1 text-dark bg-info filt`}>{shortOp!}</Card.Text>}
          {status_id && <Card.Text className={`mb-2 me-1 filt filt-${status_id}`}>{status_name}</Card.Text>}
        </div>
        <Row>
          <Col>
            <SurveyDetailBody
              reg_num={reg_num!}
              admin_notes={admin_notes!}
              category_name={category_name!}
              datetime={toCzDateTime(datetime)}
              created_at={toCzDateTime(created_at)}
              description={description!}
              email={email!}
              files={files!}
              gps={gps!}
              id={id!}
              status_name={status_name!}
              survey_lock_user_username={survey_lock_user_username!}
              priority={priority!}
            />
            <SurveyStatusHistory>
              <Card.Title>Historie podnětu:</Card.Title>
              <button className='btn btn-outline-dark mb-2' onClick={toggleSubHistory}>
                {showSubHistory ? 'Skrýt historii podnětu' : 'Zobrazit historii podnětu ('+ status_history?.length +')'}
              </button>
              {showSubHistory && status_history?.map((story, i) => {
                return (
                  <SurveyStatusHistoryItem
                    key={story.id}
                    updated_at={toCzDateTime(story.updated_at)}
                    message={story.message!}
                    i={i}
                    arrLenght={status_history?.length!}
                  />
                );
              })}
            </SurveyStatusHistory>
            <SurveyMessageHistory>
              <Card.Title>Historie komunikace podnětu:</Card.Title>
              <button className='btn btn-outline-dark mb-2' onClick={toggleChatHistory}>
                {showChatHistory ? 'Skrýt historii komunikace' : 'Zobrazit historii komunikace ('+ mail_history?.length +')'}
              </button>
              {showChatHistory && mail_history?.map((mail, i) => {
                return (
                  <SurveyMessageHistoryItem
                    key={mail.id}
                    id={mail.id}
                    created_at={toCzDateTime(mail.created_at)}
                    from={mail.from}
                    cc={mail.cc}
                    bcc={mail.bcc}
                    subject={mail.subject}
                    to={mail.to}
                    message={mail.message}
                    mail_box={mail.mail_box}
                    sent={mail.sent}
                    attachments={mail.attachments}
                    i={i}
                    arrLength={mail_history?.length!}
                  />
                );
              })}
            </SurveyMessageHistory>
          </Col>
        </Row>
      </Card.Body>
    </StyledCard>
  );
};

export default SurveyDetail;

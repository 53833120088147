import { FC, MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { StyledScrollableDiv } from '../../ui/StyledScrollableDiv/StyledScrollableDiv';
import toast from 'react-hot-toast';
import { PlusLg } from 'react-bootstrap-icons';
import Icon from '../../ui/Icon/Icon';
import { Props } from '../../../interfaces/interfaces';
import { IUser } from '../../../types';

export enum ROLES {
  GUEST = 'guest',
  OPERATOR = 'operator',
  CHIEF_OPERATOR = 'chief_operator',
  ADMIN = 'admin',
  DEACTIVATED = 'deactivated',
}

export type OperatorContextType = {
  selectedOperatorId: number | null;
  setSelectedOperatorId: React.Dispatch<React.SetStateAction<number | null>>;
  getOperators: () => Promise<void>;
};

const Operators: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError, getWhoAmI } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [operators, setOperators] = useState<IUser[]>([]);
  const [selectedOperatorId, setSelectedOperatorId] = useState<number | null>(null);

  const getOperators = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(
        `/users?active=true&role=${ROLES.OPERATOR}&role=${ROLES.CHIEF_OPERATOR}`
      );
      setOperators(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWhoAmI();
    getOperators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUserSelect = (event: MouseEvent, user: IUser) => {
    setSelectedOperatorId(user.id);
  };

  const onUserAdd = (event: MouseEvent) => {
    setSelectedOperatorId(null);
  };

  return (
    <Container fluid style={{ minHeight: '70vh' }}>
      <h1 className="px-3 mb-5 text-center">{props.header}</h1>
      <Row>
        <Col md={4}>
          <div className="clearfix mb-2">
            <h4 className="d-inline-block">Přehled operátorů</h4>
            <Link
              to="add"
              className="btn btn-sm btn-success float-end"
              onClick={(event) => onUserAdd(event)}
            >
              <Icon>
                <PlusLg size={24} color="white" />
              </Icon>
            </Link>
          </div>
          <StyledScrollableDiv>
            <ul className="list-unstyled">
              {operators.map((user) => (
                <li
                  key={user.id}
                  className="list-group list-group-flush"
                  style={{
                    border: '1px solid rgba(0,0,0,.125)',
                    borderRadius: '0.4rem',
                    marginBottom: '0.2rem',
                  }}
                >
                  <NavLink
                    to={user.id.toString()}
                    className={`list-group-item list-group-item-action py-2`}
                    onClick={(event) => onUserSelect(event, user)}
                  >
                    {user.name && user.surname ? (
                      <span>
                        {user.surname} {user.name}
                      </span>
                    ) : (
                      '---'
                    )}
                    <br />
                    <span className="small fst-italic">({user.username})</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </StyledScrollableDiv>
        </Col>
        <Col md={8}>
          <Outlet context={{ selectedOperatorId, setSelectedOperatorId, getOperators }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Operators;

import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../../../styles/styles.scss';

interface IPriority {
  priority: 'urgent' | 'high' | 'normal' | 'X';
}

const PriorityLink = styled(Link)<IPriority>``;

const PriorityButton: FC<IPriority> = ({ priority }): JSX.Element => {
  return (
    <PriorityLink
      priority={priority}
      to={`/surveys/${priority !== 'X' ? priority : ''}`}
      className={`me-2 mb-2 filt filt-${
        priority === 'urgent' ? 1 : priority === 'high' ? 12 : priority === 'normal' ? 13 : 11
      }`}
    >
      {priority}
    </PriorityLink>
  );
};

export default PriorityButton;

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequestResponseDTO,
    BadRequestResponseDTOFromJSON,
    BadRequestResponseDTOToJSON,
    ForbiddenResponseDTO,
    ForbiddenResponseDTOFromJSON,
    ForbiddenResponseDTOToJSON,
    NotFoundResponseDTO,
    NotFoundResponseDTOFromJSON,
    NotFoundResponseDTOToJSON,
    RequestEntityTooLargeResponseDTO,
    RequestEntityTooLargeResponseDTOFromJSON,
    RequestEntityTooLargeResponseDTOToJSON,
    SurveyAddNoteRequestDTO,
    SurveyAddNoteRequestDTOFromJSON,
    SurveyAddNoteRequestDTOToJSON,
    SurveyChildResponseDTO,
    SurveyChildResponseDTOFromJSON,
    SurveyChildResponseDTOToJSON,
    SurveyCountsResponseDTO,
    SurveyCountsResponseDTOFromJSON,
    SurveyCountsResponseDTOToJSON,
    SurveyDetailResponseDTO,
    SurveyDetailResponseDTOFromJSON,
    SurveyDetailResponseDTOToJSON,
    SurveyEmailAttachmentDTO,
    SurveyEmailAttachmentDTOFromJSON,
    SurveyEmailAttachmentDTOToJSON,
    SurveyFiltersResponseDTO,
    SurveyFiltersResponseDTOFromJSON,
    SurveyFiltersResponseDTOToJSON,
    SurveyPaginatedResponseDTO,
    SurveyPaginatedResponseDTOFromJSON,
    SurveyPaginatedResponseDTOToJSON,
    SurveyPatchRequestDTO,
    SurveyPatchRequestDTOFromJSON,
    SurveyPatchRequestDTOToJSON,
    SurveyPrefilledEmailRequestDTO,
    SurveyPrefilledEmailRequestDTOFromJSON,
    SurveyPrefilledEmailRequestDTOToJSON,
    SurveyPrefilledEmailResponseDTO,
    SurveyPrefilledEmailResponseDTOFromJSON,
    SurveyPrefilledEmailResponseDTOToJSON,
    SurveyReassignUserRequestDTO,
    SurveyReassignUserRequestDTOFromJSON,
    SurveyReassignUserRequestDTOToJSON,
    SurveyResponseDTO,
    SurveyResponseDTOFromJSON,
    SurveyResponseDTOToJSON,
    SurveySetStateRequestDTO,
    SurveySetStateRequestDTOFromJSON,
    SurveySetStateRequestDTOToJSON,
    SurveySplitResponseDTO,
    SurveySplitResponseDTOFromJSON,
    SurveySplitResponseDTOToJSON,
    SurveyStepsResponseDTO,
    SurveyStepsResponseDTOFromJSON,
    SurveyStepsResponseDTOToJSON,
    SurveyUpdateGpsRequestDTO,
    SurveyUpdateGpsRequestDTOFromJSON,
    SurveyUpdateGpsRequestDTOToJSON,
    UnauthorizedResponseDTO,
    UnauthorizedResponseDTOFromJSON,
    UnauthorizedResponseDTOToJSON,
} from '../models';

export interface SurveysControllerAddNoteRequest {
    surveyId: string;
    surveyAddNoteRequestDTO: SurveyAddNoteRequestDTO;
}

export interface SurveysControllerGetChildSurveysRequest {
    surveyId: string;
}

export interface SurveysControllerGetPrefilledEmailsRequest {
    surveyId: string;
    stepId: number;
    optionIds?: Array<string>;
}

export interface SurveysControllerGetSurveyRequest {
    surveyId: string;
}

export interface SurveysControllerGetSurveysRequest {
    operatorId?: number;
    statusId?: number;
    extTag?: SurveysControllerGetSurveysExtTagEnum;
    priority?: SurveysControllerGetSurveysPriorityEnum;
    email?: string;
    regNum?: string;
    limit?: number;
    offset?: number;
}

export interface SurveysControllerPatchSurveyRequest {
    surveyId: string;
    surveyPatchRequestDTO: SurveyPatchRequestDTO;
}

export interface SurveysControllerReassignSurveyRequest {
    surveyId: string;
    surveyReassignUserRequestDTO: SurveyReassignUserRequestDTO;
}

export interface SurveysControllerSendPrefilledEmailsRequest {
    surveyId: string;
    stepId: number;
    surveyPrefilledEmailRequestDTO: SurveyPrefilledEmailRequestDTO;
}

export interface SurveysControllerSendToDppRequest {
    surveyId: string;
}

export interface SurveysControllerSendToMHMPRequest {
    surveyId: string;
}

export interface SurveysControllerSetStatusRequest {
    surveyId: string;
    surveySetStateRequestDTO: SurveySetStateRequestDTO;
}

export interface SurveysControllerSplitSurveysRequest {
    surveyId: string;
}

export interface SurveysControllerUpdateGpsRequest {
    surveyId: string;
    surveyUpdateGpsRequestDTO: SurveyUpdateGpsRequestDTO;
}

export interface SurveysControllerUploadFileToSurveyRequest {
    surveyId: string;
    files: Array<any>;
}

/**
 * 
 */
export class SurveysApi extends runtime.BaseAPI {

    /**
     */
    async surveysControllerAddNoteRaw(requestParameters: SurveysControllerAddNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerAddNote.');
        }

        if (requestParameters.surveyAddNoteRequestDTO === null || requestParameters.surveyAddNoteRequestDTO === undefined) {
            throw new runtime.RequiredError('surveyAddNoteRequestDTO','Required parameter requestParameters.surveyAddNoteRequestDTO was null or undefined when calling surveysControllerAddNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/note`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyAddNoteRequestDTOToJSON(requestParameters.surveyAddNoteRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerAddNote(requestParameters: SurveysControllerAddNoteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerAddNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerGetChildSurveysRaw(requestParameters: SurveysControllerGetChildSurveysRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SurveyChildResponseDTO>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerGetChildSurveys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/children`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyChildResponseDTOFromJSON));
    }

    /**
     */
    async surveysControllerGetChildSurveys(requestParameters: SurveysControllerGetChildSurveysRequest, initOverrides?: RequestInit): Promise<Array<SurveyChildResponseDTO>> {
        const response = await this.surveysControllerGetChildSurveysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerGetCountsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SurveyCountsResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/counts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyCountsResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async surveysControllerGetCounts(initOverrides?: RequestInit): Promise<SurveyCountsResponseDTO> {
        const response = await this.surveysControllerGetCountsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerGetPrefilledEmailsRaw(requestParameters: SurveysControllerGetPrefilledEmailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SurveyPrefilledEmailResponseDTO>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerGetPrefilledEmails.');
        }

        if (requestParameters.stepId === null || requestParameters.stepId === undefined) {
            throw new runtime.RequiredError('stepId','Required parameter requestParameters.stepId was null or undefined when calling surveysControllerGetPrefilledEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.optionIds) {
            queryParameters['optionIds'] = requestParameters.optionIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/solution-steps/{stepId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"stepId"}}`, encodeURIComponent(String(requestParameters.stepId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyPrefilledEmailResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async surveysControllerGetPrefilledEmails(requestParameters: SurveysControllerGetPrefilledEmailsRequest, initOverrides?: RequestInit): Promise<SurveyPrefilledEmailResponseDTO> {
        const response = await this.surveysControllerGetPrefilledEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerGetSurveyRaw(requestParameters: SurveysControllerGetSurveyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SurveyDetailResponseDTO>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerGetSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyDetailResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async surveysControllerGetSurvey(requestParameters: SurveysControllerGetSurveyRequest, initOverrides?: RequestInit): Promise<SurveyDetailResponseDTO> {
        const response = await this.surveysControllerGetSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerGetSurveyFiltersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SurveyFiltersResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFiltersResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async surveysControllerGetSurveyFilters(initOverrides?: RequestInit): Promise<SurveyFiltersResponseDTO> {
        const response = await this.surveysControllerGetSurveyFiltersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerGetSurveysRaw(requestParameters: SurveysControllerGetSurveysRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SurveyPaginatedResponseDTO>> {
        const queryParameters: any = {};

        if (requestParameters.operatorId !== undefined) {
            queryParameters['operatorId'] = requestParameters.operatorId;
        }

        if (requestParameters.statusId !== undefined) {
            queryParameters['statusId'] = requestParameters.statusId;
        }

        if (requestParameters.extTag !== undefined) {
            queryParameters['extTag'] = requestParameters.extTag;
        }

        if (requestParameters.priority !== undefined) {
            queryParameters['priority'] = requestParameters.priority;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.regNum !== undefined) {
            queryParameters['regNum'] = requestParameters.regNum;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyPaginatedResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async surveysControllerGetSurveys(requestParameters: SurveysControllerGetSurveysRequest = {}, initOverrides?: RequestInit): Promise<SurveyPaginatedResponseDTO> {
        const response = await this.surveysControllerGetSurveysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerGetSurveysStepsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SurveyStepsResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/solution-steps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyStepsResponseDTOFromJSON));
    }

    /**
     */
    async surveysControllerGetSurveysSteps(initOverrides?: RequestInit): Promise<Array<SurveyStepsResponseDTO>> {
        const response = await this.surveysControllerGetSurveysStepsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerPatchSurveyRaw(requestParameters: SurveysControllerPatchSurveyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SurveyResponseDTO>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerPatchSurvey.');
        }

        if (requestParameters.surveyPatchRequestDTO === null || requestParameters.surveyPatchRequestDTO === undefined) {
            throw new runtime.RequiredError('surveyPatchRequestDTO','Required parameter requestParameters.surveyPatchRequestDTO was null or undefined when calling surveysControllerPatchSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyPatchRequestDTOToJSON(requestParameters.surveyPatchRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async surveysControllerPatchSurvey(requestParameters: SurveysControllerPatchSurveyRequest, initOverrides?: RequestInit): Promise<SurveyResponseDTO> {
        const response = await this.surveysControllerPatchSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerReassignSurveyRaw(requestParameters: SurveysControllerReassignSurveyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerReassignSurvey.');
        }

        if (requestParameters.surveyReassignUserRequestDTO === null || requestParameters.surveyReassignUserRequestDTO === undefined) {
            throw new runtime.RequiredError('surveyReassignUserRequestDTO','Required parameter requestParameters.surveyReassignUserRequestDTO was null or undefined when calling surveysControllerReassignSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/user`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyReassignUserRequestDTOToJSON(requestParameters.surveyReassignUserRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerReassignSurvey(requestParameters: SurveysControllerReassignSurveyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerReassignSurveyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerSendPrefilledEmailsRaw(requestParameters: SurveysControllerSendPrefilledEmailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerSendPrefilledEmails.');
        }

        if (requestParameters.stepId === null || requestParameters.stepId === undefined) {
            throw new runtime.RequiredError('stepId','Required parameter requestParameters.stepId was null or undefined when calling surveysControllerSendPrefilledEmails.');
        }

        if (requestParameters.surveyPrefilledEmailRequestDTO === null || requestParameters.surveyPrefilledEmailRequestDTO === undefined) {
            throw new runtime.RequiredError('surveyPrefilledEmailRequestDTO','Required parameter requestParameters.surveyPrefilledEmailRequestDTO was null or undefined when calling surveysControllerSendPrefilledEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/solution-steps/{stepId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"stepId"}}`, encodeURIComponent(String(requestParameters.stepId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyPrefilledEmailRequestDTOToJSON(requestParameters.surveyPrefilledEmailRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerSendPrefilledEmails(requestParameters: SurveysControllerSendPrefilledEmailsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerSendPrefilledEmailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerSendToDppRaw(requestParameters: SurveysControllerSendToDppRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerSendToDpp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/dpp`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerSendToDpp(requestParameters: SurveysControllerSendToDppRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerSendToDppRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerSendToMHMPRaw(requestParameters: SurveysControllerSendToMHMPRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerSendToMHMP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/mhmp`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerSendToMHMP(requestParameters: SurveysControllerSendToMHMPRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerSendToMHMPRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerSetStatusRaw(requestParameters: SurveysControllerSetStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerSetStatus.');
        }

        if (requestParameters.surveySetStateRequestDTO === null || requestParameters.surveySetStateRequestDTO === undefined) {
            throw new runtime.RequiredError('surveySetStateRequestDTO','Required parameter requestParameters.surveySetStateRequestDTO was null or undefined when calling surveysControllerSetStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/status`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveySetStateRequestDTOToJSON(requestParameters.surveySetStateRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerSetStatus(requestParameters: SurveysControllerSetStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerSetStatusRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerSplitSurveysRaw(requestParameters: SurveysControllerSplitSurveysRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SurveySplitResponseDTO>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerSplitSurveys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/split`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveySplitResponseDTOFromJSON));
    }

    /**
     */
    async surveysControllerSplitSurveys(requestParameters: SurveysControllerSplitSurveysRequest, initOverrides?: RequestInit): Promise<Array<SurveySplitResponseDTO>> {
        const response = await this.surveysControllerSplitSurveysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async surveysControllerUpdateGpsRaw(requestParameters: SurveysControllerUpdateGpsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerUpdateGps.');
        }

        if (requestParameters.surveyUpdateGpsRequestDTO === null || requestParameters.surveyUpdateGpsRequestDTO === undefined) {
            throw new runtime.RequiredError('surveyUpdateGpsRequestDTO','Required parameter requestParameters.surveyUpdateGpsRequestDTO was null or undefined when calling surveysControllerUpdateGps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/gps`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyUpdateGpsRequestDTOToJSON(requestParameters.surveyUpdateGpsRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async surveysControllerUpdateGps(requestParameters: SurveysControllerUpdateGpsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.surveysControllerUpdateGpsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async surveysControllerUploadFileToSurveyRaw(requestParameters: SurveysControllerUploadFileToSurveyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SurveyEmailAttachmentDTO>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling surveysControllerUploadFileToSurvey.');
        }

        if (requestParameters.files === null || requestParameters.files === undefined) {
            throw new runtime.RequiredError('files','Required parameter requestParameters.files was null or undefined when calling surveysControllerUploadFileToSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/admin/surveys/{surveyId}/file`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyEmailAttachmentDTOFromJSON));
    }

    /**
     */
    async surveysControllerUploadFileToSurvey(requestParameters: SurveysControllerUploadFileToSurveyRequest, initOverrides?: RequestInit): Promise<Array<SurveyEmailAttachmentDTO>> {
        const response = await this.surveysControllerUploadFileToSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SurveysControllerGetSurveysExtTagEnum {
    Dpp = 'dpp',
    Omluvenka = 'omluvenka',
    Urgence = 'urgence',
    Anonym = 'anonym'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveysControllerGetSurveysPriorityEnum {
    Urgent = 'urgent',
    High = 'high',
    Normal = 'normal'
}

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyResponseDTO,
    SurveyResponseDTOFromJSON,
    SurveyResponseDTOFromJSONTyped,
    SurveyResponseDTOToJSON,
} from './SurveyResponseDTO';

/**
 * 
 * @export
 * @interface SurveyPaginatedResponseDTO
 */
export interface SurveyPaginatedResponseDTO {
    /**
     * 
     * @type {Array<SurveyResponseDTO>}
     * @memberof SurveyPaginatedResponseDTO
     */
    data: Array<SurveyResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof SurveyPaginatedResponseDTO
     */
    total: number;
}

export function SurveyPaginatedResponseDTOFromJSON(json: any): SurveyPaginatedResponseDTO {
    return SurveyPaginatedResponseDTOFromJSONTyped(json, false);
}

export function SurveyPaginatedResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyPaginatedResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(SurveyResponseDTOFromJSON)),
        'total': json['total'],
    };
}

export function SurveyPaginatedResponseDTOToJSON(value?: SurveyPaginatedResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(SurveyResponseDTOToJSON)),
        'total': value.total,
    };
}


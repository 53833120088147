/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyStatusHistoryDTO
 */
export interface SurveyStatusHistoryDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyStatusHistoryDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusHistoryDTO
     */
    surveyId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusHistoryDTO
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusHistoryDTO
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyStatusHistoryDTO
     */
    statusId: number | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyStatusHistoryDTO
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusHistoryDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatusHistoryDTO
     */
    updatedAt: string;
}

export function SurveyStatusHistoryDTOFromJSON(json: any): SurveyStatusHistoryDTO {
    return SurveyStatusHistoryDTOFromJSONTyped(json, false);
}

export function SurveyStatusHistoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyStatusHistoryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'surveyId': json['survey_id'],
        'message': json['message'],
        'type': json['type'],
        'statusId': json['status_id'],
        'userId': json['user_id'],
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function SurveyStatusHistoryDTOToJSON(value?: SurveyStatusHistoryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'survey_id': value.surveyId,
        'message': value.message,
        'type': value.type,
        'status_id': value.statusId,
        'user_id': value.userId,
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRequestDTO
 */
export interface UpdateUserRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestDTO
     */
    role?: UpdateUserRequestDTORoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestDTO
     */
    assignAmount?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequestDTO
     */
    maxSurveys?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestDTO
     */
    assignBalance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestDTO
     */
    setHolidays?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateUserRequestDTORoleEnum {
    Guest = 'guest',
    Operator = 'operator',
    ChiefOperator = 'chief_operator',
    Admin = 'admin',
    Deactivated = 'deactivated'
}

export function UpdateUserRequestDTOFromJSON(json: any): UpdateUserRequestDTO {
    return UpdateUserRequestDTOFromJSONTyped(json, false);
}

export function UpdateUserRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'assignAmount': !exists(json, 'assign_amount') ? undefined : json['assign_amount'],
        'maxSurveys': !exists(json, 'max_surveys') ? undefined : json['max_surveys'],
        'assignBalance': !exists(json, 'assign_balance') ? undefined : json['assign_balance'],
        'setHolidays': !exists(json, 'set_holidays') ? undefined : json['set_holidays'],
    };
}

export function UpdateUserRequestDTOToJSON(value?: UpdateUserRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'surname': value.surname,
        'role': value.role,
        'assign_amount': value.assignAmount,
        'max_surveys': value.maxSurveys,
        'assign_balance': value.assignBalance,
        'set_holidays': value.setHolidays,
    };
}


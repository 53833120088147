import { FC, Fragment } from 'react';
import StyledHr from '../../ui/StyledHr/StyledHr';
import StyledLine from '../../ui/StyledLine/StyledLine';

interface ISurveyStatusHistoryItem {
  updated_at: string;
  message: string;
  i: number;
  arrLenght: number;
}

const SurveyStatusHistoryItem: FC<ISurveyStatusHistoryItem> = ({
  updated_at,
  message,
  i,
  arrLenght,
}): JSX.Element => {
  return (
    <Fragment>
      <StyledLine
        header="Datum změny:"
        data={updated_at}
        className="mt-1"
        style={{ backgroundColor: '#24a3' }}
      />
      <StyledLine header="Status:" data={message} />
      {arrLenght > 1 && i !== arrLenght - 1 && <StyledHr />}
    </Fragment>
  );
};

export default SurveyStatusHistoryItem;

import React from 'react';
import { Alert } from 'react-bootstrap';

export default function OperatorEmpty() {
  return (
    <Alert variant="info">
      <Alert.Heading>Nastavení operátora</Alert.Heading>
      <p>Zde vidíte statistiky operátora a můžete k němu nastavit rozdělování podnětů.</p>
      <hr />
      <p className="mb-0">Zvolte položku v levém menu.</p>
    </Alert>
  );
}

import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import AppContext from '../../../../store/appContext';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import debounce from 'lodash/debounce';
import { InputStyledBasic } from '../../InputStyledBasic/index';

const pragueBounds = {
  south: 49.912258,
  west: 14.175487,
  north: 50.199694,
  east: 14.744029,
};

const AutoComp = () => {
  const { LatLng, setLatLng } = useContext(AppContext);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<string | undefined>('');

  const getNewValueFromMarker = useCallback(async (LatLng) => {
    try {
      const result = await geocodeByLatLng(LatLng);
      setAutocompleteValue(result[0].formatted_address);
    } catch (error: any) {
      console.error();
    }
  }, []);

  const onLoad = (autocomplete: google.maps.places.Autocomplete | null) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setAutocompleteValue(place.formatted_address);
      setLatLng(place.geometry?.location?.toJSON());
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const debouncedOnPlaceChanged = debounce(onPlaceChanged, 500);

  useEffect(() => {
    !LatLng && setAutocompleteValue('');
    if (LatLng) {
      getNewValueFromMarker(LatLng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LatLng]);

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={debouncedOnPlaceChanged}
      fields={['geometry.location', 'formatted_address']}
      restrictions={{ country: 'cz' }}
      bounds={pragueBounds}
    >
      <InputStyledBasic
        label="Adresa"
        type="text"
        mobVertical
        id="autocomplete"
        name="autocomplete"
        placeholder="Zadejte lokaci"
        value={autocompleteValue}
        onChange={(event) => {
          event.preventDefault();
          setAutocompleteValue(event.target.value);
        }}
      />
    </Autocomplete>
  );
};

export default memo(AutoComp);

import { createContext, useCallback, useContext, useState } from 'react';

import {
  IAppCtx,
  IChildren,
  IDetail,
  IFilters,
  ISurveys,
  ISurChild,
  IWhoAmI,
  IFiles,
} from '../interfaces/interfaces';
import { IUnpaired } from '../components/views/unpaired';
import AxiosContext from './axiosContext';
import { AuthContext } from './authContext';
import toast from 'react-hot-toast';
import ApiContext from './apiContext';

const AppContext = createContext<IAppCtx>({
  LatLng: undefined,
  setLatLng: () => {},
  isLoading: false,
  setIsLoading: () => {},
  error: null,
  setError: () => {},
  showModalHQ: { show: false, type: '', regnum: '', survey_id: '' },
  setShowModalHQ: () => {},
  limit: 100,
  getSurveys: () => {},
  surveys: undefined,
  setSurveys: () => [],
  detail: undefined,
  getSurveyById: () => {},
  totalSurCount: undefined,
  setTotalSurCount: () => {},
  patchSurvey: () => Promise.reject(),
  getFilters: () => {},
  filters: undefined,
  oper: '',
  setOper: () => {},
  getUnpairedEmails: (limit: number, offset: number) => {},
  unpaired: undefined,
  pairEmailToSurvey: (email_id: number, survey_id: string) => {},
  getChildren: (survey_id: string) => {},
  surChildren: undefined,
  getWhoAmI: () => {},
  whoAmI: undefined,
  filesToShow: [],
  setFilesToShow: () => [],
  mapLatLngErrors: {},
  setMapLatLngErrors: () => {},
  unassigned: false,
  setUnassigned: () => {},
});

export const AppContextProvider = ({ children }: IChildren) => {
  const httpClient = useContext(AxiosContext);
  const { token } = useContext(AuthContext);
  const { SurveysApi } = useContext(ApiContext);

  const [LatLng, setLatLng] = useState<google.maps.LatLngLiteral | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showModalHQ, setShowModalHQ] = useState<{
    show: boolean;
    type: string;
    regnum?: string;
    survey_id?: string;
  }>({
    show: false,
    type: '',
    regnum: '',
    survey_id: '',
  });
  const [surveys, setSurveys] = useState<ISurveys[] | undefined>();
  const [detail, setDetail] = useState<IDetail | undefined>();
  const [totalSurCount, setTotalSurCount] = useState<number>();
  const [filters, setFilters] = useState<IFilters | undefined>();
  const [oper, setOper] = useState<string>('');
  const [unpaired, setUnpaired] = useState<IUnpaired | undefined>();
  const [surChildren, setSurChildren] = useState<ISurChild[] | undefined>();
  const [whoAmI, setWhoAmI] = useState<IWhoAmI | undefined>();
  const [filesToShow, setFilesToShow] = useState<IFiles[] | undefined>();
  const [mapLatLngErrors, setMapLatLngErrors] = useState<{ [key: string]: string }>({});
  const [unassigned, setUnassigned] = useState<boolean>(false);

  // pagination const and functions
  const limit = 100;

  //get surveys
  const getSurveys = useCallback(
    async (limit, offset, priority?, regNum?, status_id?, extTag?, email?, operatorId?) => {
      setIsLoading(true);
      setError(null);
      const priorityPar = priority ? `&priority=${priority}` : '';
      const regnumPar = regNum ? `&regNum=${regNum}` : '';
      const statusPar = status_id ? `&statusId=${status_id}` : '';
      const extTagPar = extTag ? `&extTag=${extTag}` : '';
      const emailPar = email ? `&email=${email}` : '';
      const operatorPar = operatorId ? `operatorId=${operatorId}` : '';
      const url = `/surveys?${operatorPar}${statusPar}${extTagPar}${priorityPar}${emailPar}${regnumPar}&limit=${limit}&offset=${offset}`;
      try {
        const response = await httpClient.get(url);
        const unassignedSurveys = response.data.data.filter(
          (sur: ISurveys) => sur.survey_lock_user_username === null
        );
        setSurveys(() => (unassigned ? unassignedSurveys : response.data.data));
        setTotalSurCount(() => (unassigned ? unassignedSurveys.length : response.data.total));
      } catch (error: any) {
        toast.error(`Chyba: ${error.response.data.message}`);
        setError(() => error.message);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, unassigned]
  );

  //get survey by Id
  const getSurveyById = useCallback(
    async (id) => {
      setIsLoading(true);
      setError(null);
      setDetail(undefined);
      try {
        const response = await httpClient.get(`/surveys/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setDetail(() => response.data);
        if (response.data.child_surveys) {
          getChildren(response.data.id);
        } else setSurChildren([]);
      } catch (error: any) {
        toast.error(`Chyba: ${error.response.data.message}`);
        setError(() => error.message);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const patchSurvey = useCallback<IAppCtx['patchSurvey']>(
    async (id, data) => {
      try {
        const res = await SurveysApi.surveysControllerPatchSurvey({
          surveyId: id,
          surveyPatchRequestDTO: {
            description: data.description,
          },
        });

        setDetail((v) => {
          if (!v) {
            return v;
          }

          return {
            ...v,
            description: res.description,
          };
        });
      } catch (e: unknown) {
        if (e instanceof Response) {
          if (e.status === 400) {
            toast.error(`Chyba: Nepodařilo se upravit podnět`);
          } else if (e.status === 404) {
            toast.error(`Chyba: Požadovaný podnět nebyl nalezen`);
          } else {
            toast.error(`Chyba: Při úpravě podnětu došlo k neznámé chybě`);
          }
        } else {
          toast.error(`Chyba: Při úpravě podnětu došlo k neznámé chybě`);
        }

        throw e;
      }
    },
    [SurveysApi]
  );

  //get filters
  const getFilters = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/surveys/filters`);
      setFilters(() => response.data);
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  // get children
  const getChildren = async (survey_id: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/surveys/${survey_id}/children`);
      setSurChildren(response.data);
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  //get unpaired
  const getUnpairedEmails = async (limit: number, offset: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(
        `/surveys/unpaired-emails?limit=${limit}&offset=${offset}`
      );
      setUnpaired(response.data);
      setTotalSurCount(response.data.total);
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };
  //pair email to survey
  const pairEmailToSurvey = async (email_id: number, survey_id: string) => {
    setIsLoading(true);
    setError(null);
    const jsonOutput = JSON.stringify({ id: email_id, surveyId: survey_id }, null, 2);
    try {
      const response = await httpClient.post(`/surveys/unpaired-emails`, jsonOutput, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success(`Spárováno`);
      return response.data;
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
  };

  // who am I and what is my role
  const getWhoAmI = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/users/me`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setWhoAmI(response.data);
    } catch (error: any) {
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // provide a value
  return (
    <AppContext.Provider
      value={{
        LatLng,
        setLatLng,
        isLoading,
        setIsLoading,
        error,
        setError,
        showModalHQ,
        setShowModalHQ,
        limit,
        getSurveys,
        surveys,
        setSurveys,
        detail,
        getSurveyById,
        totalSurCount,
        setTotalSurCount,
        patchSurvey,
        getFilters,
        filters,
        oper,
        setOper,
        getUnpairedEmails,
        unpaired,
        pairEmailToSurvey,
        getChildren,
        surChildren,
        getWhoAmI,
        whoAmI,
        filesToShow,
        setFilesToShow,
        mapLatLngErrors,
        setMapLatLngErrors,
        unassigned,
        setUnassigned,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;

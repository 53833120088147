import { KeyboardEvent, FocusEvent, useContext, useEffect, useState } from 'react';
import AppContext from 'src/store/appContext';
import { number, object } from 'yup';
import { InputStyledBasic } from '../../InputStyledBasic';
import styled from 'styled-components';
import { ChangeEvent } from 'react';
import StyledErrorMessage from '../../StyledErrorMessage/StyledErrorMessage';

// validace pro širší Prahu
// const validationSchema = object().shape({
//   lat: number()
//     .min(49.912258, 'Zadaná hodnota latitude neodpovídá souřadnicím širší Prahy')
//     .max(50.199694, 'Zadaná hodnota latitude neodpovídá souřadnicím širší Prahy')
//     .required('Hodnota musí být vyplněna'),
//   lng: number()
//     .min(14.175487, 'Zadaná hodnota longitude neodpovídá souřadnicím širší Prahy')
//     .max(14.744029, 'Zadaná hodnota longitude neodpovídá souřadnicím širší Prahy')
//     .required('Hodnota musí být vyplněna'),
// });

// validace pro svět
const validationSchema = object().shape({
  lat: number()
    .min(-85, 'Zadaná hodnota latitude musí být minimálně -85')
    .max(85, 'Zadaná hodnota latitude musí být maximálně 85')
    .required('Hodnota musí být vyplněna'),
  lng: number()
    .min(-180, 'Zadaná hodnota longitude musí být minimálně -180')
    .max(180, 'Zadaná hodnota longitude musí být maximálně 180')
    .required('Hodnota musí být vyplněna'),
});

const GpsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  justify-content: space-between;
  align-items: center;
  // remove arrows in number input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledButton = styled.button`
  height: 38px;
  padding: 2px 12px;
  min-width: fit-content;
  margin: 0 !important;
`;

const GpsInput = () => {
  const { LatLng, setLatLng, mapLatLngErrors, setMapLatLngErrors } = useContext(AppContext);
  const [gpsValue, setgpsValue] = useState<{ lat: number | undefined; lng: number | undefined }>({
    lat: LatLng?.lat,
    lng: LatLng?.lng,
  });

  const errKeys = Object.keys(mapLatLngErrors);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value &&
      setgpsValue({ ...gpsValue, [e.currentTarget.name]: Number(e.currentTarget.value) });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    validationSchema
      .validate(gpsValue, { abortEarly: false })
      .then(() => {
        setLatLng({ lat: gpsValue.lat, lng: gpsValue.lng } as google.maps.LatLngLiteral);
      })
      .catch((err: { errors: string[]; inner: { path: string; message: string }[] }) => {
        const newErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setMapLatLngErrors(newErrors);
      });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      e.preventDefault();
    }
  };

  const disableMouseWheel = (e: FocusEvent) => {
    e.target?.addEventListener('wheel', function (e) {
      e.preventDefault();
    });
  };

  useEffect(() => {
    LatLng && setgpsValue(LatLng);
    validationSchema
      .validate(LatLng, { abortEarly: false })
      .then(() => {
        setMapLatLngErrors({});
      })
      .catch((err: { errors: string[]; inner: { path: string; message: string }[] }) => {
        const newErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setMapLatLngErrors(newErrors);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LatLng]);

  return (
    <>
      <GpsContainer>
        <InputStyledBasic
          label="Lat"
          name="lat"
          value={gpsValue?.lat}
          id="lat"
          type="number"
          onChange={handleChange}
          step="0.000001"
          onKeyDown={handleKeyDown}
          onFocus={disableMouseWheel}
        />
        <InputStyledBasic
          label="Lng"
          value={gpsValue?.lng}
          name="lng"
          id="lng"
          type="number"
          onChange={handleChange}
          step="0.000001"
          onKeyDown={handleKeyDown}
          onFocus={disableMouseWheel}
        />
        <StyledButton className="ms-2 btn btn-outline-dark" type="button" onClick={handleSubmit}>
          Změnit GPS
        </StyledButton>
      </GpsContainer>
      {mapLatLngErrors &&
        errKeys &&
        errKeys.map((eKey) => {
          return <StyledErrorMessage key={eKey}>{`${mapLatLngErrors[eKey]}`}</StyledErrorMessage>;
        })}
    </>
  );
};

export default GpsInput;

import React from 'react';
import { Alert } from 'react-bootstrap';

export default function InstitutionEmpty() {
  return (
    <Alert variant="info">
      <Alert.Heading>Nastavení institucí</Alert.Heading>
      <p>Zde můžete nastavit instituce a jejích kontakty.</p>
      <hr />
      <p className="mb-0">Zvolte položku v levém menu.</p>
    </Alert>
  );
}

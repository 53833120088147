import { useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import { object } from 'yup';
import { Formik, Form } from 'formik';
import { Card, Container } from 'react-bootstrap';
import InputFieldLabelBack from '../../ui/InputFieldLabelBack/InputFieldLabelBack';
import { checkAtLeatstOneSheme } from '../../ui/_Utils/yup_validations';
import { chooseRadioOptionSheme } from '../../ui/_Utils/yup_validations';

interface Values {
  checked: string[];
  answer: string;
}

const validationSchema = (action: string) => {
  switch (action) {
    case 'checkbox':
      return checkAtLeatstOneSheme;

    case 'radio':
      return chooseRadioOptionSheme;

    default:
      return object().shape({});
  }
};
const SolutionSelections = () => {
  const { detail } = useContext(AppContext);
  const { getSurveyByStepId, setSolutionAction, setSolutionStepName, stepIds } =
    useContext(SolutionContext);
  const [showStep, setShowStep] = useState(null);

  const showStepHandler = (stepId: any) => {
    if (showStep === stepId) {
      setShowStep(null);
    } else {
      setShowStep(stepId);
    }
  };
  const initialValues: Values = {
    checked: [],
    answer: '',
  };
  return (
    <>
      {stepIds &&
        stepIds.map((stepId) => {
          if (
            stepId.action_title === 'replyToReporter' ||
            stepId.action_title === 'additionalInfo'
          ) {
            return (
              <Formik
                key={stepId.order.toString()}
                initialValues={initialValues}
                validationSchema={validationSchema(stepId.action)}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                  stepId.substeps.forEach((substep) => {
                    setSolutionStepName(stepId!.name);
                    actions.setSubmitting(true);
                    if (substep.action === 'checkbox') {
                      let options = ``;
                      values.checked.forEach((element) => {
                        options += `optionIds=${element}&`;
                      });
                      getSurveyByStepId(stepId?.id!, detail!, options, false);
                    }
                    if (substep.action === 'radio') {
                      let options = `optionIds=${values.answer}`;
                      getSurveyByStepId(stepId?.id!, detail!, options, false);
                    }
                    setTimeout(() => {
                      setSolutionAction('viewMail');
                    }, 300);
                    actions.setSubmitting(false);
                    actions.resetForm();
                    setShowStep(null);
                  });
                }}
              >
                {({ values, errors, isSubmitting }) => (
                  <Form className="text-dark">
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Title
                          key={stepId.order}
                          onClick={() => showStepHandler(stepId.order)}
                          style={{
                            cursor: 'pointer',
                            marginBottom: showStep === stepId.order ? '.5rem' : '0',
                          }}
                        >
                          {stepId && stepId.order}. {stepId.order && stepId.name}
                        </Card.Title>
                        {showStep === stepId.order && (
                          <Container className="mt-3" id="step-groups">
                            <div role="group">
                              {stepId &&
                                stepId.substeps.map((input) => {
                                  if (input.action === 'checkbox') {
                                    return (
                                      <div
                                        className="mb-1"
                                        key={input.id}
                                        aria-labelledby="checkbox-group"
                                      >
                                        <InputFieldLabelBack
                                          name="checked"
                                          value={input.id as unknown as string}
                                          label={input.name}
                                          type={input.action}
                                          checked={values.checked.includes(input.id.toString())}
                                        />
                                      </div>
                                    );
                                  }
                                  if (input.action === 'radio') {
                                    return (
                                      <div
                                        className="mb-1"
                                        key={input.id}
                                        role="group"
                                        aria-labelledby="answer-group"
                                      >
                                        <InputFieldLabelBack
                                          name="answer"
                                          id={input.id as unknown as string}
                                          type={input.action}
                                          label={input.name}
                                          value={input.id as unknown as string}
                                          checked={values.answer === input.id.toString()}
                                        />
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                            </div>
                            <button
                              className="btn btn-outline-dark mt-3"
                              type="submit"
                              style={{ maxWidth: '8rem' }}
                              disabled={isSubmitting}
                            >
                              Odpovědět
                            </button>
                          </Container>
                        )}
                      </Card.Body>
                    </Card>
                  </Form>
                )}
              </Formik>
            );
          }
          return null;
        })}
    </>
  );
};
export default SolutionSelections;

import { FC } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { IFiles } from '../../../interfaces/interfaces';

interface ICarousel {
  files: IFiles[];
  handleDelete: (id: number) => void;
}

export const CarouselComponent: FC<ICarousel> = ({ files, handleDelete }) => {
  const items = files.map((file) => {
    return (
      <Carousel.Item key={file.id} interval={20000}>
        <img
          className="d-block mx-auto"
          style={{ maxHeight: '22rem', maxWidth: '100%' }}
          src={file.path}
          alt={file.id!.toString()}
          id={file.id!.toString()}
        />
        <div className='d-flex justify-content-center mt-2'>
          <Button className='d-flex' variant="danger" type="button" onClick={() => handleDelete(file.id!)}>
            Smazat
          </Button>
        </div>
      </Carousel.Item>
    );
  });
  return <Carousel variant="dark">{items}</Carousel>;
};

export default CarouselComponent;

import styled from 'styled-components';
import { Row } from 'react-bootstrap';

const FilterRow = styled(Row)`
  background-color: #eee;
  border-radius: 0.25rem;
  padding-top: 0.8rem;
  padding-bottom: 0.3rem;
`;

export default FilterRow;

import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const SurveyStatusHistory = styled(Card)`
  padding: 0.2rem;
  background-color: #deecfc33;
  margin-bottom: 1rem;
`;

export default SurveyStatusHistory;

import { createContext, FC, useCallback, useContext } from 'react';
import axios from 'axios';

import { configuration } from '../configuration';
import AppContext from './appContext';
import useToken from '../hooks/useToken';
import toast from 'react-hot-toast';

interface AuthInterface {
  REDIRECT_AFTER_LOGIN: string;
  LOGIN_ROUTE: string;
  token: string | null;
  setToken: (token: string | null) => void;
  logIn: (username: string, password: string) => void;
  logOut: () => void;
  forgotPass: (username: string) => void;
}

const AuthContext = createContext<AuthInterface>({
  REDIRECT_AFTER_LOGIN: '',
  LOGIN_ROUTE: '',
  token: null,
  setToken: () => null,
  logIn: () => null,
  logOut: () => null,
  forgotPass: () => null,
});

const AuthContextProvider: FC<{}> = ({ children }) => {
  const { setError, setIsLoading } = useContext(AppContext);
  const { token, setToken } = useToken();

  const REDIRECT_AFTER_LOGIN = '/surveys';
  const LOGIN_ROUTE = '/';

  const logIn = useCallback(async (username: string, password: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${configuration.API_URL}/auth/login`,
        { username: username, password: password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setToken(response.data.token);
      toast.success(`Vítejte uživateli ${username}. Přeji hezký den.`);
    } catch (error: any) {
      // console.error(error.response);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.response.data.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = async () => {
    setToken(null);
    toast.success(`Nashledanou, přeji hezký zbytek dne.`);
  };

  const forgotPass = useCallback(async (username: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${configuration.API_URL}/auth/forgot-password`,
        { username: username },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success(`Na adresu ${username} jsem odeslalo zprávu.`);
      return response.data;
    } catch (error: any) {
      // console.error(error.response);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.response.data.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        REDIRECT_AFTER_LOGIN,
        LOGIN_ROUTE,
        token,
        setToken,
        logIn,
        logOut,
        forgotPass,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };

import styled from 'styled-components';

const StyledText = styled.div`
  margin-bottom: 0.1rem;
  background-color: #24a1;
  padding: 0.1rem 0.5rem;
  border-radius: 0.2rem;
`;

export default StyledText;

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyFileResponseDTO,
    SurveyFileResponseDTOFromJSON,
    SurveyFileResponseDTOFromJSONTyped,
    SurveyFileResponseDTOToJSON,
} from './SurveyFileResponseDTO';
import {
    SurveyMailHistoryDTO,
    SurveyMailHistoryDTOFromJSON,
    SurveyMailHistoryDTOFromJSONTyped,
    SurveyMailHistoryDTOToJSON,
} from './SurveyMailHistoryDTO';
import {
    SurveyNearestSurveysDTO,
    SurveyNearestSurveysDTOFromJSON,
    SurveyNearestSurveysDTOFromJSONTyped,
    SurveyNearestSurveysDTOToJSON,
} from './SurveyNearestSurveysDTO';
import {
    SurveyStatusHistoryDTO,
    SurveyStatusHistoryDTOFromJSON,
    SurveyStatusHistoryDTOFromJSONTyped,
    SurveyStatusHistoryDTOToJSON,
} from './SurveyStatusHistoryDTO';

/**
 * 
 * @export
 * @interface SurveyDetailResponseDTO
 */
export interface SurveyDetailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    subcategoryName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    regNum: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    gps: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    datetime: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    surveyId: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyDetailResponseDTO
     */
    statusId: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    statusName: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyDetailResponseDTO
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyDetailResponseDTO
     */
    dppSent: SurveyDetailResponseDTODppSentEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    surveyLock: string | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyDetailResponseDTO
     */
    surveyLockUserId: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyDetailResponseDTO
     */
    extApology: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyDetailResponseDTO
     */
    extUrgency: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyDetailResponseDTO
     */
    extPraise: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyDetailResponseDTO
     */
    extInstitution: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyDetailResponseDTO
     */
    extAnonym: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    adminNotes: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    _export: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    extTags: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    extInstitutionExtraInfo: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyDetailResponseDTO
     */
    childSurveys: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    descAdded: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    gpsAdded: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    parcelAdded: string | null;
    /**
     * 
     * @type {Array<SurveyFileResponseDTO>}
     * @memberof SurveyDetailResponseDTO
     */
    files: Array<SurveyFileResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyDetailResponseDTO
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<SurveyNearestSurveysDTO>}
     * @memberof SurveyDetailResponseDTO
     */
    nearestSurveys: Array<SurveyNearestSurveysDTO>;
    /**
     * 
     * @type {Array<SurveyStatusHistoryDTO>}
     * @memberof SurveyDetailResponseDTO
     */
    statusHistory: Array<SurveyStatusHistoryDTO>;
    /**
     * 
     * @type {Array<SurveyMailHistoryDTO>}
     * @memberof SurveyDetailResponseDTO
     */
    mailHistory: Array<SurveyMailHistoryDTO>;
}

/**
* @export
* @enum {string}
*/
export enum SurveyDetailResponseDTODppSentEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function SurveyDetailResponseDTOFromJSON(json: any): SurveyDetailResponseDTO {
    return SurveyDetailResponseDTOFromJSONTyped(json, false);
}

export function SurveyDetailResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyDetailResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'categoryName': json['category_name'],
        'subcategoryName': json['subcategory_name'],
        'regNum': json['reg_num'],
        'description': json['description'],
        'email': json['email'],
        'gps': json['gps'],
        'datetime': json['datetime'],
        'surveyId': json['survey_id'],
        'statusId': json['status_id'],
        'statusName': json['status_name'],
        'priority': json['priority'],
        'dppSent': json['dpp_sent'],
        'surveyLock': json['survey_lock'],
        'surveyLockUserId': json['survey_lock_user_id'],
        'extApology': json['ext_apology'],
        'extUrgency': json['ext_urgency'],
        'extPraise': json['ext_praise'],
        'extInstitution': json['ext_institution'],
        'extAnonym': json['ext_anonym'],
        'adminNotes': json['admin_notes'],
        '_export': json['export'],
        'extTags': json['ext_tags'],
        'extInstitutionExtraInfo': json['ext_institution_extra_info'],
        'childSurveys': json['child_surveys'],
        'descAdded': json['desc_added'],
        'gpsAdded': json['gps_added'],
        'parcelAdded': json['parcel_added'],
        'files': ((json['files'] as Array<any>).map(SurveyFileResponseDTOFromJSON)),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
        'nearestSurveys': ((json['nearest_surveys'] as Array<any>).map(SurveyNearestSurveysDTOFromJSON)),
        'statusHistory': ((json['status_history'] as Array<any>).map(SurveyStatusHistoryDTOFromJSON)),
        'mailHistory': ((json['mail_history'] as Array<any>).map(SurveyMailHistoryDTOFromJSON)),
    };
}

export function SurveyDetailResponseDTOToJSON(value?: SurveyDetailResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category_name': value.categoryName,
        'subcategory_name': value.subcategoryName,
        'reg_num': value.regNum,
        'description': value.description,
        'email': value.email,
        'gps': value.gps,
        'datetime': value.datetime,
        'survey_id': value.surveyId,
        'status_id': value.statusId,
        'status_name': value.statusName,
        'priority': value.priority,
        'dpp_sent': value.dppSent,
        'survey_lock': value.surveyLock,
        'survey_lock_user_id': value.surveyLockUserId,
        'ext_apology': value.extApology,
        'ext_urgency': value.extUrgency,
        'ext_praise': value.extPraise,
        'ext_institution': value.extInstitution,
        'ext_anonym': value.extAnonym,
        'admin_notes': value.adminNotes,
        'export': value._export,
        'ext_tags': value.extTags,
        'ext_institution_extra_info': value.extInstitutionExtraInfo,
        'child_surveys': value.childSurveys,
        'desc_added': value.descAdded,
        'gps_added': value.gpsAdded,
        'parcel_added': value.parcelAdded,
        'files': ((value.files as Array<any>).map(SurveyFileResponseDTOToJSON)),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
        'nearest_surveys': ((value.nearestSurveys as Array<any>).map(SurveyNearestSurveysDTOToJSON)),
        'status_history': ((value.statusHistory as Array<any>).map(SurveyStatusHistoryDTOToJSON)),
        'mail_history': ((value.mailHistory as Array<any>).map(SurveyMailHistoryDTOToJSON)),
    };
}


import { FC, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../utils/dividerBlot'
import { Delta } from 'quill/core';
import { QuillDeltaToHtmlConverter }  from 'quill-delta-to-html';

export interface IMailEditorProps {
  initialText: string;
  readText: (text: string) => void;
}

const MailEditor: FC<IMailEditorProps> = ({ initialText, readText }): JSX.Element => {
  const quillRef = useRef<any>(null);

  const handleEditorChange = (content: string, delta: Delta, source: string, editor: any) => {
    const cfg = { encodeHtml: true, paragraphTag: 'p', multiLineParagraph: false, linkTarget: ''  }
    const deltaOps = editor.getContents();
    const converter = new QuillDeltaToHtmlConverter(deltaOps.ops, cfg);
    const htmlContent = converter.convert();
    readText(htmlContent);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const clipboard = editor.getModule('clipboard');
 
      clipboard.addMatcher('HR', function(node: any, delta: any) {
        return new Delta().insert('---------------------------------------\n');
      });

      const delta = editor.clipboard.convert(initialText);
      editor.setContents(delta, 'silent');
    }
  }, [initialText]);

  return (
    <ReactQuill
      ref={quillRef}
      defaultValue={initialText}
      onChange={handleEditorChange}
      modules={{
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          ['link'],
          [{ 'align': [] }],
        ],
      }}
      formats={[
        'header', 'font',
        'list', 'bullet',
        'bold', 'italic', 'underline',
        'link', 'image', 'align',
        'divider',
      ]}
      style={{ height: '500px' }}
    />
  );
};

export default MailEditor;
/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyCountsResponseDTO
 */
export interface SurveyCountsResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyCountsResponseDTO
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyCountsResponseDTO
     */
    received: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyCountsResponseDTO
     */
    _new: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyCountsResponseDTO
     */
    changed: number;
}

export function SurveyCountsResponseDTOFromJSON(json: any): SurveyCountsResponseDTO {
    return SurveyCountsResponseDTOFromJSONTyped(json, false);
}

export function SurveyCountsResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyCountsResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'received': json['received'],
        '_new': json['new'],
        'changed': json['changed'],
    };
}

export function SurveyCountsResponseDTOToJSON(value?: SurveyCountsResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'received': value.received,
        'new': value._new,
        'changed': value.changed,
    };
}


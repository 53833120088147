/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyEmailAttachmentDTO,
    SurveyEmailAttachmentDTOFromJSON,
    SurveyEmailAttachmentDTOFromJSONTyped,
    SurveyEmailAttachmentDTOToJSON,
} from './SurveyEmailAttachmentDTO';

/**
 * 
 * @export
 * @interface SurveyPrefilledEmailResponseDTO
 */
export interface SurveyPrefilledEmailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    cc: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    bcc: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    message: string;
    /**
     * 
     * @type {Array<SurveyEmailAttachmentDTO>}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    attachments: Array<SurveyEmailAttachmentDTO>;
    /**
     * 
     * @type {number}
     * @memberof SurveyPrefilledEmailResponseDTO
     */
    statusId: number;
}

export function SurveyPrefilledEmailResponseDTOFromJSON(json: any): SurveyPrefilledEmailResponseDTO {
    return SurveyPrefilledEmailResponseDTOFromJSONTyped(json, false);
}

export function SurveyPrefilledEmailResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyPrefilledEmailResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': json['header'],
        'cc': json['cc'],
        'bcc': json['bcc'],
        'subject': json['subject'],
        'message': json['message'],
        'attachments': ((json['attachments'] as Array<any>).map(SurveyEmailAttachmentDTOFromJSON)),
        'statusId': json['status_id'],
    };
}

export function SurveyPrefilledEmailResponseDTOToJSON(value?: SurveyPrefilledEmailResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': value.header,
        'cc': value.cc,
        'bcc': value.bcc,
        'subject': value.subject,
        'message': value.message,
        'attachments': ((value.attachments as Array<any>).map(SurveyEmailAttachmentDTOToJSON)),
        'status_id': value.statusId,
    };
}


import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const StyledCardLink = styled(Card.Link)`
  text-decoration: none;
  color: #3400a6;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export default StyledCardLink;

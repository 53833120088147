/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveySplitResponseDTO
 */
export interface SurveySplitResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveySplitResponseDTO
     */
    surveyId: string;
}

export function SurveySplitResponseDTOFromJSON(json: any): SurveySplitResponseDTO {
    return SurveySplitResponseDTOFromJSONTyped(json, false);
}

export function SurveySplitResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveySplitResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyId': json['survey_id'],
    };
}

export function SurveySplitResponseDTOToJSON(value?: SurveySplitResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'survey_id': value.surveyId,
    };
}


import { FC } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import styled from 'styled-components';

const Styled = styled(XCircle)`
  background-color: #fc2e2ef4;
  color: #000;
  position: absolute;
  top: -0.2rem;
  left: 0;
  font-size: 1.4rem;
  border-radius: 50%;
  text-shadow: #000 1px 1px 1px;
  box-shadow: #555c 1px 1px 2px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;

const StyledCloseBadge: FC<{ onClick: () => void }> = ({ onClick }) => {
  return <Styled onClick={onClick} />;
};

export default StyledCloseBadge;

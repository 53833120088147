import { FC, useContext } from 'react';
import AppContext from 'src/store/appContext';
import GoogleMap from '../Map/GoogleMap';

const AggregationMap: FC = () => {
  const { detail } = useContext(AppContext);

  return (
    <div>
      <GoogleMap nearests={detail?.nearest_surveys} />
    </div>
  );
};

export default AggregationMap;

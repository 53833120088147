import { useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import ButtonWrapper from '../../ui/ButtonWrapper/ButtonWrapper';
import SolutionSubstep from '../SolutionSubStep';

const SolutionStepButton = () => {
  const { detail, setShowModalHQ, setFilesToShow } = useContext(AppContext);
  const {
    getSurveyByStepId,
    changeStatus,
    treatSurvey,
    setSolutionStepName,
    setSolutionAction,
    addTagSolved,
    stepIds,
  } = useContext(SolutionContext);
  const [showSolutionSubstep, setShowSolutionSubstep] = useState(false);
  const [showStep, setShowStep] = useState<number | null>(null);

  const showStepHandler = (stepId: any) => {
    if (showStep === stepId) {
      setShowStep(null);
    } else {
      setShowStep(stepId);
    }
  };

  enum StepActionEnum {
    Email = 'email',
    Subtitle = 'subtitle',
    Link = 'link',
    Modal = 'modal',
  }

  enum SubstepActionEnum {
    SendToDpp = 'sendToDpp',
    SendToTSK = 'sendToTSK',
    ChangePosition = 'changePosition',
    SplitSurvey = 'splitSurvey',
    SetStatusSolved = 'setStatusSolved',
    SetStatusToBeFinalized = 'setStatusToBeFinalized',
    AlreadySolved = 'alreadySolved',
  }

  const SendToReporter = [
    'urgencyAdditionalInfo',
    'sendApology',
    'forwardReply',
    'solveWithoutInstitution',
  ];

  return (
    <>
      {stepIds &&
        stepIds.map((stepId) => {
          if (
            stepId.action_title !== 'replyToReporter' &&
            stepId.action_title !== 'additionalInfo'
          ) {
            return (
              <ButtonWrapper
                header={`${stepId?.order}. ${stepId?.name}`}
                showStep={showStep}
                onClick={() => {
                  showStepHandler(stepId.order);
                  setShowSolutionSubstep(false);
                }}
                key={stepId.order}
              >
                {stepId! &&
                  stepId?.substeps.map((substep, i) => {
                    return (
                      <div className="mt-1" key={substep.order}>
                        {showStep === stepId.order && (
                          <button
                            key={substep.order}
                            className="btn btn-outline-dark me-3 mb-3"
                            onClick={() => {
                              setSolutionStepName(substep.name);
                              setSolutionAction(null);

                              switch (substep.action) {
                                case StepActionEnum.Email:
                                  getSurveyByStepId(
                                    stepId?.id!,
                                    detail!,
                                    `optionIds=${substep.id!}`,
                                    SendToReporter.includes(substep.action_title) ? false : true
                                  );
                                  setShowStep(null);
                                  setTimeout(() => {
                                    setSolutionAction('viewMail');
                                  }, 300);
                                  break;

                                case StepActionEnum.Link:
                                  switch (substep.action_title) {
                                    case SubstepActionEnum.SendToDpp:
                                      setSolutionStepName(substep.name);
                                      treatSurvey(`/surveys/${detail?.id}/dpp`);
                                      break;
                                    case SubstepActionEnum.SendToTSK:
                                      setSolutionAction(SubstepActionEnum.SendToTSK);
                                      break;
                                    case SubstepActionEnum.ChangePosition:
                                      setShowModalHQ({ show: true, type: 'gps' });
                                      break;
                                    case SubstepActionEnum.SplitSurvey:
                                      treatSurvey(`/surveys/${detail?.id}/split`);
                                      break;
                                    case SubstepActionEnum.SetStatusSolved:
                                      changeStatus(detail?.id!, 'SOLVED');
                                      break;
                                    case SubstepActionEnum.SetStatusToBeFinalized:
                                      changeStatus(detail?.id!, 'TO_BE_FINALIZED');
                                      break;
                                    case SubstepActionEnum.AlreadySolved:
                                      addTagSolved(detail?.id!);
                                      break;
                                  }
                                  setShowStep(null);

                                  switch (substep.action_title) {
                                    case SubstepActionEnum.SplitSurvey:
                                    case SubstepActionEnum.SetStatusSolved:
                                    case SubstepActionEnum.SetStatusToBeFinalized:
                                    case SubstepActionEnum.AlreadySolved:
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 150);
                                      break;
                                  }
                                  break;

                                case StepActionEnum.Modal:
                                  setShowModalHQ({
                                    show: true,
                                    type: 'photos',
                                    regnum: detail?.reg_num,
                                    survey_id: detail?.id,
                                  });
                                  setFilesToShow(detail?.files);
                                  setShowStep(null);
                                  break;
                                case StepActionEnum.Subtitle:
                                  setShowSolutionSubstep(!showSolutionSubstep);

                                  break;
                              }
                            }}
                          >
                            {substep.name}
                          </button>
                        )}
                      </div>
                    );
                  })}
                {showSolutionSubstep && stepId.order === 6 && (
                  <SolutionSubstep stepId={stepId.substeps[2]} />
                )}
              </ButtonWrapper>
            );
          }

          return null;
        })}
    </>
  );
};
export default SolutionStepButton;

import { FC, Fragment, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './views/home';
import Surveys from './views/surveys';
import Priority from './views/priority';
import Statuses from './views/statuses';
import Status from './views/status';
import Tags from './views/tags';
import Tag from './views/tag';
import Detail from './views/detail';
import Det from './views/det';
import Unpaired from './views/unpaired';
import Institutions from './views/institutions';
import ForgotPass from './views/forgot';
import Email from './views/email';
import Ratings from './views/rating/list';
import RatingsAverageOffice from './views/rating/average-office';
import RatingsAverageQuestions from './views/rating/average-questions';
import RatingsQuestions from './views/questions';
import RatingsOrganizations from './views/organizations';
import Operator from './views/operator';
import Opid from './views/opid';
import Operators from './views/operators';
import OperatorDetail from './views/operators/detail';
import OperatorAdd from './views/operators/add';
import OperatorEmpty from './views/operators/empty';
import Admin from './views/admin';
import AdminEmpty from './views/admin/empty';
import AdminUsers from './views/admin/users';
import AdminApologies from './views/admin/apology';
import AdminTemplates from './views/admin/templates';

import { Title } from './title';
import ProtectedRouter from './protected-route';
import PublicRoute from './public-routes';
import InstitutionEmpty from './views/institutions/empty';
import InstitutionDetail from './views/institutions/detail';
import InstitutionAdd from './views/institutions/add';
import Profile from './views/profile';
import ResetPass from './views/reset-password';

interface ILink {
  link: string;
  title: string;
  component: JSX.Element;
  isPublic?: boolean;
}

interface IRouterLink extends ILink {
  subRoutes?: ILink[];
}

export const Router: FC = () => {
  const [links] = useState<IRouterLink[]>([
    {
      link: '/',
      title: '',
      component: <Home header="Změňte.to - admin" />,
      isPublic: true,
    },
    {
      link: '*',
      title: 'Oblivion',
      component: (
        <section style={{ padding: '1rem', textAlign: 'center', minHeight: '40vh' }}>
          <h3>There's nothing for you here!</h3>
        </section>
      ),
      isPublic: true,
    },
    {
      link: '/detail',
      title: 'Podnět',
      component: <Detail header="Změňte.to - podnět" />,
      subRoutes: [
        {
          link: ':id',
          title: 'Detail',
          component: <Det />,
        },
      ],
    },
    {
      link: '/email',
      title: 'Email',
      component: <Email header="Změňte.to - stěžovatel" />,
    },
    {
      link: '/institutions',
      title: 'Instituce',
      component: <Institutions header="Instituce" />,
      subRoutes: [
        {
          link: 'add',
          title: 'Add operator',
          component: <InstitutionAdd />,
        },
        {
          link: ':institutionId',
          title: 'Detail',
          component: <InstitutionDetail />,
        },
        {
          link: '',
          title: 'Index',
          component: <InstitutionEmpty />,
        },
      ],
    },
    {
      link: '/ratings',
      title: 'Ratings',
      component: <Ratings header="Hodnocení" />,
    },
    {
      link: '/ratings-offices',
      title: 'Office Ratings',
      component: <RatingsAverageOffice header="Hodnocení útvarů" />,
    },
    {
      link: '/ratings-questions',
      title: 'Question Ratings',
      component: <RatingsAverageQuestions header="Hodnocení dle otázek" />,
    },
    {
      link: '/organizations',
      title: 'Organizations',
      component: <RatingsOrganizations header="Nastavení organizací" />,
    },
    {
      link: '/questions',
      title: 'Question',
      component: <RatingsQuestions header="Nastavení otázek" />,
    },
    {
      link: '/operators',
      title: 'Operators',
      component: <Operators header="Operátoři" />,
      subRoutes: [
        {
          link: 'add',
          title: 'Add operator',
          component: <OperatorAdd />,
        },
        {
          link: ':operatorId',
          title: 'Detail',
          component: <OperatorDetail />,
        },
        {
          link: '',
          title: 'Index',
          component: <OperatorEmpty />,
        },
      ],
    },
    {
      link: '/administration',
      title: 'Administration',
      component: <Admin header="Administrace" />,
      subRoutes: [
        {
          link: 'users',
          title: 'Add operator',
          component: <AdminUsers />,
        },
        {
          link: 'apologies',
          title: 'Apologies',
          component: <AdminApologies />,
        },
        {
          link: 'templates',
          title: 'Templates',
          component: <AdminTemplates />,
        },
        {
          link: '',
          title: 'Index',
          component: <AdminEmpty />,
        },
      ],
    },
    {
      link: '/password-reset',
      title: 'Zapomenuté heslo',
      component: <ForgotPass header="Změňte.to - zapomenuté heslo" />,
      isPublic: true,
    },
    {
      link: '/password-reset/:token',
      title: 'Nastavení nového hesla',
      component: <ResetPass header="Změňte.to - nastavení nového hesla" />,
      isPublic: true,
    },
    {
      link: '/surveys',
      title: 'Podněty',
      component: <Surveys header="Změňte.to - podněty" />,
      subRoutes: [
        {
          link: ':priority',
          title: 'Priorita',
          component: <Priority />,
        },
      ],
    },
    {
      link: '/operator',
      title: 'Operátor',
      component: <Operator header="Změňte.to - operátor" />,
      subRoutes: [
        {
          link: ':id',
          title: 'Operator',
          component: <Opid />,
        },
      ],
    },
    {
      link: '/statuses',
      title: 'Status',
      component: <Statuses header="Změňte.to - statusy" />,
      subRoutes: [{ link: ':id', title: 'Status', component: <Status /> }],
    },
    {
      link: '/tags',
      title: 'Tags',
      component: <Tags header="Změňte.to - tagy" />,
      subRoutes: [
        {
          link: ':tag',
          title: 'Tag',
          component: <Tag />,
        },
      ],
    },
    {
      link: '/profile',
      title: 'Profil',
      component: <Profile header="Můj účet" />,
    },
    {
      link: '/unpaired',
      title: 'Nespárované podněty',
      component: <Unpaired header="Nespárované podněty" />,
    },
  ]);

  const getRouteElement = (link: ILink) => {
    return link.isPublic ? (
      <PublicRoute>
        <Title title={link.title}>{link.component}</Title>
      </PublicRoute>
    ) : (
      <ProtectedRouter>
        <Title title={link.title}>{link.component}</Title>
      </ProtectedRouter>
    );
  };

  return (
    <Routes>
      {links.map((link, idx) => {
        return (
          <Route key={idx} path={link.link} element={getRouteElement(link)}>
            {link.subRoutes?.map((subLink, idx) => (
              <Fragment key={idx}>
                {subLink.link ? (
                  <Route path={subLink.link} element={getRouteElement(subLink)} />
                ) : (
                  <Route index element={getRouteElement(subLink)} />
                )}
              </Fragment>
            ))}
          </Route>
        );
      })}
    </Routes>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyStepsNestedResponseDTO,
    SurveyStepsNestedResponseDTOFromJSON,
    SurveyStepsNestedResponseDTOFromJSONTyped,
    SurveyStepsNestedResponseDTOToJSON,
} from './SurveyStepsNestedResponseDTO';

/**
 * 
 * @export
 * @interface SurveyStepsResponseDTO
 */
export interface SurveyStepsResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsResponseDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsResponseDTO
     */
    prefilledEmailOptionsId: number | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsResponseDTO
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsResponseDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsResponseDTO
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsResponseDTO
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsResponseDTO
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsResponseDTO
     */
    actionHref: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsResponseDTO
     */
    actionTitle: string;
    /**
     * 
     * @type {Array<SurveyStepsNestedResponseDTO>}
     * @memberof SurveyStepsResponseDTO
     */
    substeps: Array<SurveyStepsNestedResponseDTO>;
}

export function SurveyStepsResponseDTOFromJSON(json: any): SurveyStepsResponseDTO {
    return SurveyStepsResponseDTOFromJSONTyped(json, false);
}

export function SurveyStepsResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyStepsResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'prefilledEmailOptionsId': json['prefilled_email_options_id'],
        'parentId': json['parent_id'],
        'name': json['name'],
        'description': json['description'],
        'order': json['order'],
        'action': json['action'],
        'actionHref': json['action_href'],
        'actionTitle': json['action_title'],
        'substeps': ((json['substeps'] as Array<any>).map(SurveyStepsNestedResponseDTOFromJSON)),
    };
}

export function SurveyStepsResponseDTOToJSON(value?: SurveyStepsResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'prefilled_email_options_id': value.prefilledEmailOptionsId,
        'parent_id': value.parentId,
        'name': value.name,
        'description': value.description,
        'order': value.order,
        'action': value.action,
        'action_href': value.actionHref,
        'action_title': value.actionTitle,
        'substeps': ((value.substeps as Array<any>).map(SurveyStepsNestedResponseDTOToJSON)),
    };
}


import React, { FC, useState } from 'react';
import { InputProps } from '../../../interfaces/interfaces';
import { Field, useField } from 'formik';
import { InputGroup } from 'react-bootstrap';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const FormikPasswordInput: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="mb-3">
      <InputGroup>
        <Field className="form-control" {...field} {...props} type={show ? 'text' : 'password'} />
        <InputGroup.Text className="bg-white icon-toggle" onClick={() => setShow(!show)}>
          {show ? <Eye /> : <EyeSlash />}
        </InputGroup.Text>
      </InputGroup>
      {meta.touched && meta.error ? <div className="errorMessage">{meta.error}</div> : null}
    </div>
  );
};

export default FormikPasswordInput;

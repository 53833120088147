import { FC, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Formik, Form } from 'formik';
import InputSelect from '../InputSelect/InputSelect';
import { object, string } from 'yup';
import { IInstitution } from '../../../types';

interface ChooseProps {
  onSend: (recipient: string) => void;
}

const ChooseInstitutions: FC<ChooseProps> = ({ onSend }): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);
  const [institutions, setInstitutions] = useState<IInstitution[]>([]);

  const instContacts = institutions.map((inst) => {
    return inst.contacts;
  });
  const instContact = instContacts.map((el) => {
    return el.map((e) => ({
      name: `${e.administrative_district} ${e.cadastral_area} - ${e.email}`,
      value: e.email,
    }));
  });
  const instContactsList: { name: string; value: string }[] = (
    [] as { name: string; value: string }[]
  ).concat([], ...instContact);

  const getInstitutions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get('/institutions');
      setInstitutions(response.data);
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInstitutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = object().shape({
    selectInstitution: string().required('Vyberte instituci'),
  });
  const initialValues = {
    selectInstitution: '',
  };

  const handleInstitutionChange = (values:any, actions:any) => {
    actions.setSubmitting(true);
    onSend(values.selectInstitution);
    actions.setSubmitting(false);
    actions.resetForm();
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      enableReinitialize={true}
      
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSend(values.selectInstitution);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form className="text-dark mb-1 d-flex">
          <InputSelect
            name="selectInstitution"
            placeholder="Vyberte instituci"
            options={instContactsList}
            onChange={(e) => {
              const newValue = e.target.value;
              console.log(newValue); // Add this line to log the value
              onSend(newValue);
              setFieldValue('selectInstitution', newValue);
              // You can add any additional logic here based on the changed value
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
/*<button
            className="ms-3 btn btn-outline-dark"
            style={{ height: '31px', padding: '2px 12px' }}
            type="submit"
            disabled={isSubmitting}
          >
            Vybrat
          </button>*/
export default ChooseInstitutions;

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, memo, useContext, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import AppContext from 'src/store/appContext';
import { configuration } from 'src/configuration';
import AutoComp from '../Autocomplete';

import styles from './GoogleMap.module.scss';
import { INearest } from 'src/interfaces/interfaces';
import { faLocationDot, faDroplet, faCircleDot, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import GpsInput from '../GpsInput/index';

const hlmpLocation = {
  lat: 50.08712109608858,
  lng: 14.417847885347497,
};

const GMap: FC<{
  isAutocomplete?: boolean;
  nearests?: INearest[];
}> = ({ isAutocomplete, nearests }) => {
  const { LatLng, setLatLng, detail } = useContext(AppContext);
  const [zoom] = useState(18); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(hlmpLocation);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: configuration.GOOGLE_API_KEY || '',
    libraries: configuration.GOOGLE_LIBS,
    language: 'cs',
    region: 'cz',
  });

  const [showInfo, setShowInfo] = useState(false);
  const [showSameLocation, setShowSameLocation] = useState(false);
  const [sames, setSames] = useState<INearest[]>();

  const [isMarkerMoveMode, setIsMarkerMoveMode] = useState(false);

  const [isFocused, setIsFocused] =
    useState<{ focused: boolean; latLng: google.maps.LatLngLiteral | undefined }>();

  const handleMarkerMove = (e: google.maps.MapMouseEvent) => {
    if (isMarkerMoveMode && LatLng) {
      setLatLng(() => e.latLng?.toJSON());
      setCenterHandler(e.latLng?.toJSON()!);
      setShowInfo(false);
      setIsFocused({ focused: false, latLng: undefined });
      // Additional logic or actions you may want to perform after moving the marker
    }
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (!LatLng) {
      setLatLng(() => e.latLng?.toJSON());
    }
    setShowInfo(false);
    setShowSameLocation(false);
    setIsFocused({ focused: false, latLng: undefined });

    if (isMarkerMoveMode && LatLng) {
      setLatLng(() => e.latLng?.toJSON());
      setCenterHandler(e.latLng?.toJSON()!);
      setShowInfo(false);
      setIsFocused({ focused: false, latLng: undefined });
      // Additional logic or actions you may want to perform after moving the marker
    }
  };

  const resetPositionHandler = () => {
    setLatLng({
      lat: Number(detail?.gps?.split(',')[0]),
      lng: Number(detail?.gps?.split(',')[1]),
    });
  };

  const setCenterHandler = (LatLng: google.maps.LatLngLiteral) => {
    setCenter({ ...center, lat: LatLng.lat, lng: LatLng.lng });
  };

  const handleMarkerLoad = (marker: google.maps.Marker) => {
    setCenterHandler(marker.getPosition()!.toJSON());
  };

  const getMarkerposition = (e: google.maps.MapMouseEvent) => {
    setLatLng(() => e.latLng?.toJSON());
    setCenterHandler(e.latLng!.toJSON());
  };

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    border: '3px solid #2dcfa7',
  };
  const options = {
    center: center,
    zoom: zoom,
  };

  const ResetPosition = () => {
    return (
      <div
        style={{
          padding: '0.5rem 0',
        }}
      >
        <button
          className={`btn ${styles['reset-button']}`}
          type="button"
          onClick={resetPositionHandler}
          aria-label="Resetovat polohu"
        >
          Resetovat polohu
        </button>
      </div>
    );
  };

  const SameLocations = () => {
    return (
      <div className={`${styles['info-window']} ${styles['info-window-same-locs']}`}>
        <span>Podnět(y) na poloze:</span>
        <ul>
          {sames?.map((place) => (
            <li key={place.reg_num}>
              <a href={`/detail/${place.survey_id}`} target="_blank" rel="noreferrer">
                {place.reg_num}
              </a>
              <p>{place.description}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const InfoWin = () => {
    return (
      <div className={styles['info-window']}>
        <span>Info o podnětu:</span>
        <ul>
          <li>RegNum: {detail?.reg_num}</li>
          <li>Lat: {LatLng?.lat}</li>
          <li>Lng: {LatLng?.lng}</li>
        </ul>
      </div>
    );
  };

  const showInfoHandler = (e: google.maps.MapMouseEvent) => {
    setCenterHandler(e.latLng?.toJSON()!);
    setShowInfo(true);
    setShowSameLocation(false);
    setIsFocused({ focused: false, latLng: undefined });
  };

  const showSameLocationHandler = (lat: string, lng: string) => {
    const sameArr = nearests?.filter((pos) => pos.lat === lat && pos.lng === lng);
    setSames(sameArr);
    setShowSameLocation(true);
  };

  useEffect(() => {
    LatLng && setCenterHandler(LatLng);
  }, [LatLng]);

  useEffect(() => {
    resetPositionHandler();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: isAutocomplete ? '600px' : '450px',
        minHeight: '300px',
      }}
    >
      {!isLoaded && <span>Nahrávám...</span>}
      {isLoaded && (
        <>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            id={'google-map'}
            options={options}
            onClick={onClick}
          >
            {LatLng && !nearests && (
              <Marker
                position={LatLng}
                onDragEnd={getMarkerposition}
                onLoad={handleMarkerLoad}
                draggable={!nearests}
                onClick={() => setIsMarkerMoveMode(!isMarkerMoveMode)}
                icon={{
                  // Change the marker color based on the selected state
                  path: faLocationDot.icon[4] as string,
                  fillColor:'#f00',
                  fillOpacity: 0.9,
                  anchor: new google.maps.Point(
                    faLocationDot.icon[0] / 2, // horizontal shift - centered
                    500 // vertical shift
                  ),
                  strokeWeight: isMarkerMoveMode ? 2 : 1,
                  strokeColor: isMarkerMoveMode ? '#00f' : '#f00',
                  scale: 0.085,
                  rotation: 180
                }}
              />
            )}
            {LatLng && nearests && (
              <Marker
                position={LatLng}
                title={`Poloha podnětu ${detail?.reg_num}`}
                icon={{
                  path: faLocationDot.icon[4] as string,
                  fillColor: '#f00',
                  fillOpacity: 0.9,
                  anchor: new google.maps.Point(
                    faLocationDot.icon[0] / 2, // horizontal shift - centered
                    450 // vertical shift
                  ),
                  strokeWeight: 1,
                  strokeColor: '#fff',
                  scale: 0.085,
                  rotation: 180
                }}
                animation={google.maps.Animation.DROP}
                onClick={showInfoHandler}
              />
            )}
            {showInfo && <InfoWin />}
            {sames && sames?.length > 0 && showSameLocation && <SameLocations />}
            {nearests &&
              nearests.map((loc, i) => (
                <Marker
                  key={i}
                  position={{ lat: Number(loc.lat), lng: Number(loc.lng) }}
                  title={loc.reg_num}
                  icon={{
                    path: faLocationDot.icon[4] as string,
                    fillColor:
                      isFocused?.focused &&
                      isFocused.latLng?.lat === Number(loc.lat) &&
                      isFocused.latLng.lng === Number(loc.lng)
                        ? '#f00'
                        : '#00f',
                    fillOpacity: 1,
                    anchor: new google.maps.Point(
                      faLocationDot.icon[0] / 2, // horizontal shift - centered
                      550 // vertical shift - positioned on top of location
                    ),
                    strokeWeight: 1,
                    strokeColor: '#fff',
                    scale: 0.075,
                  }}
                  onClick={(e: google.maps.MapMouseEvent) => {
                    setCenterHandler(e.latLng?.toJSON()!);
                    setShowInfo(false);
                    setIsFocused({ focused: true, latLng: e.latLng?.toJSON()! });
                    showSameLocationHandler(loc.lat, loc.lng);
                  }}
                />
              ))}
          </GoogleMap>
          {!nearests && (
            <>
              <ResetPosition />
              <AutoComp />
              <GpsInput />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(GMap);

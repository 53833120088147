import { ChangeEvent, FC, Fragment, memo, useContext } from 'react';
import AppContext from '../../../store/appContext';
import { ButtonGroup, Col, ToggleButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FilterRow from '../../ui/FilterRow/FilterRow';
import PriorityButton from '../../ui/PriorityButton/PriorityButton';
import '../../../styles/styles.scss';

const Filters: FC = () => {
  const { filters, oper, setOper, whoAmI, unassigned, setUnassigned } = useContext(AppContext);

  const setOperatorHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setOper(e.currentTarget.value);
    setUnassigned(false);
  };

  const filtOps = filters?.operators.map((opr) => {
    return (
      <ToggleButton
        key={opr.id}
        type="radio"
        id={opr.username}
        name="radio"
        variant="outline-primary"
        className={`me-2 mb-2 filt filt-${opr.id}`}
        value={opr.id}
        checked={oper === opr.id.toString()}
        onChange={setOperatorHandler}
      >
        {opr.username}
      </ToggleButton>
    );
  });

  const filtStatus = filters?.statuses.map((status) => {
    return (
      <Link
        to={`/statuses/${status.id}`}
        key={status.id}
        className={`me-2 mb-2 filt filt-${status.id}`}
      >
        {status.value}
      </Link>
    );
  });

  const filtTagsExt = filters?.ext_tags.map((tag, i) => {
    return (
      <Link to={`/tags/${tag.id}`} key={tag.id} className={`me-2 mb-2 filt filt-${i + 1}`}>
        {tag.value}
      </Link>
    );
  });

  return (
    <Fragment>
      {whoAmI && whoAmI.role !== 'guest' && (
        <FilterRow className="mb-2">
          <ButtonGroup>
            <Col>
              <ToggleButton
                type="radio"
                id="noop"
                name="radio"
                variant="outline-secondary"
                className={`me-2 mb-2`}
                value=""
                checked={oper === '' && !unassigned}
                onChange={setOperatorHandler}
              >
                X
              </ToggleButton>
              <ToggleButton
                type="radio"
                id="unassigned"
                name="radio"
                variant="outline-secondary"
                className={`me-2 mb-2`}
                value=""
                checked={unassigned}
                onChange={(e) => {
                  setOper(e.currentTarget.value);
                  setUnassigned(true);
                }}
              >
                bez operátora
              </ToggleButton>
              {filtOps}
            </Col>
          </ButtonGroup>
        </FilterRow>
      )}
      <FilterRow className="mb-2">
        <Col>
          <PriorityButton priority="X" />
          <PriorityButton priority="urgent" />
          <PriorityButton priority="high" />
          <PriorityButton priority="normal" />
        </Col>
      </FilterRow>
      <FilterRow className="mb-2">
        <Col>
          <Link to="/surveys" className={`me-2 mb-2 filt filt-11`}>
            X
          </Link>
          {filtStatus}
        </Col>
      </FilterRow>
      <FilterRow className="mb-3">
        <Col>
          <Link to="/surveys" className={`me-2 mb-2 filt filt-11`}>
            X
          </Link>
          {filtTagsExt}
        </Col>
      </FilterRow>
    </Fragment>
  );
};

export default memo(Filters);

import { FC, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import Filters from '../../layout/Filters/Filters';
import SurveyBasic from '../../layout/SurveyBasic/SurveyBasic';
import Paginator from '../../ui/Paginator/Paginator';
import { Props } from '../../../interfaces/interfaces';

const Email: FC<Props> = (props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const { getFilters, getSurveys, limit, surveys, totalSurCount } = useContext(AppContext);

  // getting props from the link
  const location = useLocation();
  let state: any;
  if (location.state) {
    state = location.state;
  }

  useEffect(() => {
    getFilters();
    if (state.email) {
      getSurveys(limit, (currentPage - 1) * limit, '', '', '', '', state.email);
    } else {
      getSurveys(limit, (currentPage - 1) * limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.email, currentPage]);

  let result;
  let generatedSurveys;
  if (surveys) {
    generatedSurveys = surveys.map((survey) => {
      return (
        <Col key={survey?.reg_num} xs={12}>
          <SurveyBasic fields={survey} />
        </Col>
      );
    });
    if (generatedSurveys.length > 0) {
      result = generatedSurveys;
    } else {
      result = (
        <Col>
          <h3 className="mt-5 text-center">Nic jsem nenašlo, pardon</h3>
        </Col>
      );
    }
  }

  return (
    <section className="top">
      <h1 className="px-3 mb-3 display-5 text-center">{props.header}</h1>
      <Outlet />
      <Container>
        <Filters />
        <Row className="mb-3">
          <Col>
            <Paginator
              totalCount={totalSurCount!}
              itemsPerPage={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
        <Row className="mb-3">{result}</Row>
        <Row className="mb-5">
          <Col>
            <Paginator
              totalCount={totalSurCount!}
              itemsPerPage={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Email;

import { FC } from 'react';
import { Field, useField } from 'formik';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { InputProps } from '../../../interfaces/interfaces';
import { InputGroup } from 'react-bootstrap';

interface InputSelectProps extends InputProps {
  options: { name: string; value: string | number }[];
}

const InputSelect: FC<InputSelectProps> = ({ label, options, ...props }) => {
  const [field, meta] = useField({ ...props });
  return (
    <div className="d-flex flex-column mb-2 flex-fill">
      <InputGroup className="d-flex">
        {label && (
          <InputGroup.Text>
            <label htmlFor={props.id || props.name}>{label}</label>
          </InputGroup.Text>
        )}
        <Field className="form-select form-select-sm" {...field} {...props} as="select">
          <option value="" label={props.placeholder}>
            {props.placeholder}{' '}
          </option>
          {options &&
            options.map((option, i: number) => (
              <option key={i} value={option.value} label={option.name}>
                {option.name}{' '}
              </option>
            ))}
        </Field>
      </InputGroup>
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
    </div>
  );
};

export default InputSelect;

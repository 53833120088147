import { FC, useContext } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import { Formik, Form } from 'formik';
import InputSelect from '../InputSelect/InputSelect';
import { string, object } from 'yup';

const SetOperatorForm: FC = () => {
  const { detail, filters } = useContext(AppContext);
  const { assignTo } = useContext(SolutionContext);

  const ops = filters?.operators.map((op) => ({ name: op.username, value: op.id }));

  const validationSchema = object().shape({
    selectOp: string().required('Vyberte operátora'),
  });
  const initialValues = {
    selectOp: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        assignTo(detail?.id!, parseFloat(values.selectOp));
        setTimeout(() => {
          window.location.reload();
        }, 300);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form className="text-dark mb-1 d-flex">
          <InputSelect name="selectOp" placeholder="Vyberte operátora" options={ops!} />
          <button
            className="ms-3 btn btn-outline-dark"
            style={{ height: '31px', padding: '2px 12px' }}
            type="submit"
            disabled={isSubmitting}
          >
            Přiřadit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SetOperatorForm;

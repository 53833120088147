import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Props } from '../../../interfaces/interfaces';
import { IQuestionCategoryRating } from '../../../types';

const RatingsQuestions: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [categoryRatings, setCategoryRatings] = useState<IQuestionCategoryRating[]>([]);

  const getQuestions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/office-ratings/questions`);
      setCategoryRatings(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <Row>
          <Col>
            <h2 className="mb-5">Přehled hodnotících otázek</h2>
            {categoryRatings.map((category) => (
              <Fragment key={category.id}>
                <h5>{category.name}</h5>
                {category.questions.map((question) => (
                  <p key={category.id + '_' + question.id}>{question.name}</p>
                ))}
                <br />
              </Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RatingsQuestions;

import { FC } from 'react';
import { ClipboardPlus, ClipboardMinus } from 'react-bootstrap-icons';
import styled from 'styled-components';

const StyledClipPlus = styled(ClipboardPlus)`
  background-color: #24a1;
  border-radius: 0.2rem;
  font-size: 1.7rem;
  padding: 0.1rem 0.1rem;
  transition: all 0.1s ease-in;
  &:hover {
    color: red;
  }
  margin-left: 0.2rem;
`;

const StyledClipMinus = styled(ClipboardMinus)`
  background-color: #24a1;
  border-radius: 0.2rem;
  font-size: 1.7rem;
  padding: 0.1rem 0.1rem;
  transition: all 0.1s ease-in;
  &:hover {
    color: red;
  }
  margin-left: 0.2rem;
`;

const AddNoteIcon: FC<{ onClick?: () => void; showEditor: boolean }> = ({
  onClick,
  showEditor,
}) => {
  return showEditor ? <StyledClipMinus onClick={onClick} /> : <StyledClipPlus onClick={onClick} />;
};

export default AddNoteIcon;

/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyStepsDTO,
    SurveyStepsDTOFromJSON,
    SurveyStepsDTOFromJSONTyped,
    SurveyStepsDTOToJSON,
} from './SurveyStepsDTO';

/**
 * 
 * @export
 * @interface SurveyStepsNestedResponseDTO
 */
export interface SurveyStepsNestedResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsNestedResponseDTO
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsNestedResponseDTO
     */
    prefilledEmailOptionsId: number | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsNestedResponseDTO
     */
    parentId: number | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsNestedResponseDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsNestedResponseDTO
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyStepsNestedResponseDTO
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsNestedResponseDTO
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsNestedResponseDTO
     */
    actionHref: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStepsNestedResponseDTO
     */
    actionTitle: string;
    /**
     * 
     * @type {Array<SurveyStepsDTO>}
     * @memberof SurveyStepsNestedResponseDTO
     */
    substeps: Array<SurveyStepsDTO>;
}

export function SurveyStepsNestedResponseDTOFromJSON(json: any): SurveyStepsNestedResponseDTO {
    return SurveyStepsNestedResponseDTOFromJSONTyped(json, false);
}

export function SurveyStepsNestedResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyStepsNestedResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'prefilledEmailOptionsId': json['prefilled_email_options_id'],
        'parentId': json['parent_id'],
        'name': json['name'],
        'description': json['description'],
        'order': json['order'],
        'action': json['action'],
        'actionHref': json['action_href'],
        'actionTitle': json['action_title'],
        'substeps': ((json['substeps'] as Array<any>).map(SurveyStepsDTOFromJSON)),
    };
}

export function SurveyStepsNestedResponseDTOToJSON(value?: SurveyStepsNestedResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'prefilled_email_options_id': value.prefilledEmailOptionsId,
        'parent_id': value.parentId,
        'name': value.name,
        'description': value.description,
        'order': value.order,
        'action': value.action,
        'action_href': value.actionHref,
        'action_title': value.actionTitle,
        'substeps': ((value.substeps as Array<any>).map(SurveyStepsDTOToJSON)),
    };
}


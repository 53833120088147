import { FC, useContext, useState } from 'react';
import AppContext from '../../../store/appContext';
import SolutionContext from '../../../store/solutionContext';
import { object } from 'yup';
import { Formik, Form } from 'formik';
import { Card, Container } from 'react-bootstrap';
import InputFieldLabelBack from '../../ui/InputFieldLabelBack/InputFieldLabelBack';
import { ISolutionSubSteps } from '../../../interfaces/interfaces';
import { chooseRadioOptionSheme } from '../../ui/_Utils/yup_validations';

interface Values {
  answer: string;
}
// Yup validation schema
const validationSchema = object().shape({
  answer: chooseRadioOptionSheme,
});
// form
const SolutionSubstep: FC<{ stepId?: ISolutionSubSteps }> = ({ stepId }): JSX.Element => {
  const { detail } = useContext(AppContext);
  const { getSurveyByStepId, setSolutionAction, setSolutionStepName } = useContext(SolutionContext);
  const [showStep, setShowStep] = useState(true);

  const showStepHandler = () => {
    setShowStep(!showStep);
  };

  const initialValues: Values = {
    answer: '',
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        setSolutionStepName(stepId!.name);
        let options = `optionIds=${values.answer}`;
        getSurveyByStepId(stepId?.parent_id!, detail!, options, false);
        setTimeout(() => {
          setSolutionAction('viewMail');
        }, 300);
        actions.setSubmitting(false);
        actions.resetForm();
        setShowStep(false);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form className="text-dark">
          {showStep && (
            <Card className="mb-3">
              <Card.Body>
                <Card.Title
                  onClick={showStepHandler}
                  style={{ cursor: 'pointer', marginBottom: showStep ? '.5rem' : '0' }}
                >
                  {stepId && stepId!.name}
                </Card.Title>
                <Container className="mt-3" id="answer-group">
                  <div role="group" aria-labelledby="answer-group" className="ms-3 mb-3">
                    {stepId &&
                      stepId.substeps.map((input) => {
                        return (
                          <div className="mb-1" key={input.id}>
                            <InputFieldLabelBack
                              name="answer"
                              id={input.id as unknown as string}
                              type={input.action}
                              label={input.name}
                              value={input.id as unknown as string}
                              checked={values.answer === input.id.toString()}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <button
                    className="btn btn-outline-dark"
                    type="submit"
                    style={{ maxWidth: '8rem' }}
                    disabled={isSubmitting}
                    onClick={handleClick}
                  >
                    Odpovědět
                  </button>
                </Container>
              </Card.Body>
            </Card>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SolutionSubstep;

import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { StyledBadge } from '../../ui/StyledBadge/StyledBadge';
import toast from 'react-hot-toast';
import { Props } from '../../../interfaces/interfaces';
import { IAverageOfficeRating } from '../../../types';

const RatingsAverageOffice: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [officeRatings, setOfficeRatings] = useState<IAverageOfficeRating[]>([]);
  const [detailVisibility, setDetailVisibility] = useState<Record<number, boolean>>({});

  const toggleVisibility = (departmentId: number) => {
    setDetailVisibility({
      ...detailVisibility,
      [departmentId]: !detailVisibility[departmentId] ?? true,
    });
  };

  const getOfficeRatings = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/office-ratings/ratings/average/offices`);
      setOfficeRatings(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOfficeRatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <Row>
          <Col>
            <h2 className="mb-5">Hodnocení jednotlivých útvarů</h2>
            <Table>
              <thead>
                <tr>
                  <th>Název</th>
                  <th style={{ textAlign: 'center' }}>Info</th>
                  <th style={{ textAlign: 'center' }}>Počet hodnocení</th>
                  <th style={{ textAlign: 'center' }}>Známka</th>
                </tr>
              </thead>
              <tbody>
                {officeRatings.map((office) => (
                  <Fragment key={office.id}>
                    <tr key={office.id + '_office'}>
                      <td colSpan={4}>
                        <h4>{office.name}</h4>
                      </td>
                    </tr>
                    {office.divisions.map((division) => (
                      <Fragment key={division.id}>
                        <tr key={division.id + '_division'}>
                          <td colSpan={4}>
                            <h5>- {division.name}</h5>
                          </td>
                        </tr>
                        {division.departments.map((department) => (
                          <Fragment key={department.id}>
                            <tr key={department.id + '_department'}>
                              <td>
                                <span className="ps-sm-2">-- {department.name}</span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {!!department.rating_count && (
                                  <button
                                    onClick={() => toggleVisibility(department.id)}
                                    className="btn btn-primary btn-sm"
                                  >
                                    info
                                  </button>
                                )}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {department.rating_count ? department.rating_count : 'nehodnoceno'}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <StyledBadge rating={department.rating_avg}>
                                  {department.rating_avg}
                                </StyledBadge>
                              </td>
                            </tr>
                            {!!department.rating_count && (
                              <tr
                                key={department.id + '_question'}
                                style={{
                                  visibility: detailVisibility[department.id]
                                    ? 'visible'
                                    : 'collapse',
                                }}
                              >
                                <td colSpan={4}>
                                  <Table striped borderless className="p-3 pb-0">
                                    <tbody>
                                      {department.rating_per_question.map((rating) => (
                                        <tr key={department.id + '_' + rating.id}>
                                          <td>{rating.name}</td>
                                          <td>
                                            <StyledBadge scale={0.7} rating={rating.rating_avg}>
                                              {rating.rating_avg}
                                            </StyledBadge>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RatingsAverageOffice;

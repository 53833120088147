import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { StyledBadge } from '../../ui/StyledBadge/StyledBadge';
import toast from 'react-hot-toast';
import { Props } from '../../../interfaces/interfaces';
import { IAverageQuestionCategoryRating } from '../../../types';

const RatingsAverageQuestions: FC<Props> = (props): JSX.Element => {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const [categoryRatings, setCategoryRatings] = useState<IAverageQuestionCategoryRating[]>([]);

  const getQuestionRatings = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/office-ratings/ratings/average/questions`);
      setCategoryRatings(response.data);
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getQuestionRatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="top">
      <Container fluid style={{ minHeight: '70vh' }}>
        <Row>
          <Col>
            <h2 className="mb-5">Hodnocení dle jednotlivých kritérií</h2>
            {categoryRatings.map((category) => (
              <Fragment key={category.id}>
                <h5>{category.name}</h5>
                <Table striped>
                  <tbody>
                    {category.questions.map((question) => (
                      <tr key={question.id}>
                        <td>{question.name}</td>
                        <td style={{ textAlign: 'right' }}>
                          <StyledBadge rating={question.rating_avg}>
                            {question.rating_avg}
                          </StyledBadge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RatingsAverageQuestions;

import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../store/appContext';
import AxiosContext from '../../../store/axiosContext';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { IUser } from '../../../types';

const ROLES = ['guest', 'operator', 'chief_operator', 'admin'];
// DEACTIVATED = "deactivated",

type IEditUser = Pick<IUser, 'name' | 'surname' | 'role'>;
type IAddUser = Pick<IUser, 'name' | 'surname' | 'username' | 'role'>;

export default function AdminUsers() {
  const { setIsLoading, setError } = useContext(AppContext);
  const httpClient = useContext(AxiosContext);

  const navigate = useNavigate();

  const [users, setUsers] = useState<Record<string, IUser>>({});
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUserEdit, setSelectedUserEdit] = useState<IEditUser>({
    name: '',
    surname: '',
    role: '',
  });
  const [user, setUser] = useState<IAddUser>({
    name: '',
    surname: '',
    username: '',
    role: '',
  });

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get<IUser[]>(`/users?active=true`);
      setUsers(
        response.data.reduce((res: Record<number, IUser>, el) => {
          res[el.id] = el;
          return res;
        }, {})
      );
    } catch (error: any) {
      // console.error(error);
      toast.error(`Chyba: ${error.response.data.message}`);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      setSelectedUserEdit({
        name: users[selectedUserId].name ?? '',
        surname: users[selectedUserId].surname ?? '',
        role: users[selectedUserId].role ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const handleAddUserSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await httpClient.post(`/users`, user);
      setUser({
        name: '',
        surname: '',
        username: '',
        role: '',
      });
      await getUsers();
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
  };

  const onUserSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserId(+event.target.value || null);
  };

  const handleEditUserSubmit = async (event: FormEvent, userId: number) => {
    event.preventDefault();
    try {
      await httpClient.put(`/users/${userId}`, selectedUserEdit);
      await getUsers();
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
  };

  const onDeactivate = async (userId: number) => {
    try {
      await httpClient.delete(`/users/${userId}`);
      await getUsers();
      setSelectedUserId(null);
      navigate('/administration/users');
    } catch (error: any) {
      console.error(error);
      setError(() => error.message);
    }
  };

  const onUserRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setUser({ ...user!, role: event.target.value });
  };

  const onRoleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserEdit({ ...selectedUserEdit, role: event.target.value });
  };

  const handleAddUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    setSelectedUserEdit({
      // TODO fix?? selectedUserEdit
      ...selectedUserEdit,
      [name]: value,
    });
  };

  return (
    <div className="mb-5">
      <h3 className="pb-3">Přidat uživatele</h3>
      <Form onSubmit={handleAddUserSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Jméno</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Jméno"
              name="name"
              value={user.name}
              onChange={handleAddUserChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            <b>Příjmení</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="Příjmení"
              name="surname"
              value={user.surname}
              onChange={handleAddUserChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" className="required">
            <b>Uživatelské jméno</b>
          </Form.Label>
          <Col sm="10">
            <Form.Control
              required
              type="email"
              placeholder="user@oict.cz"
              name="username"
              value={user.username}
              onChange={handleAddUserChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="role">
          <Form.Label column sm="2" className="required">
            <b>Role</b>
          </Form.Label>
          <Col sm="10">
            <Form.Select
              required
              name="role"
              value={user?.role || ''}
              disabled={!user}
              onChange={onUserRoleChange}
            >
              <option key="default" value="" disabled>
                -- Vyberte uživatelskou roli --
              </option>
              {ROLES.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        <div className="clearfix">
          <Button size="sm" variant="success" type="submit" className="mt-2 float-end">
            Uložit
          </Button>
        </div>
      </Form>

      <h3 className="pb-3">Nastavení uživatelských účtů</h3>
      <Form onSubmit={(event) => handleEditUserSubmit(event, selectedUserId!)}>
        <Form.Group as={Row} className="mb-3" controlId="username">
          <Form.Label column sm="2">
            <b>Uživatel</b>
          </Form.Label>
          <Col sm="10">
            <Form.Select value={selectedUserId || ''} onChange={(event) => onUserSelect(event)}>
              <option key={'user_default'} value="">
                -- Vyberte uživatele --
              </option>
              {Object.values(users).map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        {!!selectedUserId && (
          <>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column sm="2">
                <b>Jméno</b>
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="text"
                  placeholder="Jméno"
                  name="name"
                  value={selectedUserEdit.name}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="surname">
              <Form.Label column sm="2">
                <b>Příjmení</b>
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="text"
                  placeholder="Příjmení"
                  name="surname"
                  value={selectedUserEdit.surname}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="role">
              <Form.Label column sm="2">
                <b>Role</b>
              </Form.Label>
              <Col sm="10">
                <Form.Select
                  value={selectedUserEdit.role}
                  // disabled={!selectedUser}
                  name="role"
                  onChange={onRoleSelect}
                >
                  <option key="default" value="" disabled>
                    -- Vyberte uživatelskou roli --
                  </option>
                  {ROLES.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <div className="clearfix">
              <Button size="sm" variant="success" type="submit" className="mt-2 float-end">
                Uložit
              </Button>
            </div>
          </>
        )}
      </Form>

      {!!selectedUserId && (
        <Button
          size="sm"
          variant="danger"
          style={{ minWidth: '80%', display: 'block', margin: '2rem auto' }}
          onClick={() => {
            window.confirm('Opravdu chcete deaktivovat uživatele?') && onDeactivate(selectedUserId);
          }}
        >
          Deaktivovat
        </Button>
      )}
    </div>
  );
}

import { FC, Fragment, useState } from 'react';
import StyledCardLink from '../../ui/StyledCardLink/StyledCardLink';
import StyledHr from '../../ui/StyledHr/StyledHr';
import StyledLine from '../../ui/StyledLine/StyledLine';
import StyledMessage from '../../ui/StyledMessage/StyledMessage';
import FileFieldSimple from '../../ui/FileFieldSimple/FileFieldSimple';

interface ISurveyMessageHistoryItem {
  i: number;
  arrLength: number;
  id: number;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  message: string;
  mail_box: string;
  sent: boolean;
  attachments: string[];
  created_at: string;
}

const SurveyMessageHistoryItem: FC<ISurveyMessageHistoryItem> = ({
  created_at,
  from,
  subject,
  to,
  message,
  mail_box,
  sent,
  attachments,
  i,
  arrLength,
}): JSX.Element => {
  const [showMessage, setShowMessage] = useState(false);

  const showMessageHandler = () => {
    setShowMessage(!showMessage);
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <StyledLine header={`Odesláno:`} data={created_at} />
        {sent ? (
          <span className={`me-1 tag tag-7 ms-auto`}>Odeslaný</span>
        ) : (
          <span className={`me-1 tag tag-7 ms-auto`}>Odesílaný</span>
        )}
        {mail_box === 'INBOX' && <span className={`me-1 tag tag-5`}>INBOX</span>}
      </div>
      <StyledLine header={`Od:`} data={from} />
      <StyledLine header={`Předmět:`} data={subject} />
      <StyledLine header={`Komu:`} data={to} />
      {attachments && attachments.length > 0 && (
        <FileFieldSimple label={`Příloha/y:`} files={attachments} imageWidth={5} />
      )}
      <div className="d-flex justify-content-between">
        <StyledCardLink className="ps-2 me-auto" onClick={showMessageHandler}>
          Zpráva...
        </StyledCardLink>
      </div>
      {showMessage && (
        <StyledMessage>
          <div dangerouslySetInnerHTML={{ __html: message! }} />
        </StyledMessage>
      )}
      {arrLength > 1 && i !== arrLength - 1 && <StyledHr />}
    </Fragment>
  );
};

export default SurveyMessageHistoryItem;

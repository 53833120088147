/* tslint:disable */
/* eslint-disable */
/**
 * Změňte.to Admin API
 * Změňte.to Admin API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CodeDTO,
    CodeDTOFromJSON,
    CodeDTOFromJSONTyped,
    CodeDTOToJSON,
} from './CodeDTO';

/**
 * 
 * @export
 * @interface CodeListResponseDTO
 */
export interface CodeListResponseDTO {
    /**
     * 
     * @type {Array<CodeDTO>}
     * @memberof CodeListResponseDTO
     */
    data: Array<CodeDTO>;
}

export function CodeListResponseDTOFromJSON(json: any): CodeListResponseDTO {
    return CodeListResponseDTOFromJSONTyped(json, false);
}

export function CodeListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeListResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(CodeDTOFromJSON)),
    };
}

export function CodeListResponseDTOToJSON(value?: CodeListResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(CodeDTOToJSON)),
    };
}


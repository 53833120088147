import { FC, useState } from 'react';
import { Card } from 'react-bootstrap';
import StyledCard from '../../ui/StyledCard/StyledCard';
import StyledLine from '../../ui/StyledLine/StyledLine';
import StyledMessage from '../../ui/StyledMessage/StyledMessage';
import StyledText from '../../ui/StyledText/StyledText';
import StyledTextCatName from '../../ui/StyledTextCatName/StyledTextCatName';
import { IUnpairedMail } from '../../views/unpaired';
import { ChevronRight, ChevronDown } from 'react-bootstrap-icons';
import PairEmailToSurvey from '../../ui/PairEmailToSurvey/PairEmailToSurvey';
import { toCzDateTime } from '../../../utils/toCzDateTime';

const UnpairedEmail: FC<{ fields: IUnpairedMail }> = ({ fields }): JSX.Element => {
  const [show, setShow] = useState(false);
  const { id, subject, from_email, to_email, body, date, attachments, created_at } = fields;

  return (
    <StyledCard className="mb-3">
      <Card.Body>
        <PairEmailToSurvey id={id} />
        <StyledLine header={`ID: `} data={id} />
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <StyledLine header={`Datum vytvoření: `} data={toCzDateTime(date)} />
          <StyledLine header={`Odesláno: `} data={toCzDateTime(created_at)} />
        </div>
        <StyledLine header={`Předmět: `} data={subject} />
        <StyledLine header={`Od: `} data={from_email} />
        <StyledLine header={`Příjemce: `} data={to_email} />
        <StyledLine header={`Přílohy: `} data={attachments} />
        <StyledText>
          <StyledTextCatName onClick={() => setShow(!show)} style={{ cursor: 'pointer' }}>
            Zpráva
            {show ? (
              <ChevronDown style={{ fontSize: '0.7rem' }} />
            ) : (
              <ChevronRight style={{ fontSize: '0.7rem' }} />
            )}
          </StyledTextCatName>
        </StyledText>
        {show && (
          <StyledMessage>
            <div dangerouslySetInnerHTML={{ __html: body! }} />
          </StyledMessage>
        )}
      </Card.Body>
    </StyledCard>
  );
};

export default UnpairedEmail;

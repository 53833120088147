import { FC } from 'react';
import StyledCloseBadge from '../StyledCloseBadge/StyledCloseBadge';
import StyledText from '../StyledText/StyledText';
import StyledTextCatName from '../StyledTextCatName/StyledTextCatName';
import { Image } from 'react-bootstrap';
import StyledResetButton from '../StyledResetButton/StyledResetButton';
import { IFiles } from '../../../interfaces/interfaces';

const FileField: FC<{
  label: string;
  files: IFiles[] | undefined;
  survey_id?: string;
  onDelete?: (id: number) => void;
  onReset?: () => void;
  onClick?: () => void;
  controls?: boolean;
  imageWidth?: number;
}> = ({ label, files, survey_id, onDelete, onReset, onClick, controls, imageWidth }) => {
  return (
    <StyledText>
      <div className="d-flex justify-content-between mb-1">
        <StyledTextCatName className="align-top">{label}</StyledTextCatName>
        {controls && <StyledResetButton onClick={() => onReset!()} />}
      </div>
      <div className="d-flex flex-wrap pb-2">
        {files?.map((file, i) => {
          return (
            <button
              key={i}
              className="position-relative"
              style={{ border: 'none', padding: '0' }}
              onClick={onClick}
              type='button'
            >
              <Image
                thumbnail
                src={file.path!}
                alt={survey_id}
                className="mx-1"
                style={{ maxWidth: `${imageWidth ? imageWidth : 6}rem` }}
              />
              {controls && (
                <StyledCloseBadge
                  onClick={() => {
                    onDelete!(file.id!);
                  }}
                />
              )}
            </button>
          );
        })}
      </div>
    </StyledText>
  );
};

export default FileField;

import { FC, useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import AppContext from '../../../store/appContext';
import { Props } from '../../../interfaces/interfaces';
import { useParams } from 'react-router-dom';
import Filters from '../../layout/Filters/Filters';
import SurveyBasic from '../../layout/SurveyBasic/SurveyBasic';
import Paginator from '../../ui/Paginator/Paginator';

const Statuses: FC<Props> = (props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const { getFilters, getSurveys, getWhoAmI, limit, oper, surveys, totalSurCount } =
    useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getWhoAmI(), []);

  // fetchin params from urls
  const { id } = useParams();

  useEffect(() => {
    getFilters();
    if (id !== '') {
      getSurveys(limit, (currentPage - 1) * limit, '', '', id, '', '', oper);
    } else {
      getSurveys(limit, (currentPage - 1) * limit, '', '', '', '', '', oper);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, id, oper, currentPage]);

  let result = [
    <Col key={`99999999`} className="d-flex justify-content-center">
      <Spinner animation="border" />
    </Col>,
  ];
  let generatedSurveys;
  if (surveys) {
    generatedSurveys = surveys.map((survey) => {
      return (
        <Col key={survey?.id} xs={12}>
          <SurveyBasic fields={survey} />
        </Col>
      );
    });
    if (generatedSurveys.length > 0) {
      result = generatedSurveys;
    } else {
      result = [
        <Col key={`99999999`}>
          <h3 className="mt-5 text-center">Nic jsem nenašlo, pardon</h3>
        </Col>,
      ];
    }
  }

  return (
    <section className="top">
      <h1 className="px-3 mb-4 display-5 text-center">{props.header}</h1>
      <Container>
        <Filters />
        <Row className="mb-3">
          <Col>
            <Paginator
              totalCount={totalSurCount!}
              itemsPerPage={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
        <Row className="mb-3">{result}</Row>
        <Row className="mb-5">
          <Col>
            <Paginator
              totalCount={totalSurCount!}
              itemsPerPage={limit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Statuses;

export const toCzDateTime = (datetime: Date | string | undefined) => {
  if (datetime) {
    let euDatetime = new Date(datetime.toString().replace(' ', 'T'));
    let formattedDatetime = euDatetime
      .toLocaleString('cs-CZ', { timeZone: 'Europe/Prague' })
      .replace(',', '');

    return formattedDatetime;
  } else return '';
};

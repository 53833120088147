import { FC, useEffect, useRef } from 'react';
import { Field, useField } from 'formik';
import { InputGroup } from 'react-bootstrap';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import { InputProps } from '../../../interfaces/interfaces';

const InputFieldMail: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const emailRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, [emailRef]);

  return (
    <div className="d-flex flex-column mb-2 flex-fill">
      <InputGroup>
        <InputGroup.Text>
          <label htmlFor={props.id || props.name}>{label}</label>
        </InputGroup.Text>
        <Field className="form-control" innerRef={emailRef} {...field} {...props} />
      </InputGroup>
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="align-self-end">{meta.error}</StyledErrorMessage>
      ) : null}
    </div>
  );
};

export default InputFieldMail;
